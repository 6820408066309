import React from 'react'
import { SvgIcon } from '@mui/material'


import { ReactComponent as AddUserMaleSkinType7 } from '../../assets/icons/icons8-add-user-male-skin-type-7-50.svg'
import { ReactComponent as AdminSettingMale } from '../../assets/icons/icons8-admin-settings-male-50.svg'
import { ReactComponent as AtSign } from '../../assets/icons/icons8-at-sign-50.svg'
import { ReactComponent as AvailableUpdates } from '../../assets/icons/icons8-available-updates-50.svg'
import { ReactComponent as Backlog } from '../../assets/icons/icons8-backlog-50.svg'
import { ReactComponent as Basket } from '../../assets/icons/icons8-basket-50.svg'
import { ReactComponent as Blueprint } from '../../assets/icons/icons8-blueprint-50.svg'
import { ReactComponent as BriefCase } from '../../assets/icons/icons8-briefcase-50.svg'
import { ReactComponent as BusinessReport } from '../../assets/icons/icons8-business-report-50.svg'
import { ReactComponent as Chain } from '../../assets/icons/icons8-chain-50.svg'
import { ReactComponent as CheckAll } from '../../assets/icons/icons8-check-all-50.svg'
import { ReactComponent as CheckList } from '../../assets/icons/icons8-checklist-50.svg'
import { ReactComponent as CheckMark } from '../../assets/icons/icons8-checkmark-50.svg'
import { ReactComponent as ClientManagement } from '../../assets/icons/icons8-client-management-50.svg'
import { ReactComponent as Clock } from '../../assets/icons/icons8-clock-50.svg'
import { ReactComponent as CloseWindow } from '../../assets/icons/icons8-close-window-50.svg'
import { ReactComponent as Comments } from '../../assets/icons/icons8-comments-50.svg'
import { ReactComponent as Complaints } from '../../assets/icons/icons8-complaints-50.svg'
import { ReactComponent as CyberSecurity } from '../../assets/icons/icons8-cyber-security-50.svg'
import { ReactComponent as CylindersChained } from '../../assets/icons/icons8-cylinders-chained-50.svg'
import { ReactComponent as DataBackupSVG } from '../../assets/icons/icons8-data-backup-50.svg'
import { ReactComponent as DoNotDisturbSVG } from '../../assets/icons/icons8-do-not-disturb-50.svg'
import { ReactComponent as DoNotDisturbBlueSVG } from '../../assets/icons/icons8-do-not-disturb-blue-50.svg'
import { ReactComponent as DownloadSVG } from '../../assets/icons/icons8-download-50.svg'
import { ReactComponent as EarProtectionSVG } from '../../assets/icons/icons8-ear-protection-50.svg'
import { ReactComponent as EditSVG } from '../../assets/icons/icons8-edit-50.svg'
import { ReactComponent as EditRowSVG } from '../../assets/icons/icons8-edit-row-50.svg'
import { ReactComponent as ElectronicsSVG } from '../../assets/icons/icons8-electronics-50.svg'
import { ReactComponent as EnvelopeSVG } from '../../assets/icons/icons8-envelope-50.svg'
import { ReactComponent as ErrorSVG } from '../../assets/icons/icons8-error-50.svg'
import { ReactComponent as ExchangeSVG } from '../../assets/icons/icons8-exchange-50.svg'
import { ReactComponent as ExpiredSVG } from '../../assets/icons/icons8-expired-50.svg'
import { ReactComponent as EyeProtectionSVG } from '../../assets/icons/icons8-eye-protection-50.svg'
import { ReactComponent as FacialRecognitionSVG } from '../../assets/icons/icons8-facial-recognition-50.svg'
import { ReactComponent as FacialRecognitionCheckedSVG } from '../../assets/icons/icons8-facial-recognition-checked-50.svg'
import { ReactComponent as FacialRecognitionWarningSVG } from '../../assets/icons/icons8-facial-recognition-warning-50.svg'
import { ReactComponent as FacialRecognitionErrorSVG } from '../../assets/icons/icons8-facial-recognition-error-50.svg'
import { ReactComponent as FactorySVG } from '../../assets/icons/icons8-factory-50.svg'
import { ReactComponent as FastDownloadSVG } from '../../assets/icons/icons8-fast-download-50.svg'
import { ReactComponent as FilterSVG } from '../../assets/icons/icons8-filter-50.svg'
import { ReactComponent as FireExtinguisherSVG } from '../../assets/icons/icons8-fire-extinguisher-50.svg'
import { ReactComponent as FlickerFreeSVG } from '../../assets/icons/icons8-flicker-free-50.svg'
import { ReactComponent as FootProtectionSVG } from '../../assets/icons/icons8-foot-protection-50.svg'
import { ReactComponent as FootWashSVG } from '../../assets/icons/icons8-foot-wash-50.svg'
import { ReactComponent as ForkLiftSVG } from '../../assets/icons/icons8-fork-lift-50.svg'
import { ReactComponent as GasMaskSVG } from '../../assets/icons/icons8-gas-mask-50.svg'
import { ReactComponent as GoalSVG } from '../../assets/icons/icons8-goal-50.svg'
import { ReactComponent as HairProtectionSVG } from '../../assets/icons/icons8-hair-protection-50.svg'
import { ReactComponent as HandProtectionSVG } from '../../assets/icons/icons8-hand-protection-50.svg'
import { ReactComponent as HelpSVG } from '../../assets/icons/icons8-help-50.svg'
import { ReactComponent as HierarchySVG } from '../../assets/icons/icons8-hierarchy-50.svg'
import { ReactComponent as HomeSVG } from '../../assets/icons/icons8-home-50.svg'
import { ReactComponent as HomePageSVG } from '../../assets/icons/icons8-home-page-50.svg'
import { ReactComponent as IdeaSVG } from '../../assets/icons/icons8-idea-50.svg'
import { ReactComponent as InfoSVG } from '../../assets/icons/icons8-info-50.svg'
import { ReactComponent as InspectionSVG } from '../../assets/icons/icons8-inspection-50.svg'
import { ReactComponent as KeepCleanSVG } from '../../assets/icons/icons8-keep-clean-50.svg'
import { ReactComponent as KeySVG } from '../../assets/icons/icons8-key-50.svg'
import { ReactComponent as AddKeySVG } from '../../assets/icons/icons8-add-key-50.svg'
import { ReactComponent as ResetKeySVG } from '../../assets/icons/icons8-reset-key-50.svg'
import { ReactComponent as LockSVG } from '../../assets/icons/icons8-lock-50.svg'
import { ReactComponent as LockCheckedSVG } from '../../assets/icons/icons8-lock-checked-50.svg'
import { ReactComponent as LockWarningSVG } from '../../assets/icons/icons8-lock-warning-50.svg'
import { ReactComponent as ManualSVG } from '../../assets/icons/icons8-manual-50.svg'
import { ReactComponent as MechanicSVG } from '../../assets/icons/icons8-mechanic-50.svg'
import { ReactComponent as NotificationSVG } from '../../assets/icons/icons8-notification-50.svg'
import { ReactComponent as OfficeSVG } from '../../assets/icons/icons8-office-50.svg'
import { ReactComponent as OkSVG } from '../../assets/icons/icons8-ok-50.svg'
import { ReactComponent as OnlineSupportSVG } from '../../assets/icons/icons8-online-support-50.svg'
import { ReactComponent as OrganizationSVG } from '../../assets/icons/icons8-organization-50.svg'
import { ReactComponent as PadlockSVG } from '../../assets/icons/icons8-padlock-50.svg'
import { ReactComponent as PedestriansSVG } from '../../assets/icons/icons8-pedestrians-50.svg'
import { ReactComponent as PeopleSVG } from '../../assets/icons/icons8-people-50.svg'
import { ReactComponent as PoisonSVG } from '../../assets/icons/icons8-poison-50.svg'
import { ReactComponent as PresentationSVG } from '../../assets/icons/icons8-presentation-50.svg'
import { ReactComponent as ProductDocumentsSVG } from '../../assets/icons/icons8-product-documents-50.svg'
import { ReactComponent as ProtectionMaskSVG } from '../../assets/icons/icons8-protection-mask-50.svg'
import { ReactComponent as ProtectiveGearASVG } from '../../assets/icons/icons8-protective-gear-50-2.svg'
import { ReactComponent as ProtectiveGearBSVG } from '../../assets/icons/icons8-protective-gear-50-3.svg'
import { ReactComponent as ProtectiveGearCSVG } from '../../assets/icons/icons8-protective-gear-50.svg'
import { ReactComponent as ProtectiveGlassesSVG } from '../../assets/icons/icons8-protective-glasses-50.svg'
import { ReactComponent as ProximitySensorSVG } from '../../assets/icons/icons8-proximity-sensor-50.svg'
import { ReactComponent as RadarSVG } from '../../assets/icons/icons8-radar-50.svg'
import { ReactComponent as RequestServiceSVG } from '../../assets/icons/icons8-request-service-50.svg'
import { ReactComponent as RestartSVG } from '../../assets/icons/icons8-restart-50.svg'
import { ReactComponent as RfidSensorSVG } from '../../assets/icons/icons8-rfid-sensor-50.svg'
import { ReactComponent as RfidSignalSVG } from '../../assets/icons/icons8-rfid-signal-50.svg'
import { ReactComponent as SafetyHatSVG } from '../../assets/icons/icons8-safety-hat-50.svg'
import { ReactComponent as SafetyVestSVG } from '../../assets/icons/icons8-safety-vest-50.svg'
import { ReactComponent as SaveSVG } from '../../assets/icons/icons8-save-50.svg'
import { ReactComponent as SecurityGuardSkinType3SVG } from '../../assets/icons/icons8-security-guard-skin-type-3-50.svg'
import { ReactComponent as SettingsSVG } from '../../assets/icons/icons8-settings-50.svg'
import { ReactComponent as StatisticsSVG } from '../../assets/icons/icons8-statistics-50.svg'
import { ReactComponent as SystemInformationSVG } from '../../assets/icons/icons8-system-information-50.svg'
import { ReactComponent as ThumbnailsSVG } from '../../assets/icons/icons8-thumbnails-50.svg'
import { ReactComponent as TickBoxSVG } from '../../assets/icons/icons8-tick-box-50.svg'
import { ReactComponent as ToDoSVG } from '../../assets/icons/icons8-to-do-50.svg'
import { ReactComponent as ToolsSVG } from '../../assets/icons/icons8-tools-50.svg'
import { ReactComponent as TouchIdSVG } from '../../assets/icons/icons8-touch-id-50.svg'
import { ReactComponent as TrainingSVG } from '../../assets/icons/icons8-training-50.svg'
import { ReactComponent as TrashSVG } from '../../assets/icons/icons8-trash-50.svg'
import { ReactComponent as UnavailableSVG } from '../../assets/icons/icons8-unavailable-50.svg'
import { ReactComponent as UseForkliftSVG } from '../../assets/icons/icons8-use-forklift-50.svg'
import { ReactComponent as UserLocationSVG } from '../../assets/icons/icons8-user-location-50.svg'
import { ReactComponent as VerifiedAccountSVG } from '../../assets/icons/icons8-verified-account-50.svg'
import { ReactComponent as VerfiedBadgeSVG } from '../../assets/icons/icons8-verified-badge-50.svg'
import { ReactComponent as ViewDetailsSVG } from '../../assets/icons/icons8-view-details-50.svg'
import { ReactComponent as WarrantySVG } from '../../assets/icons/icons8-warranty-50.svg'
import { ReactComponent as WashYourHandSVG } from '../../assets/icons/icons8-wash-your-hand-50.svg'
import { ReactComponent as WearAntiStaticBootsSVG } from '../../assets/icons/icons8-wear-anti-static-boots-50.svg'
import { ReactComponent as WearAntiStaticGlovesSVG } from '../../assets/icons/icons8-wear-anti-static-gloves-50.svg'
import { ReactComponent as WearApronSVG } from '../../assets/icons/icons8-wear-apron-50.svg'
import { ReactComponent as WearBreathingApparatusSVG } from '../../assets/icons/icons8-wear-breathing-apparatus-50.svg'
import { ReactComponent as WearDustMaskSVG } from '../../assets/icons/icons8-wear-dust-mask-50.svg'
import { ReactComponent as WearEarPlugAndGogglesSVG } from '../../assets/icons/icons8-wear-ear-plug-and-goggles-50.svg'
import { ReactComponent as WearFaceShieldSVG } from '../../assets/icons/icons8-wear-face-shield-50.svg'
import { ReactComponent as WearHalfMaskRespiratorSVG } from '../../assets/icons/icons8-wear-half-mask-respirator-50.svg'
import { ReactComponent as WearHelmetAndFaceShieldSVG } from '../../assets/icons/icons8-wear-helmet-and-face-shield-50.svg'
import { ReactComponent as WearHighVisibilityJacketSVG } from '../../assets/icons/icons8-wear-high-visibility-jacket-50.svg'
import { ReactComponent as WearLaboratoryCoatSVG } from '../../assets/icons/icons8-wear-laboratory-coat-50.svg'
import { ReactComponent as WearLifeJacketSVG } from '../../assets/icons/icons8-wear-life--jacket-50.svg'
import { ReactComponent as WearProtectiveClothingSVG } from '../../assets/icons/icons8-wear-protective-clothing-50.svg'
import { ReactComponent as WearRespiratorSVG } from '../../assets/icons/icons8-wear-respirator-50.svg'
import { ReactComponent as WearSafetyHarnessSVG } from '../../assets/icons/icons8-wear-safety-harness-50.svg'
import { ReactComponent as WearSafetyHelmetSVG } from '../../assets/icons/icons8-wear-safety-helmet-50.svg'
import { ReactComponent as WearSeatBeltsSVG } from '../../assets/icons/icons8-wear-seat-belts-50.svg'
import { ReactComponent as WearWeldingMaskSVG } from '../../assets/icons/icons8-wear-welding-mask-50.svg'
import { ReactComponent as WishListSVG } from '../../assets/icons/icons8-wish-list-50.svg'
import { ReactComponent as WorkSVG } from '../../assets/icons/icons8-work-50.svg'
import { ReactComponent as WorkerAuthorizationSVG } from '../../assets/icons/icons8-work-authorisation-50.svg'
import { ReactComponent as WorkerSVG } from '../../assets/icons/icons8-worker-50.svg'
import { ReactComponent as WorkerBeardSVG } from '../../assets/icons/icons8-worker-beard-50.svg'
import { ReactComponent as WorkersSVG } from '../../assets/icons/icons8-workers-50.svg'
import { ReactComponent as WritingDown } from '../../assets/icons/icons8-writing-down-50.svg'
import { ReactComponent as WwiGermanHelmet } from '../../assets/icons/icons8-wwi-german-helmet-50.svg'
import { ReactComponent as XlargeIconsSVG } from '../../assets/icons/icons8-xlarge-icons-50.svg'
import { ReactComponent as Xls50SVG } from '../../assets/icons/icons8-xls-50.svg'

import { ReactComponent as DepartamentoSVG } from '../../assets/icons/icons8-unidade-organizacional.svg'
import { ReactComponent as FuncaoSVG } from '../../assets/icons/icons8-funcao.svg'
import { ReactComponent as PuzzleSVG } from '../../assets/icons/icons8-puzzle.svg'
import { ReactComponent as VariableSVG } from '../../assets/icons/icons8-variable.svg'
import { ReactComponent as DiversitySVG } from '../../assets/icons/icons8-diversity.svg'
import { ReactComponent as SewingTapeMeasureSVG } from '../../assets/icons/icons8-sewing-tape-measure.svg'

import { ReactComponent as AddSVG } from '../../assets/icons/icons8-add-50.svg';
import { ReactComponent as AndroidOsSVG } from '../../assets/icons/icons8-android-os-50.svg';
import { ReactComponent as ApkSVG } from '../../assets/icons/icons8-apk-50.svg';
import { ReactComponent as AskQuestionSVG } from '../../assets/icons/icons8-ask-question-50.svg';
import { ReactComponent as BoxImportantSVG } from '../../assets/icons/icons8-box-important-50.svg';
import { ReactComponent as BusinessSVG } from '../../assets/icons/icons8-business-50.svg';
import { ReactComponent as Checkmark2SVG } from '../../assets/icons/icons8-checkmark-50-2.svg';
import { ReactComponent as CircledRightSVG } from '../../assets/icons/icons8-circled-right-50.svg';
import { ReactComponent as ClipboardSVG } from '../../assets/icons/icons8-clipboard-50.svg';
import { ReactComponent as Clock2SVG } from '../../assets/icons/icons8-clock-50-2.svg';
import { ReactComponent as CloudDoneSVG } from '../../assets/icons/icons8-cloud-done-50.svg';
import { ReactComponent as CloudRefreshSVG } from '../../assets/icons/icons8-cloud-refresh-50.svg';
import { ReactComponent as ConfinedSpacesSVG } from '../../assets/icons/icons8-confined-spaces-50.svg';
import { ReactComponent as ConnectedSVG } from '../../assets/icons/icons8-connected-50.svg';
import { ReactComponent as CsvSVG } from '../../assets/icons/icons8-csv-50.svg';
import { ReactComponent as CvSVG } from '../../assets/icons/icons8-cv-50.svg';
import { ReactComponent as DatabaseExportSVG } from '../../assets/icons/icons8-database-export-50.svg';
import { ReactComponent as DatabaseRestoreSVG } from '../../assets/icons/icons8-database-restore-50.svg';
import { ReactComponent as DisconnectMainPlugSVG } from '../../assets/icons/icons8-disconnect-main-plug-50.svg';
import { ReactComponent as DisconnectedSVG } from '../../assets/icons/icons8-disconnected-50.svg';
import { ReactComponent as DocumentSVG } from '../../assets/icons/icons8-document-50.svg';
import { ReactComponent as DocumentationSVG } from '../../assets/icons/icons8-documentation-50.svg';
import { ReactComponent as DoubleLeftSVG } from '../../assets/icons/icons8-double-left-50.svg';
import { ReactComponent as DoubleRightSVG } from '../../assets/icons/icons8-double-right-50.svg';
import { ReactComponent as ErrorCloudSVG } from '../../assets/icons/icons8-error-cloud-50.svg';
import { ReactComponent as FacialRecognition2SVG } from '../../assets/icons/icons8-facial-recognition-50-2.svg';
import { ReactComponent as FacialRecognition3SVG } from '../../assets/icons/icons8-facial-recognition-50-3.svg';
import { ReactComponent as Filter2SVG } from '../../assets/icons/icons8-filter-50-2.svg';
import { ReactComponent as GeneralMandatoryActionSVG } from '../../assets/icons/icons8-general-mandatory-action-50.svg';
import { ReactComponent as Gift2SVG } from '../../assets/icons/icons8-gift-50-2.svg';
import { ReactComponent as GiftSVG } from '../../assets/icons/icons8-gift-50.svg';
import { ReactComponent as GoBackSVG } from '../../assets/icons/icons8-go-back-50.svg';
import { ReactComponent as GoodPincodeSVG } from '../../assets/icons/icons8-good-pincode-50.svg';
import { ReactComponent as LearnSVG } from '../../assets/icons/icons8-learn-50.svg';
import { ReactComponent as LinechartFileSVG } from '../../assets/icons/icons8-linechart-file-50.svg';
import { ReactComponent as NameTagSVG } from '../../assets/icons/icons8-name-tag-50.svg';
import { ReactComponent as NoEntrySVG } from '../../assets/icons/icons8-no-entry-50.svg';
import { ReactComponent as PasswordResetSVG } from '../../assets/icons/icons8-password-reset-50.svg';
import { ReactComponent as PdfSVG } from '../../assets/icons/icons8-pdf-50.svg';
import { ReactComponent as PinPadSVG } from '../../assets/icons/icons8-pin-pad-50.svg';
import { ReactComponent as PreviewPaneSVG } from '../../assets/icons/icons8-preview-pane-50.svg';
import { ReactComponent as QrCodeSVG } from '../../assets/icons/icons8-qr-code-50.svg';
import { ReactComponent as RequestService2SVG } from '../../assets/icons/icons8-request-service-50-2.svg';
import { ReactComponent as RestApiSVG } from '../../assets/icons/icons8-rest-api-50.svg';
import { ReactComponent as SandTimerSVG } from '../../assets/icons/icons8-sand-timer-50.svg';
import { ReactComponent as SearchSVG } from '../../assets/icons/icons8-search-50.svg';
import { ReactComponent as ShowPermitCardSVG } from '../../assets/icons/icons8-show-permit-card-50.svg';
import { ReactComponent as SpeedometerSVG } from '../../assets/icons/icons8-speedometer-50.svg';
import { ReactComponent as SpinnerSVG } from '../../assets/icons/icons8-spinner-50.svg';
import { ReactComponent as StackCorrectlySVG } from '../../assets/icons/icons8-stack-correctly-50.svg';
import { ReactComponent as SupportSVG } from '../../assets/icons/icons8-support-50.svg';
import { ReactComponent as SwitchOffAfterUseSVG } from '../../assets/icons/icons8-switch-off-after-use-50.svg';
import { ReactComponent as SyncSVG } from '../../assets/icons/icons8-sync-50.svg';
import { ReactComponent as TicketSVG } from '../../assets/icons/icons8-ticket-50.svg';
import { ReactComponent as TruckSVG } from '../../assets/icons/icons8-truck-50.svg';
import { ReactComponent as UnitSVG } from '../../assets/icons/icons8-unit-50.svg';
import { ReactComponent as UseAdjustableGuardSVG } from '../../assets/icons/icons8-use-adjustable-guard-50.svg';
import { ReactComponent as UseGuardSVG } from '../../assets/icons/icons8-use-guard-50.svg';
import { ReactComponent as VendingMachineSVG } from '../../assets/icons/icons8-vending-machine-50.svg';
import { ReactComponent as VirusFreeSVG } from '../../assets/icons/icons8-virus-free-50.svg';
import { ReactComponent as WacomTabletSVG } from '../../assets/icons/icons8-wacom-tablet-50.svg';
import { ReactComponent as WallMountCameraSVG } from '../../assets/icons/icons8-wall-mount-camera-50.svg';
import { ReactComponent as WearProtectiveClothing2SVG } from '../../assets/icons/icons8-wear-protective-clothing-50-2.svg';
import { ReactComponent as WebsiteSVG } from '../../assets/icons/icons8-website-50.svg';
import { ReactComponent as WheelsChockedSVG } from '../../assets/icons/icons8-wheels-chocked-50.svg';
import { ReactComponent as WhoisSVG } from '../../assets/icons/icons8-whois-50.svg';
import { ReactComponent as WorkerIdCardSVG } from '../../assets/icons/icons8-worker-id-card-50.svg';
import { ReactComponent as WorkingAtHeightSVG } from '../../assets/icons/icons8-working-at-height-50.svg';
import { ReactComponent as WrongPincodeSVG } from '../../assets/icons/icons8-wrong-pincode-50.svg';
import { ReactComponent as ZipSVG } from '../../assets/icons/icons8-zip-50.svg';
import { ReactComponent as Xls502SVG } from '../../assets/icons/icons8-xls-50-2.svg';
import { ReactComponent as StockSVG } from '../../assets/icons/icons8-products-pile-50.svg';
import { ReactComponent as ClearSVG } from '../../assets/icons/icons8-clear.svg';
import { ReactComponent as EyeSVG } from '../../assets/icons/icons8-preview-pane-50.svg';

import { ReactComponent as AddIconSVG } from '../../assets/icons/icons8-add.svg';
import { ReactComponent as ControlPanelSVG } from '../../assets/icons/icons8-control-panel.svg';
import { ReactComponent as EngineCheckSVG } from '../../assets/icons/icons8-engine-check.svg';
import { ReactComponent as VenderEstoqueSVG } from '../../assets/icons/icons8-vender-estoque.svg';

const viewBox = "0 0 50 50"

export const AddUserMaleSkinType7Icon = (props) => <SvgIcon component={AddUserMaleSkinType7} viewBox={viewBox} {...props} />;
export const AdminSettingMaleIcon = (props) => <SvgIcon component={AdminSettingMale} viewBox={viewBox} {...props} />;
export const AtSignIcon = (props) => <SvgIcon component={AtSign} viewBox={viewBox} {...props} />;
export const AvailableUpdatesIcon = (props) => <SvgIcon component={AvailableUpdates} viewBox={viewBox} {...props} />;
export const BacklogIcon = (props) => <SvgIcon component={Backlog} viewBox={viewBox} {...props} />;
export const BasketIcon = (props) => <SvgIcon component={Basket} viewBox={viewBox} {...props} />;
export const BlueprintIcon = (props) => <SvgIcon component={Blueprint} viewBox={viewBox} {...props} />;
export const BriefCaseIcon = (props) => <SvgIcon component={BriefCase} viewBox={viewBox} {...props} />;
export const BusinessReportIcon = (props) => <SvgIcon component={BusinessReport} viewBox={viewBox} {...props} />;
export const ChainIcon = (props) => <SvgIcon component={Chain} viewBox={viewBox} {...props} />;
export const CheckAllIcon = (props) => <SvgIcon component={CheckAll} viewBox={viewBox} {...props} />;
export const CheckListIcon = (props) => <SvgIcon component={CheckList} viewBox={viewBox} {...props} />;
export const CheckMarkIcon = (props) => <SvgIcon component={CheckMark} viewBox={viewBox} {...props} />;
export const ClientManagementIcon = (props) => <SvgIcon component={ClientManagement} viewBox={viewBox} {...props} />;
export const ClockIcon = (props) => <SvgIcon component={Clock} viewBox={viewBox} {...props} />;
export const CloseWindowIcon = (props) => <SvgIcon component={CloseWindow} viewBox={viewBox} {...props} />;
export const CommentsIcon = (props) => <SvgIcon component={Comments} viewBox={viewBox} {...props} />;
export const ComplaintsIcon = (props) => <SvgIcon component={Complaints} viewBox={viewBox} {...props} />;
export const CyberSecurityIcon = (props) => <SvgIcon component={CyberSecurity} viewBox={viewBox} {...props} />;
export const CylindersChainedIcon = (props) => <SvgIcon component={CylindersChained} viewBox={viewBox} {...props} />;
export const DataBackupIcon = (props) => <SvgIcon component={DataBackupSVG} viewBox={viewBox} {...props} />;
export const DoNotDisturbIcon = (props) => <SvgIcon component={DoNotDisturbSVG} viewBox={viewBox} {...props} />;
export const DoNotDisturbBlueIcon = (props) => <SvgIcon component={DoNotDisturbBlueSVG} viewBox={viewBox} {...props} />;
export const DownloadIcon = (props) => <SvgIcon component={DownloadSVG} viewBox={viewBox} {...props} />;
export const EarProtectionIcon = (props) => <SvgIcon component={EarProtectionSVG} viewBox={viewBox} {...props} />;
export const EditIcon = (props) => <SvgIcon component={EditSVG} viewBox={viewBox} {...props} />;
export const EditRowIcon = (props) => <SvgIcon component={EditRowSVG} viewBox={viewBox} {...props} />;
export const ElectronicsIcon = (props) => <SvgIcon component={ElectronicsSVG} viewBox={viewBox} {...props} />;
export const EnvelopeIcon = (props) => <SvgIcon component={EnvelopeSVG} viewBox={viewBox} {...props} />;
export const ErrorIcon = (props) => <SvgIcon component={ErrorSVG} viewBox={viewBox} {...props} />;
export const ExchangeIcon = (props) => <SvgIcon component={ExchangeSVG} viewBox={viewBox} {...props} />;
export const ExpiredIcon = (props) => <SvgIcon component={ExpiredSVG} viewBox={viewBox} {...props} />;
export const EyeProtectionIcon = (props) => <SvgIcon component={EyeProtectionSVG} viewBox={viewBox} {...props} />;
export const FacialRecognitionIcon = (props) => <SvgIcon component={FacialRecognitionSVG} viewBox={viewBox} {...props} />;
export const FacialRecognitionIconChecked = (props) => <SvgIcon component={FacialRecognitionCheckedSVG} sx={{ width: 24, height: 24 }} viewBox={viewBox} {...props} />;
export const FacialRecognitionIconWarning = (props) => <SvgIcon component={FacialRecognitionWarningSVG} sx={{ width: 24, height: 24 }} viewBox={viewBox} {...props} />;
export const FacialRecognitionIconError = (props) => <SvgIcon component={FacialRecognitionErrorSVG} sx={{ width: 24, height: 24 }} viewBox={viewBox} {...props} />;
export const FactoryIcon = (props) => <SvgIcon component={FactorySVG} viewBox={viewBox} {...props} />;
export const FastDownloadIcon = (props) => <SvgIcon component={FastDownloadSVG} viewBox={viewBox} {...props} />;
export const FilterIcon = (props) => <SvgIcon component={FilterSVG} viewBox={viewBox} {...props} />;
export const FireExtinguisherIcon = (props) => <SvgIcon component={FireExtinguisherSVG} viewBox={viewBox} {...props} />;
export const FlickerFreeIcon = (props) => <SvgIcon component={FlickerFreeSVG} viewBox={viewBox} {...props} />;
export const FootProtectionIcon = (props) => <SvgIcon component={FootProtectionSVG} viewBox={viewBox} {...props} />;
export const FootWashIcon = (props) => <SvgIcon component={FootWashSVG} viewBox={viewBox} {...props} />;
export const ForkLiftIcon = (props) => <SvgIcon component={ForkLiftSVG} viewBox={viewBox} {...props} />;
export const GasMaskIcon = (props) => <SvgIcon component={GasMaskSVG} viewBox={viewBox} {...props} />;
export const GoalIcon = (props) => <SvgIcon component={GoalSVG} viewBox={viewBox} {...props} />;
export const HairProtectionIcon = (props) => <SvgIcon component={HairProtectionSVG} viewBox={viewBox} {...props} />;
export const HandProtectionIcon = (props) => <SvgIcon component={HandProtectionSVG} viewBox={viewBox} {...props} />;
export const HelpIcon = (props) => <SvgIcon component={HelpSVG} viewBox={viewBox} {...props} />;
export const HierarchyIcon = (props) => <SvgIcon component={HierarchySVG} viewBox={viewBox} {...props} />;
export const HomeIcon = (props) => <SvgIcon component={HomeSVG} viewBox={viewBox} {...props} />;
export const HomePageIcon = (props) => <SvgIcon component={HomePageSVG} viewBox={viewBox} {...props} />;
export const IdeaIcon = (props) => <SvgIcon component={IdeaSVG} viewBox={viewBox} {...props} />;
export const InfoIcon = (props) => <SvgIcon component={InfoSVG} viewBox={viewBox} {...props} />;
export const InspectionIcon = (props) => <SvgIcon component={InspectionSVG} viewBox={viewBox} {...props} />;
export const KeepCleanIcon = (props) => <SvgIcon component={KeepCleanSVG} viewBox={viewBox} {...props} />;
export const KeyIcon = (props) => <SvgIcon component={KeySVG} viewBox={viewBox} {...props} />;
export const AddKeyIcon = (props) => <SvgIcon component={AddKeySVG} viewBox={viewBox} {...props} />;
export const ResetKeyIcon = (props) => <SvgIcon component={ResetKeySVG} viewBox={viewBox} {...props} />;
export const LockIcon = (props) => <SvgIcon component={LockSVG} viewBox={viewBox} {...props} />;
export const LockIconChecked = (props) => <SvgIcon component={LockCheckedSVG} sx={{ width: 24, height: 24 }} viewBox={viewBox} {...props} />;
export const LockIconWarning = (props) => <SvgIcon component={LockWarningSVG} sx={{ width: 24, height: 24 }} viewBox={viewBox} {...props} />;
export const ManualIcon = (props) => <SvgIcon component={ManualSVG} viewBox={viewBox} {...props} />;
export const MechanicIcon = (props) => <SvgIcon component={MechanicSVG} viewBox={viewBox} {...props} />;
export const NotificationIcon = (props) => <SvgIcon component={NotificationSVG} viewBox={viewBox} {...props} />;
export const OfficeIcon = (props) => <SvgIcon component={OfficeSVG} viewBox={viewBox} {...props} />;
export const OkIcon = (props) => <SvgIcon component={OkSVG} viewBox={viewBox} {...props} />;
export const OnlineSupportIcon = (props) => <SvgIcon component={OnlineSupportSVG} viewBox={viewBox} {...props} />;
export const OrganizationIcon = (props) => <SvgIcon component={OrganizationSVG} viewBox={viewBox} {...props} />;
export const PadlockIcon = (props) => <SvgIcon component={PadlockSVG} viewBox={viewBox} {...props} />;
export const PedestriansIcon = (props) => <SvgIcon component={PedestriansSVG} viewBox={viewBox} {...props} />;
export const PeopleIcon = (props) => <SvgIcon component={PeopleSVG} viewBox={viewBox} {...props} />;
export const PoisonIcon = (props) => <SvgIcon component={PoisonSVG} viewBox={viewBox} {...props} />;
export const PresentationIcon = (props) => <SvgIcon component={PresentationSVG} viewBox={viewBox} {...props} />;
export const ProductDocumentsIcon = (props) => <SvgIcon component={ProductDocumentsSVG} viewBox={viewBox} {...props} />;
export const ProtectionMaskIcon = (props) => <SvgIcon component={ProtectionMaskSVG} viewBox={viewBox} {...props} />;
export const ProtectiveGearAIcon = (props) => <SvgIcon component={ProtectiveGearASVG} viewBox="0 0 64 64" {...props} />;
export const ProtectiveGearBIcon = (props) => <SvgIcon component={ProtectiveGearBSVG} viewBox="0 0 256 256" {...props} />;
export const ProtectiveGearCIcon = (props) => <SvgIcon component={ProtectiveGearCSVG} viewBox="0 0 256 256" {...props} />;
export const ProtectiveGlassesIcon = (props) => <SvgIcon component={ProtectiveGlassesSVG} viewBox={viewBox} {...props} />;
export const ProximitySensorIcon = (props) => <SvgIcon component={ProximitySensorSVG} viewBox={viewBox} {...props} />;
export const RadarIcon = (props) => <SvgIcon component={RadarSVG} viewBox={viewBox} {...props} />;
export const RequestServiceIcon = (props) => <SvgIcon component={RequestServiceSVG} viewBox={viewBox} {...props} />;
export const RestartIcon = (props) => <SvgIcon component={RestartSVG} viewBox={viewBox} {...props} />;
export const RfidSensorIcon = (props) => <SvgIcon component={RfidSensorSVG} viewBox={viewBox} {...props} />;
export const RfidSignalIcon = (props) => <SvgIcon component={RfidSignalSVG} viewBox={viewBox} {...props} />;
export const SafetyHatIcon = (props) => <SvgIcon component={SafetyHatSVG} viewBox={viewBox} {...props} />;
export const SafetyVestIcon = (props) => <SvgIcon component={SafetyVestSVG} viewBox={viewBox} {...props} />;
export const SaveIcon = (props) => <SvgIcon component={SaveSVG} viewBox={viewBox} {...props} />;
export const SecurityGuardSkinType3Icon = (props) => <SvgIcon component={SecurityGuardSkinType3SVG} viewBox={viewBox} {...props} />;
export const SettingsIcon = (props) => <SvgIcon component={SettingsSVG} viewBox={viewBox} {...props} />;
export const StatisticsIcon = (props) => <SvgIcon component={StatisticsSVG} viewBox={viewBox} {...props} />;
export const SystemInformationIcon = (props) => <SvgIcon component={SystemInformationSVG} viewBox={viewBox} {...props} />;
export const ThumbnailsIcon = (props) => <SvgIcon component={ThumbnailsSVG} viewBox={viewBox} {...props} />;
export const TickBoxIcon = (props) => <SvgIcon component={TickBoxSVG} viewBox={viewBox} {...props} />;
export const ToDoIcon = (props) => <SvgIcon component={ToDoSVG} viewBox={viewBox} {...props} />;
export const ToolsIcon = (props) => <SvgIcon component={ToolsSVG} viewBox={viewBox} {...props} />;
export const TouchIdIcon = (props) => <SvgIcon component={TouchIdSVG} viewBox={viewBox} {...props} />;
export const TrainingIcon = (props) => <SvgIcon component={TrainingSVG} viewBox={viewBox} {...props} />;
export const TrashIcon = (props) => <SvgIcon component={TrashSVG} viewBox={viewBox} {...props} />;
export const UnavailableIcon = (props) => <SvgIcon component={UnavailableSVG} viewBox={viewBox} {...props} />;
export const UseForkliftIcon = (props) => <SvgIcon component={UseForkliftSVG} viewBox={viewBox} {...props} />;
export const UserLocationIcon = (props) => <SvgIcon component={UserLocationSVG} viewBox={viewBox} {...props} />;
export const VerifiedAccountIcon = (props) => <SvgIcon component={VerifiedAccountSVG} viewBox={viewBox} {...props} />;
export const VerfiedBadgeIcon = (props) => <SvgIcon component={VerfiedBadgeSVG} viewBox={viewBox} {...props} />;
export const ViewDetailsIcon = (props) => <SvgIcon component={ViewDetailsSVG} viewBox={viewBox} {...props} />;
export const WarrantyIcon = (props) => <SvgIcon component={WarrantySVG} viewBox={viewBox} {...props} />;
export const WashYourHandIcon = (props) => <SvgIcon component={WashYourHandSVG} viewBox={viewBox} {...props} />;
export const WearAntiStaticBootsIcon = (props) => <SvgIcon component={WearAntiStaticBootsSVG} viewBox={viewBox} {...props} />;
export const WearAntiStaticGlovesIcon = (props) => <SvgIcon component={WearAntiStaticGlovesSVG} viewBox={viewBox} {...props} />;
export const WearApronIcon = (props) => <SvgIcon component={WearApronSVG} viewBox={viewBox} {...props} />;
export const WearBreathingApparatusIcon = (props) => <SvgIcon component={WearBreathingApparatusSVG} viewBox={viewBox} {...props} />;
export const WearDustMaskIcon = (props) => <SvgIcon component={WearDustMaskSVG} viewBox={viewBox} {...props} />;
export const WearEarPlugAndGogglesIcon = (props) => <SvgIcon component={WearEarPlugAndGogglesSVG} viewBox={viewBox} {...props} />;
export const WearFaceShieldIcon = (props) => <SvgIcon component={WearFaceShieldSVG} viewBox={viewBox} {...props} />;
export const WearHalfMaskRespiratorIcon = (props) => <SvgIcon component={WearHalfMaskRespiratorSVG} viewBox={viewBox} {...props} />;
export const WearHelmetAndFaceShieldIcon = (props) => <SvgIcon component={WearHelmetAndFaceShieldSVG} viewBox={viewBox} {...props} />;
export const WearHighVisibilityJacketIcon = (props) => <SvgIcon component={WearHighVisibilityJacketSVG} viewBox={viewBox} {...props} />;
export const WearLaboratoryCoatIcon = (props) => <SvgIcon component={WearLaboratoryCoatSVG} viewBox={viewBox} {...props} />;
export const WearLifeJacketIcon = (props) => <SvgIcon component={WearLifeJacketSVG} viewBox={viewBox} {...props} />;
export const WearProtectiveClothingIcon = (props) => <SvgIcon component={WearProtectiveClothingSVG} viewBox={viewBox} {...props} />;
export const WearRespiratorIcon = (props) => <SvgIcon component={WearRespiratorSVG} viewBox={viewBox} {...props} />;
export const WearSafetyHarnessIcon = (props) => <SvgIcon component={WearSafetyHarnessSVG} viewBox={viewBox} {...props} />;
export const WearSafetyHelmetIcon = (props) => <SvgIcon component={WearSafetyHelmetSVG} viewBox={viewBox} {...props} />;
export const WearSeatBeltsIcon = (props) => <SvgIcon component={WearSeatBeltsSVG} viewBox={viewBox} {...props} />;
export const WearWeldingMaskIcon = (props) => <SvgIcon component={WearWeldingMaskSVG} viewBox={viewBox} {...props} />;
export const WishListIcon = (props) => <SvgIcon component={WishListSVG} viewBox={viewBox} {...props} />;
export const WorkIcon = (props) => <SvgIcon component={WorkSVG} viewBox={viewBox} {...props} />;
export const WorkerAuthorizationIcon = (props) => <SvgIcon component={WorkerAuthorizationSVG} viewBox={viewBox} {...props} />;
export const WorkerIcon = (props) => <SvgIcon component={WorkerSVG} viewBox={viewBox} {...props} />;
export const WorkerBeardIcon = (props) => <SvgIcon component={WorkerBeardSVG} viewBox={viewBox} {...props} />;
export const WorkersIcon = (props) => <SvgIcon component={WorkersSVG} viewBox={viewBox} {...props} />;
export const WritingDownIcon = (props) => <SvgIcon component={WritingDown} viewBox={viewBox} {...props} />;
export const WwiGermanHelmetIcon = (props) => <SvgIcon component={WwiGermanHelmet} viewBox={viewBox} {...props} />;
export const XlargeIconsIcon = (props) => <SvgIcon component={XlargeIconsSVG} viewBox={viewBox} {...props} />;
export const Xls50Icon = (props) => <SvgIcon component={Xls50SVG} viewBox={viewBox} {...props} />;
export const DepartamentoIcon = (props) => <SvgIcon component={DepartamentoSVG} viewBox={viewBox} {...props} />;
export const FuncaoIcon = (props) => <SvgIcon component={FuncaoSVG} viewBox={viewBox} {...props} />;
export const PuzzleIcon = (props) => <SvgIcon component={PuzzleSVG} viewBox={viewBox} {...props} />;
export const VariableIcon = (props) => <SvgIcon component={VariableSVG} viewBox={viewBox} {...props} />;
export const DiversityIcon = (props) => <SvgIcon component={DiversitySVG} viewBox={viewBox} {...props} />;
export const SewingTapeMeasureIcon = (props) => <SvgIcon component={SewingTapeMeasureSVG} viewBox={viewBox} {...props} />;

export const AddIcon = (props) => <SvgIcon component={AddSVG} viewBox={viewBox} {...props} />;
export const AndroidOsIcon = (props) => <SvgIcon component={AndroidOsSVG} viewBox={viewBox} {...props} />;
export const ApkIcon = (props) => <SvgIcon component={ApkSVG} viewBox={viewBox} {...props} />;
export const AskQuestionIcon = (props) => <SvgIcon component={AskQuestionSVG} viewBox={viewBox} {...props} />;
export const BoxImportantIcon = (props) => <SvgIcon component={BoxImportantSVG} viewBox={viewBox} {...props} />;
export const BusinessIcon = (props) => <SvgIcon component={BusinessSVG} viewBox={viewBox} {...props} />;
export const Checkmark2Icon = (props) => <SvgIcon component={Checkmark2SVG} viewBox={viewBox} {...props} />;
export const CircledRightIcon = (props) => <SvgIcon component={CircledRightSVG} viewBox={viewBox} {...props} />;
export const ClipboardIcon = (props) => <SvgIcon component={ClipboardSVG} viewBox={viewBox} {...props} />;
export const Clock2Icon = (props) => <SvgIcon component={Clock2SVG} viewBox={viewBox} {...props} />;
export const CloudDoneIcon = (props) => <SvgIcon component={CloudDoneSVG} viewBox={viewBox} {...props} />;
export const CloudRefreshIcon = (props) => <SvgIcon component={CloudRefreshSVG} viewBox={viewBox} {...props} />;
export const ConfinedSpacesIcon = (props) => <SvgIcon component={ConfinedSpacesSVG} viewBox={viewBox} {...props} />;
export const ConnectedIcon = (props) => <SvgIcon component={ConnectedSVG} viewBox={viewBox} {...props} />;
export const CsvIcon = (props) => <SvgIcon component={CsvSVG} viewBox={viewBox} {...props} />;
export const CvIcon = (props) => <SvgIcon component={CvSVG} viewBox={viewBox} {...props} />;
export const DatabaseExportIcon = (props) => <SvgIcon component={DatabaseExportSVG} viewBox={viewBox} {...props} />;
export const DatabaseRestoreIcon = (props) => <SvgIcon component={DatabaseRestoreSVG} viewBox={viewBox} {...props} />;
export const DisconnectMainPlugIcon = (props) => <SvgIcon component={DisconnectMainPlugSVG} viewBox={viewBox} {...props} />;
export const DisconnectedIcon = (props) => <SvgIcon component={DisconnectedSVG} viewBox={viewBox} {...props} />;
export const DocumentIcon = (props) => <SvgIcon component={DocumentSVG} viewBox={viewBox} {...props} />;
export const DocumentationIcon = (props) => <SvgIcon component={DocumentationSVG} viewBox={viewBox} {...props} />;
export const DoubleLeftIcon = (props) => <SvgIcon component={DoubleLeftSVG} viewBox={viewBox} {...props} />;
export const DoubleRightIcon = (props) => <SvgIcon component={DoubleRightSVG} viewBox={viewBox} {...props} />;
export const ErrorCloudIcon = (props) => <SvgIcon component={ErrorCloudSVG} viewBox={viewBox} {...props} />;
export const FacialRecognition2Icon = (props) => <SvgIcon component={FacialRecognition2SVG} viewBox={viewBox} {...props} />;
export const FacialRecognition3Icon = (props) => <SvgIcon component={FacialRecognition3SVG} viewBox={viewBox} {...props} />;
export const Filter2Icon = (props) => <SvgIcon component={Filter2SVG} viewBox={viewBox} {...props} />;
export const GeneralMandatoryActionIcon = (props) => <SvgIcon component={GeneralMandatoryActionSVG} viewBox={viewBox} {...props} />;
export const Gift2Icon = (props) => <SvgIcon component={Gift2SVG} viewBox={viewBox} {...props} />;
export const GiftIcon = (props) => <SvgIcon component={GiftSVG} viewBox={viewBox} {...props} />;
export const GoBackIcon = (props) => <SvgIcon component={GoBackSVG} viewBox={viewBox} {...props} />;
export const GoodPincodeIcon = (props) => <SvgIcon component={GoodPincodeSVG} viewBox={viewBox} {...props} />;
export const LearnIcon = (props) => <SvgIcon component={LearnSVG} viewBox={viewBox} {...props} />;
export const LinechartFileIcon = (props) => <SvgIcon component={LinechartFileSVG} viewBox={viewBox} {...props} />;
export const NameTagIcon = (props) => <SvgIcon component={NameTagSVG} viewBox={viewBox} {...props} />;
export const NoEntryIcon = (props) => <SvgIcon component={NoEntrySVG} viewBox={viewBox} {...props} />;
export const PasswordResetIcon = (props) => <SvgIcon component={PasswordResetSVG} viewBox={viewBox} {...props} />;
export const PdfIcon = (props) => <SvgIcon component={PdfSVG} viewBox={viewBox} {...props} />;
export const PinPadIcon = (props) => <SvgIcon component={PinPadSVG} viewBox={viewBox} {...props} />;
export const PreviewPaneIcon = (props) => <SvgIcon component={PreviewPaneSVG} viewBox={viewBox} {...props} />;
export const QrCodeIcon = (props) => <SvgIcon component={QrCodeSVG} viewBox={viewBox} {...props} />;
export const RequestService2Icon = (props) => <SvgIcon component={RequestService2SVG} viewBox={viewBox} {...props} />;
export const RestApiIcon = (props) => <SvgIcon component={RestApiSVG} viewBox={viewBox} {...props} />;
export const SandTimerIcon = (props) => <SvgIcon component={SandTimerSVG} viewBox={viewBox} {...props} />;
export const SearchIcon = (props) => <SvgIcon component={SearchSVG} viewBox={viewBox} {...props} />;
export const ShowPermitCardIcon = (props) => <SvgIcon component={ShowPermitCardSVG} viewBox={viewBox} {...props} />;
export const SpeedometerIcon = (props) => <SvgIcon component={SpeedometerSVG} viewBox={viewBox} {...props} />;
export const SpinnerIcon = (props) => <SvgIcon component={SpinnerSVG} viewBox={viewBox} {...props} />;
export const StackCorrectlyIcon = (props) => <SvgIcon component={StackCorrectlySVG} viewBox={viewBox} {...props} />;
export const SupportIcon = (props) => <SvgIcon component={SupportSVG} viewBox={viewBox} {...props} />;
export const SwitchOffAfterUseIcon = (props) => <SvgIcon component={SwitchOffAfterUseSVG} viewBox={viewBox} {...props} />;
export const SyncIcon = (props) => <SvgIcon component={SyncSVG} viewBox={viewBox} {...props} />;
export const TicketIcon = (props) => <SvgIcon component={TicketSVG} viewBox={viewBox} {...props} />;
export const TruckIcon = (props) => <SvgIcon component={TruckSVG} viewBox={viewBox} {...props} />;
export const UnitIcon = (props) => <SvgIcon component={UnitSVG} viewBox={viewBox} {...props} />;
export const UseAdjustableGuardIcon = (props) => <SvgIcon component={UseAdjustableGuardSVG} viewBox={viewBox} {...props} />;
export const UseGuardIcon = (props) => <SvgIcon component={UseGuardSVG} viewBox={viewBox} {...props} />;
export const VendingMachineIcon = (props) => <SvgIcon component={VendingMachineSVG} viewBox={viewBox} {...props} />;
export const VirusFreeIcon = (props) => <SvgIcon component={VirusFreeSVG} viewBox={viewBox} {...props} />;
export const WacomTabletIcon = (props) => <SvgIcon component={WacomTabletSVG} viewBox={viewBox} {...props} />;
export const WallMountCameraIcon = (props) => <SvgIcon component={WallMountCameraSVG} viewBox={viewBox} {...props} />;
export const WearProtectiveClothing2Icon = (props) => <SvgIcon component={WearProtectiveClothing2SVG} viewBox={viewBox} {...props} />;
export const WebsiteIcon = (props) => <SvgIcon component={WebsiteSVG} viewBox={viewBox} {...props} />;
export const WheelsChockedIcon = (props) => <SvgIcon component={WheelsChockedSVG} viewBox={viewBox} {...props} />;
export const WhoisIcon = (props) => <SvgIcon component={WhoisSVG} viewBox={viewBox} {...props} />;
export const WorkerIdCardIcon = (props) => <SvgIcon component={WorkerIdCardSVG} viewBox={viewBox} {...props} />;
export const WorkingAtHeightIcon = (props) => <SvgIcon component={WorkingAtHeightSVG} viewBox={viewBox} {...props} />;
export const WrongPincodeIcon = (props) => <SvgIcon component={WrongPincodeSVG} viewBox={viewBox} {...props} />;
export const ZipIcon = (props) => <SvgIcon component={ZipSVG} viewBox={viewBox} {...props} />;
export const Xls502Icon = (props) => <SvgIcon component={Xls502SVG} viewBox={viewBox} {...props} />;
export const StockIcon = (props) => <SvgIcon component={StockSVG} viewBox={viewBox} {...props} />;
export const ClearIcon = (props) => <SvgIcon component={ClearSVG} viewBox={viewBox} {...props} />;
export const EyeIcon = (props) => <SvgIcon component={EyeSVG} viewBox={viewBox} {...props} />;

export const Add2Icon = (props) => <SvgIcon component={AddIconSVG} viewBox={viewBox} {...props} />;
export const EngineCheckIcon = (props) => <SvgIcon component={EngineCheckSVG} viewBox={viewBox} {...props} />;
export const ControlPanelIcon = (props) => <SvgIcon component={ControlPanelSVG} viewBox={viewBox} {...props} />;
export const VenderEstoqueIcon = (props) => <SvgIcon component={VenderEstoqueSVG} viewBox={viewBox} {...props} />;