import {
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    Button,
    Divider,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Paper,
    Typography,
    Box
} from "@mui/material";
import { grey } from "@mui/material/colors";

export const AbastecimentosDetailModal = ({ open, onClose, movimentacoesDetails }) => {
    const retiradas = movimentacoesDetails ? movimentacoesDetails.flat() : [];

    const totalItensRetirados = retiradas.reduce((sum, retirada) => sum + (retirada.qtd || 0), 0);

    return (
        <Dialog open={open} onClose={onClose} maxWidth="md" fullWidth>
            <DialogTitle>Retiradas</DialogTitle>
            <Divider />
            <DialogContent>
                {retiradas.length > 0 ? (
                    <>
                        <TableContainer component={Paper}>
                            <Table>
                                <TableHead>
                                    <TableRow>
                                        <TableCell><strong>Código</strong></TableCell>
                                        <TableCell><strong>Nome</strong></TableCell>
                                        <TableCell><strong>Quantidade</strong></TableCell>
                                        <TableCell><strong>Data</strong></TableCell>
                                        <TableCell><strong>Retirada extra</strong></TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {retiradas.map((retirada, index) => (
                                        <TableRow key={index}>
                                            <TableCell>{retirada.codigo}</TableCell>
                                            <TableCell>{retirada.pessoa?.nome || "N/A"}</TableCell>
                                            <TableCell>{retirada.qtd}</TableCell>
                                            <TableCell>{retirada.data}</TableCell>
                                            <TableCell>{retirada.isExtra ? 'Sim' : 'Não'}</TableCell>
                                        </TableRow>
                                    ))}
                                </TableBody>
                            </Table>
                        </TableContainer>

                        <Box sx={{ mt: 2, textAlign: "left", bgcolor: grey[200], p: 1, borderRadius: 1 }}>
                            <Typography variant="body2" sx={{ color: grey[700], fontWeight: "bold" }}>
                                Total de itens retirados: {totalItensRetirados}
                            </Typography>
                        </Box>
                    </>
                ) : (
                    <Typography variant='subtitle1'>Não há retiradas nesse período de tempo</Typography>
                )}
            </DialogContent>

            <DialogActions>
                <Button onClick={onClose} color="background" variant='contained'>
                    Fechar
                </Button>
            </DialogActions>
        </Dialog>
    );
};
