import dayjs from 'dayjs';
import { List, ListItem, ListItemText, Typography } from '@mui/material';

const keyMappings = {
  dt_ponto_alerta: 'Data do Ponto de Alerta',
  dt_prox_retirada: 'Data da Próxima Retirada',
  ponto_entrega: 'Ponto de Entrega',
  data_ocorrencia: 'Data da Ocorrência',
};

export function formatKey(key) {
  return typeof key === 'string' ? keyMappings[key] || key.charAt(0).toUpperCase() + key.slice(1) : key;
}

function renderArray(value) {
  return (
    <List dense sx={{ listStyleType: 'circle', paddingTop: 0.2, paddingBottom: 0.5, paddingLeft: 2.2 }}>
      {value.map((item, index) => (
        <ListItem key={index} sx={{ display: 'list-item', paddingTop: 0, paddingBottom: 0, paddingLeft: 0 }}>
          <ListItemText primary={item} sx={{ margin: 0 }} />
        </ListItem>
      ))}
    </List>
  );
}

function renderObject(value, showId) {
  return (
    <List dense sx={{ listStyleType: 'circle', paddingTop: 0.2, paddingBottom: 0.5, paddingLeft: 2.2 }}>
      {Object.entries(value).map(([subKey, subValue]) =>
        subKey !== 'id' || showId ? (
          <ListItem key={subKey} sx={{ display: 'list-item', paddingTop: 0, paddingBottom: 0, paddingLeft: 0 }}>
            <ListItemText
              primary={
                <>
                  <span style={{ fontWeight: 600 }}>{formatKey(subKey)}:</span> {renderValue(subValue)}
                </>
              }
            />
          </ListItem>
        ) : null
      )}
    </List>
  );
}

const dateFormats = ['YYYY-MM-DD', 'DD/MM/YYYY', 'DD-MM-YYYY HH:mm:ss', 'YYYY-MM-DD HH:mm:ss'];
function renderDate(value) {
  // strict check for date formats
  const validFormat = dateFormats.find((format) => dayjs(value, format, true).isValid());
  if (validFormat) {
    return dayjs(value, validFormat).format(validFormat.includes('HH:mm:ss') ? 'DD/MM/YYYY HH:mm' : 'DD/MM/YYYY');
  }
}

export function renderValue(value, showId = false) {
  if (value === '' || value === null || value === undefined) {
    return (
      <Typography variant="body2" component="span" sx={{ fontStyle: 'italic', color: 'grey.700' }}>
        Não informado
      </Typography>
    );
  }

  if (Array.isArray(value)) return renderArray(value);

  if (typeof value === 'object') return renderObject(value, showId);

  // verify if is a string with only numbers
  if (typeof value === 'string' && !/[a-zA-Z]/.test(value)) return value;

  if (typeof value === 'boolean') return value ? 'Sim' : 'Não';

  return (
    <Typography variant="body2" component="span">
      {value}
    </Typography>
  );
}