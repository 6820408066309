import { Dialog, DialogActions, DialogContent, DialogTitle, Divider, Stack, Typography, Button } from '@mui/material';
import { grey } from '@mui/material/colors';
import {ContentCopy, Print} from "@mui/icons-material";
import {useState} from "react";
import {useCommonItems} from "../../../../contexts/CommonItensProvider";


export const RequestErrorDetailModal = ({ open, onClose, requestErrorsDetails }) => {
    const { exibirAlerta } = useCommonItems();

    const formattedData = requestErrorsDetails ? JSON.stringify(requestErrorsDetails, null, 2) : "";

    const handleCopy = () => {
        navigator.clipboard.writeText(formattedData);
        exibirAlerta('Sucesso', 'Requisição copiada com sucesso', 'success');
    };

    return (
        <>
            <Dialog open={open} onClose={onClose} maxWidth="md" fullWidth>
                <DialogTitle>Detalhes da Requisição</DialogTitle>

                <Divider />

                <DialogContent>
                    {requestErrorsDetails ? (
                        <Stack spacing={2} sx={{ width: "100%", bgcolor: grey[100], p: 2, borderRadius: 2, fontFamily: 'monospace', whiteSpace: 'pre-wrap', overflowX: 'auto' }}>
                            <Typography variant='body2' component='pre' sx={{ m: 0 }}>
                                {formattedData}
                            </Typography>
                        </Stack>
                    ) : (
                        <Typography variant='subtitle1'>Não há dados de requisição para este erro</Typography>
                    )}
                </DialogContent>

                <DialogActions>
                    <Button onClick={onClose} color="background" variant='contained'>
                        Fechar
                    </Button>
                    <Button color="primary" variant="contained" startIcon={<ContentCopy />} onClick={handleCopy}>
                        Copiar
                    </Button>
                </DialogActions>
            </Dialog>


        </>
    );
};
