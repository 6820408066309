import React, { useEffect, useMemo, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useQuery } from '@tanstack/react-query';
import dayjs from 'dayjs';
import {
    Box,
    Breadcrumbs,
    Button,
    CircularProgress,
    Grid,
    LinearProgress,
    MenuItem,
    TextField,
    Typography
} from '@mui/material';
import { DataGrid, ptBR } from '@mui/x-data-grid';
import {FilterAlt, Home as HomeIcon, Print, ProductionQuantityLimits, RemoveRedEye} from '@mui/icons-material';
import PageTitle from '../../../common/PageTitle';
import LinkNavegacao from '../../../common/Link';
import { SelectEmpresaFilter } from '../../../common/SelectEmpresaFilter';
import { useAppContext } from '../../../../contexts/AppContext';
import { checkPermissionsAndRedirect, getFormErrorMessage, verifyFieldsAreFilled } from '../../../../configs/functions';
import { listaEmpresas } from '../../management/companies/configs/functions';
import {exportAbastecimentoRetiradasToPDF } from './configs/utils';
import {getAbastecimentoRetiradasData } from './configs/functions';
import { useCommonItems } from '../../../../contexts/CommonItensProvider';
import { TooltipSelectDisabled } from '../../../common/TooltipSelectDisabled';
import { carregaEquipamentos } from '../equipments/configs/functions';
import { CustomToolbarReports } from '../../../common/CustomToolbarReports';
import { grey } from '@mui/material/colors';
import DateRangePicker from '../../../common/DateRangePicker';
import {useModuleContext} from "../../../../contexts/ModuleProvider";
import {listaProdutosEquipamento} from "../products/configs/functions";
import {AbastecimentosDetailModal} from "./components/AbastecimentosDetailModal";

export function RelatorioAbastecimentoRetiradas() {
    const { dadosUsuario, getEmpresaIdSession, setEmpresaIdSession } = useAppContext();
    const { exibirAlerta, exibirDialog, handleCloseDialog } = useCommonItems();
    const { verifyIntegrationMode } = useModuleContext();

    // estados para controle de paginacao
    const [numTotalItems, setNumTotalItems] = useState(0);

    const [modalOpen, setModalOpen] = useState(false);
    const [dateRange, setDateRange] = useState([null, null]);

    const [movimentacoes, setMovimentacoes] = useState([]);
    const [equipamentos, setEquipamentos] = useState([]);
    const [produtos, setProdutos] = useState([]);
    const [movimentacoesDetails, setMovimentacoesDetails] = useState([]);

    const [open, setOpen] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [isLoadingProducts, setIsLoadingProducts] = useState(false);
    const [isSearched, setIsSearched] = useState(false);

    const [selectedEmpresaId, setSelectedEmpresaId] = useState(null);
    const [selectedEquipamentoId, setSelectedEquipamentoId] = useState(null);

    const {
        handleSubmit,
        setValue,
        getValues,
        clearErrors,
        reset,
        control,
        formState: { errors },
    } = useForm();

    const { data: empresasData, isLoading: isLoadingEmpresasData, isPending } = useQuery({
        queryKey: ['listaEmpresas'],
        queryFn: async () => {
            const response = await listaEmpresas();
            const empresasData = response?.data.data;

            let defaultEmpresaId = null;
            const selectedEmpresaIdSession = getEmpresaIdSession();

            if (selectedEmpresaIdSession) {
                defaultEmpresaId = selectedEmpresaIdSession;
            } else {
                defaultEmpresaId = empresasData[0].id;
                setEmpresaIdSession(empresasData[0].id);
            }

            setSelectedEmpresaId(defaultEmpresaId);

            const defaultValues = {
                id_empresas: defaultEmpresaId,
                id_equipamento: '',
                id_produto: '',
                data_inicio: dayjs().subtract(1, 'month'),
                data_fim: dayjs(),
            };
            reset(defaultValues);

            return response;
        },
        refetchOnWindowFocus: false,
    });

    const empresas = empresasData?.data.data || [];

    {/** Funções de filtro **/}
    const listaEquipamentos = async (empresaId) => {
        try {
            exibirDialog('Carregando Equipamentos...');
            const filterAllMachines = { id_empresas: Array.isArray(empresaId) ? empresaId : [empresaId], tipo: 'maquina' };
            const response = await carregaEquipamentos(filterAllMachines);
            setEquipamentos(response.data.data);
        } catch (error) {
            exibirAlerta('Erro ao carregar os Equipamentos', '', 'error');
        } finally {
            handleCloseDialog();
        }
    }

    const carregaProdutos = async (equpamentoId) => {
        try {
            setIsLoadingProducts(true);
            const res = await listaProdutosEquipamento({ id_equipamento: equpamentoId  });
            setProdutos(res?.data.data);
        } catch (error) {
            exibirAlerta('Erro ao carregar os Produtos', '', 'error');
        } finally {
            setIsLoadingProducts(false);
        }
    }

    {/** Busca e carregamentos de dados **/}
    async function loadReportData(id_empresa, id_equipamento, id_produto, data_inicio, data_fim) {
        if (!id_empresa) return;

        try {
            setIsLoading(true);

            data_inicio = dayjs(data_inicio).format('YYYY-MM-DD');
            data_fim = dayjs(data_fim).format('YYYY-MM-DD');

            const filter = {
                data_inicio,
                data_fim
            };

            if (id_empresa === 'all') {
                filter.empresa = 1;
            } else {
                filter.id_empresa = id_empresa;
            }

            if (id_equipamento) filter.id_equipamento = id_equipamento;
            if (id_produto) filter.id_produto = id_produto;

            const res = await getAbastecimentoRetiradasData(filter);
            setMovimentacoes(res.data);
            setNumTotalItems(res.data.numero_total);
        } catch (error) {
            exibirAlerta('Ops', 'Ocorreu um erro ao carregar dados do relatório.', 'error');
        } finally {
            setIsLoading(false);
        }
    }

    const onSubmit = async (data) => {
        try {
            setIsSearched(true);
            loadReportData(data.id_empresas, data.id_equipamento, data.id_produto, data.data_inicio, data.data_fim);
        } catch (error) {
            exibirAlerta('Erro', 'Erro ao carregar dados do relatório', 'error');
            setIsSearched(false);
        }
    };

    function getDataEmpresa(empresaId) {
        listaEquipamentos(empresaId);
    }

    {/** Effects **/}
    useEffect(() => {
        const requiredPermissionsView = ["admin", "admin_relatorio"];
        checkPermissionsAndRedirect(requiredPermissionsView);

        return () => {
            reset();
        }
    }, []);

    useEffect(() => {
        if (selectedEmpresaId) {
            getDataEmpresa(selectedEmpresaId);
        }
    }, [selectedEmpresaId]);

    useEffect(() => {
        const id_empresas = selectedEmpresaId;
        const id_equipamento = getValues('id_equipamento');
        const id_produto = getValues('id_produto');
        const data_inicio = getValues('data_inicio')
        const data_fim = getValues('data_fim')

        if (!id_empresas || !data_inicio || !data_fim) {
            return;
        }

        loadReportData(id_empresas, id_equipamento, id_produto, data_inicio, data_fim);
    }, []);

    {/** Export **/}
    function exportDataToPDF(visibleColumns, reportName) {
        if (!getValues('id_empresas')) {
            return exibirAlerta('Ops', 'Selecione ao menos empresa', 'warning');
        }

        const dataInicio = getValues('data_inicio');
        const dataFim = getValues('data_fim');

        if (!dataInicio || !dataFim) {
            return exibirAlerta('Ops', 'Selecione um período para exportar', 'warning');
        }

        try {
            exportAbastecimentoRetiradasToPDF({
                tableData: dados.linhas,
                reportName,
                dataInicio: dayjs(dataInicio).format('DD/MM/YYYY'),
                dataFim: dayjs(dataFim).format('DD/MM/YYYY'),
                selectEmpresa: empresas.find(empresa => empresa.id === selectedEmpresaId),
                dadosUsuario
            })
            exibirAlerta('Relatório gerado com sucesso!', '', 'success');
        } catch (error) {
            exibirAlerta('Erro', 'Ocorreu um erro ao exportar dados para PDF', 'error');
            console.log(error);
        }
    }

    const dados = tableRows(movimentacoes, setModalOpen, exibirAlerta, setMovimentacoesDetails);
    return (
        <Grid
            container
            direction="row"
            justifyContent="flex-start"
            alignItems="stretch"
            spacing={3}
        >
            <Grid item xs={12}>
                <Breadcrumbs>
                    <LinkNavegacao to='/'><HomeIcon fontSize='small' /></LinkNavegacao>
                    <LinkNavegacao to='/epis'>Gestão de EPI's</LinkNavegacao>
                    <LinkNavegacao to='/epis/relatorios'>Relatórios</LinkNavegacao>
                    <Typography variant="span">Relatório de abastecimento x retiradas</Typography>
                </Breadcrumbs>
                <PageTitle icon={<ProductionQuantityLimits fontSize='large'/>} title='Relatório de Abastecimentos x Retiradas'/>
            </Grid>

            {/** Filtros **/}
            <Grid container item xs={12} spacing={3} component="form" onSubmit={handleSubmit(onSubmit)}>
                <Grid container item xs={12} md={12} spacing={3}>
                    <Grid item xs={12} md={4} lg={4}>
                        <SelectEmpresaFilter
                            empresas={empresas}
                            id_empresas={empresas}
                            defaultValue={selectedEmpresaId}
                            onChangeValue={(value) => {
                                setIsSearched(false);
                                setSelectedEmpresaId(value);
                                setEmpresaIdSession(value);
                                setValue('id_empresas', value, { shouldDirty: true });
                                setValue('id_equipamento', '', { shouldDirty: true });
                                setValue('id_produto', '', { shouldDirty: true });
                            }}
                        />
                    </Grid>

                    <Grid item xs={12} md={4} lg={4}>
                        <TooltipSelectDisabled isDisabled={selectedEmpresaId}>
                            <Controller
                                name='id_equipamento'
                                control={control}
                                rules={{ required: true }}
                                defaultValue={''}
                                render={({ field: { ref, onChange, ...field } }) => (
                                    <TextField
                                        {...field}
                                        select
                                        fullWidth
                                        label="Equipamento"
                                        disabled={!selectedEmpresaId}
                                        onChange={(e) => {
                                            setIsSearched(false);
                                            setValue('id_equipamento', e.target.value, { shouldDirty: true });
                                            setSelectedEquipamentoId(e.target.value);
                                            carregaProdutos(e.target.value);

                                            if (e.target.value) {
                                                clearErrors("id_equipamento");
                                            }
                                        }}
                                        error={!!errors.id_equipamento}
                                        helperText={"Selecione o equipamento que deseja filtrar"}
                                        size='small'
                                        InputProps={{ style: { backgroundColor: '#fff' } }}
                                    >
                                        {equipamentos.length > 0 ? (
                                            [
                                                ...equipamentos.filter(option => option.status !== 0).map(option => (
                                                    <MenuItem key={option.id} value={option.id}>
                                                        {option.nome} - ({option.tipo === 'maquina' ? 'Máquina' : 'Balcão'})
                                                    </MenuItem>
                                                )),
                                                ...equipamentos.filter(option => option.status === 0).map(option => (
                                                    <MenuItem key={option.id} value={option.id} disabled>
                                                        {option.nome} - ({option.tipo === 'maquina' ? 'Máquina' : 'Balcão'}) - Inativo
                                                    </MenuItem>
                                                ))
                                            ]
                                        ) : (
                                            <MenuItem disabled>Nenhum disponível</MenuItem>
                                        )}
                                    </TextField>
                                )}
                            />
                        </TooltipSelectDisabled>
                    </Grid>

                    <Grid item xs={12} md={4} lg={4}>
                        <TooltipSelectDisabled
                            isDisabled={selectedEquipamentoId}
                            text="Selecione um equipamento para habilitar este campo"
                        >
                            <Controller
                                name='id_produto'
                                control={control}
                                rules={{ required: false }}
                                defaultValue={''}
                                render={({ field: { ref, onChange, ...field } }) => (
                                    <TextField
                                        {...field}
                                        select
                                        fullWidth
                                        label="Produto"
                                        disabled={!selectedEquipamentoId}
                                        onChange={(e) => {
                                            setIsSearched(false);
                                            setValue('id_produto', e.target.value, { shouldDirty: true })
                                            if (e.target.value) {
                                                clearErrors("id_produto");
                                            }
                                        }}
                                        error={!!errors.id_equipamento}
                                        helperText={getFormErrorMessage(errors, 'id_produto')}
                                        size='small'
                                        InputProps={{ style: { backgroundColor: '#fff' } }}
                                    >
                                        {produtos.length > 0 ? (
                                            produtos.map(produto => (
                                                <MenuItem
                                                    key={produto.id}
                                                    value={produto.id}
                                                    disabled={produto.status === 0}
                                                >
                                                    {produto.nome} {produto.ca ?  "- " + produto.ca : ''} {produto.status === 0 ? " - Inativo" : ""}
                                                </MenuItem>
                                            ))
                                        ) : (
                                            <MenuItem disabled>Nenhum disponível</MenuItem>
                                        )}
                                    </TextField>
                                )}
                            />
                        </TooltipSelectDisabled>
                    </Grid>

                    <Grid item xs={12} md={4}>
                        <DateRangePicker
                            value={dateRange}
                            bgColor='#fff'
                            onChange={(value) => {
                                setDateRange(value);
                                const [start, end] = value;
                                setValue('data_inicio', start ? dayjs(start).format('YYYY-MM-DD') : '');
                                setValue('data_fim', end ? dayjs(end).format('YYYY-MM-DD') : '');
                                setIsSearched(false);
                            }}
                            onReset={() => {
                                setDateRange([null, null]);
                                setValue('data_inicio', '');
                                setValue('data_fim', '');
                                setIsSearched(false);
                            }}
                            hasError={errors.data_inicio || errors.data_fim}
                        />
                    </Grid>

                    <Grid item xs={12} md={1}>
                        <Button
                            type='submit'
                            color='primary'
                            variant='contained'
                            disabled={
                                isLoading ||
                                Object.keys(errors).length > 0 ||
                                !verifyFieldsAreFilled([getValues('id_empresas'), getValues('id_equipamento')])
                            }
                            startIcon={isLoading ? <CircularProgress size={16} sx={{ color: "textSecondary" }} /> : <FilterAlt />}
                        >
                            Filtrar
                        </Button>
                    </Grid>
                </Grid>
            </Grid>

            {/** Tabela **/}
            <Grid item xs={12}>
                <Box sx={{
                    height: movimentacoes.length > 0 ? "auto" : 400,
                    minHeight: 400,
                    width: '100%',
                    overflowX: "auto",
                    '& .MuiDataGrid-columnHeaders, .MuiDataGrid-toolbarContainer': {
                        backgroundColor: '#d8d8d8',
                    },
                    '& .MuiDataGrid-columnHeaders': {
                        borderRadius: 0
                    },
                }}>
                    <DataGrid
                        getRowId={(row) => row.id}
                        rows={dados.linhas}
                        columns={dados.colunas}
                        loading={isLoading}
                        pageSizeOptions={[10, 20, 50, 100]}
                        // rowCount={numTotalItems}
                        paginationMode="client"
                        initialState={{
                            pagination: {
                                paginationModel: { pageSize: 20, page: 0 },
                            },
                        }}
                        sx={{
                            backgroundColor: '#fff',
                            '.MuiDataGrid-columnHeaderTitle': {
                                fontWeight: 'bold !important',
                                overflow: 'visible !important',
                            },
                            '& .MuiDataGrid-virtualScroller': {
                                overflowX: 'auto !important',
                            },
                            '& .MuiDataGrid-virtualScroller::-webkit-scrollbar-thumb': {
                                backgroundColor: grey[600],
                                borderRadius: '10px',
                            },
                            '& .MuiDataGrid-virtualScroller::-webkit-scrollbar-thumb:hover': {
                                backgroundColor: grey[700],
                            }
                        }}
                        disableRowSelectionOnClick
                        localeText={ptBR.components.MuiDataGrid.defaultProps.localeText}
                        slots={{
                            toolbar: (props) =>
                                <CustomToolbarReports
                                    reportName="relatorio_abastecimento_retiradas"
                                    pdfExport={exportDataToPDF}
                                    props={dados.linhas}
                                    customData={dados.linhas}
                                    customizedCSVExport={true}
                                />
                            ,
                            loadingOverlay: LinearProgress,
                            noRowsOverlay: () => (
                                <Box style={{ display: "flex", width: '100%', textAlign: 'center', height: "100%", alignItems: 'center', justifyContent: 'center' }}>
                                    {isSearched ?
                                        <Typography variant='h6' color='textSecondary'>Nenhum registro encontrado para o filtro selecionado</Typography>
                                        :
                                        <Typography variant='h6' color='textSecondary'>Selecione um equipamento e um produto para filtrar</Typography>
                                    }
                                </Box>
                            )
                        }}
                    />
                </Box>
            </Grid>

            <AbastecimentosDetailModal open={modalOpen} onClose={() => setModalOpen(false)} movimentacoesDetails={movimentacoesDetails} />
        </Grid>
    )
}

{/* Função para montar as colunas e linhas do dataGrid */}
const tableRows = (movimentacoes, setModalOpen, exibirAlerta, setMovimentacoesDetails) => {
    const mainColumns = [
        { field: 'produto', headerName: 'Produto', flex: 2, minWidth: 400 },
        { field: 'quantidade', headerName: 'Quantidade abastecida', flex: 1, minWidth: 200 },
        { field: 'data', headerName: 'Data do abastecimento', flex: 1, minWidth: 300 },
        {
            field: 'qtdRetirada',
            headerName: 'Quantidade retirada',
            flex: 1,
            minWidth: 200,
            renderCell: (params) => {
                const retiradas = params.row.retiradas || [];
                return retiradas.reduce((sum, retirada) => sum + (retirada.qtd || 0), 0);
            }
        },
        {
            field: "retiradas",
            headerName: "Retiradas",
            sortable: false,
            renderCell: (params) => {
                const onClick = async (e) => {
                    e.stopPropagation();
                    try {
                        setMovimentacoesDetails(params.row.retiradas);
                        setModalOpen(true);
                    } catch (error) {
                        exibirAlerta('Erro', 'Não foi possível obter os detalhes das retiradas', 'error');
                    }
                };

                return (
                    <Button onClick={onClick} size='small' color="primary" variant='contained' sx={{ minWidth: 30 }}>
                        <RemoveRedEye fontSize='small' />
                    </Button>
                )
            }
        }
    ];

    const transformedData = [];
    if (movimentacoes && Array.isArray(movimentacoes.data)) {
        movimentacoes.data.forEach((item) => {
            Object.keys(item.data).forEach((date) => {
                const data = item.data[date];

                transformedData.push({
                    id: data.id_movimentacao,
                    produto: item.produto,
                    quantidade: data.qtd_abastecida || 0,
                    data: date,
                    retiradas: data.retiradas || [],
                    isParent: true,
                });
            });
        });
    }

    return {
        colunas: mainColumns,
        linhas: transformedData
    };
}
