import { useState, useEffect } from 'react';
import { useQuery } from '@tanstack/react-query';
import { Controller, useForm } from "react-hook-form";
import {
  Button,
  Card,
  CardContent,
  CircularProgress,
  FormControl,
  FormControlLabel,
  FormLabel,
  Grid,
  List,
  MenuItem,
  Radio,
  RadioGroup,
  Stack,
  TextField,
  Typography
} from '@mui/material';
import { green, grey, yellow } from '@mui/material/colors';
import { useCommonItems } from '../../../../contexts/CommonItensProvider';
import { getFormErrorMessage } from '../../../../configs/functions';
import { RelatoriosExcelImport } from '../configs/functions';
import { renderValue } from '../../epis/alerts/utils/formatDetailsAlert';
import { listaEmpresas } from '../../management/companies/configs/functions';

export function SpreadSheetImportStep({ file, activeStep, steps, isLoading, setIsLoading, createdIdEmpresa, setCreatedIdEmpresa }) {
  const { exibirDialog, handleCloseDialog, exibirAlerta } = useCommonItems();

  const [insertedFileUpload, setInsertedFileUpload] = useState([]);
  const [duplicatesFileUpload, setDuplicatesFileUpload] = useState([]);
  const [errorsFileUpload, setErrorsFileUpload] = useState([]);
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [companyAlreadyRegistered, setCompanyAlreadyRegistered] = useState(null);

  const {
    reset,
    register,
    handleSubmit,
    setValue,
    getValues,
    setError,
    clearErrors,
    control,
    formState: { errors },
  } = useForm();

  const queryKey = ['listaEmpresas'];
  const { data: queryEmpresasData, isLoading: isLoadingQuery, isPending } = useQuery({
    queryKey: queryKey,
    queryFn: () => listaEmpresas(),
    refetchOnWindowFocus: false,
  });

  const empresas = queryEmpresasData?.data?.data || [];
  const isLoadingEmpresas = isLoadingQuery || isPending;

  const onSubmit = async (data) => {
    const formData = new FormData();

    for (const key in data) {
      if (data[key] === '') continue;

      if (Array.isArray(data[key])) {
        data[key].forEach((item, index) => {
          formData.append(`${key}[${index}]`, item);
        });
      } else {
        formData.append(key, data[key]);
      }
    }

    try {
      setIsLoading(true);
      exibirDialog('Enviando. Aguarde...');
      const response = await RelatoriosExcelImport(data);
      const { erros, duplicados, inseridos } = response.data;

      if (activeStep === 0) {
        const empresa = response.data.inseridos.Empresa[0];
        const match = empresa.match(/(\d+)$/);
        const idEmpresa = match ? match[1] : null;
        setCreatedIdEmpresa(idEmpresa);
      }

      const currentModel = steps[activeStep];
      setErrorsFileUpload(prev => ({ ...prev, [activeStep]: erros[currentModel] || [] }));
      setDuplicatesFileUpload(prev => ({ ...prev, [activeStep]: duplicados[currentModel] || [] }));
      setInsertedFileUpload(prev => ({ ...prev, [activeStep]: inseridos[currentModel] || [] }));

      if (erros[currentModel]?.length > 0) {
        exibirAlerta('Erro', `Itens com Erro na aba ${currentModel}`, 'error');
      } else if (duplicados[currentModel]?.length > 0) {
        exibirAlerta('Duplicados', `Itens Duplicados na aba ${currentModel}`, 'warning');
      } else {
        exibirAlerta('Sucesso', `Aba ${currentModel} enviada com sucesso.`, 'success');
        setIsSubmitted(true);
      }
    } catch (error) {
      console.log(error);
      const errorMessage = error.response?.data?.error || error.response?.data?.message || error.response?.data?.erro || "Erro ao enviar Planilha.";
      exibirAlerta('Erro', errorMessage, 'error');
    } finally {
      setIsLoading(false);
      handleCloseDialog();
    }
  };

  function handleSelectEmpresa() {
    const idEmpresa = getValues('id_empresa');
    if (idEmpresa) {
      setCreatedIdEmpresa(idEmpresa);
      setIsSubmitted(true);
      exibirAlerta('Empresa selecionada com sucesso', 'Agora você pode prosseguir para a próxima aba.', 'success');
    } else {
      setError('id_empresa', { message: 'Selecione uma Empresa' });
    }
  }

  useEffect(() => {
    const defaultValues = {
      excel: file,
      model: steps[activeStep] || '',
      id_empresa: createdIdEmpresa || null,
    };
    reset(defaultValues);
  }, [activeStep, createdIdEmpresa, file, reset, steps]);

  useEffect(() => {
    setIsSubmitted(false);
  }, [activeStep]);

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Grid container spacing={2}>
        {activeStep === 0 && (
          <Grid item xs={12} md={12}>
            <FormControl>
              <FormLabel id="row-radio-buttons-group-label">Cadastro da Empresa já foi realizado?</FormLabel>
              <RadioGroup
                row
                aria-labelledby="row-radio-buttons-group-label"
                name="row-radio-buttons-group"
                defaultValue={getValues('id_empresa') ? 'true' : 'false'}
                onChange={(e) => {
                  const isCompanyRegistered = e.target.value === 'true';
                  setCompanyAlreadyRegistered(isCompanyRegistered);
                  setValue('id_empresa', null, { shouldDirty: true });
                  setIsSubmitted(false);

                  if (!isCompanyRegistered) {
                    clearErrors('id_empresa');
                    setValue('id_empresa', null, { shouldDirty: true });
                  }
                }}
              >
                <FormControlLabel value="true" control={<Radio />} label="Sim" />
                <FormControlLabel value="false" control={<Radio />} label="Não" />
              </RadioGroup>
            </FormControl>
          </Grid>
        )}

        <Grid item xs={12} md={4}>
          <TextField
            InputLabelProps={{ shrink: true }}
            margin='dense'
            label='Model'
            placeholder='Model'
            disabled
            fullWidth
            {...register("model", { required: true })}
            error={!!errors.model}
            helperText={getFormErrorMessage(errors, 'model')}
          />
        </Grid>

        {(activeStep === 0 && companyAlreadyRegistered) && (
          <Grid item xs={12} md={4}>
            <Controller
              name='id_empresa'
              control={control}
              rules={{ required: true }}
              defaultValue=""
              render={({ field: { ref, onChange, ...field } }) => (
                <TextField
                  {...field}
                  select
                  fullWidth
                  sx={{ mt: 1 }}
                  label="Empresa"
                  onChange={(e) => {
                    if (e.target.value) {
                      clearErrors('id_empresa');
                    }
                    setValue('id_empresa', e.target.value, { shouldDirty: true })
                  }}
                  error={!!errors.id_empresa}
                  helperText={getFormErrorMessage(errors, 'id_empresa')}
                >
                  {empresas && empresas.length > 0 ? (
                    empresas.map((option) => (
                      <MenuItem key={option.id} value={option.id}>
                        {option.nomeFantasia + ' - ' + option.id}
                      </MenuItem>
                    ))
                  ) :
                    isLoadingEmpresas
                      ? <MenuItem disabled>Carregando...</MenuItem>
                      : <MenuItem disabled>Nenhum disponível</MenuItem>
                  }
                </TextField>
              )}
            />
          </Grid>
        )}

        {(activeStep === 0 && companyAlreadyRegistered) ? (
          <Grid item xs={12} md={4}>
            <Button
              variant='contained'
              color='primary'
              sx={{ marginTop: 2 }}
              onClick={handleSelectEmpresa}
              disabled={isSubmitted}
            >
              Confirmar
            </Button>
          </Grid>
        ) : (
          <Grid item xs={12} md={4}>
            <Button
              type='submit'
              variant='contained'
              color='primary'
              sx={{ marginTop: 2 }}
              disabled={isLoading || isSubmitted}
              startIcon={isLoading && <CircularProgress size={16} sx={{ color: grey[400] }} />}
            >
              Cadastrar Dados dessa aba
            </Button>
          </Grid>
        )}

        <Grid container spacing={2} item xs={12} md={12}>
          <Grid item xs={12} md={4}>
            <ResultsFileUploadCard
              title="Inseridos"
              items={insertedFileUpload[activeStep]}
              color={green[500]}
            />
          </Grid>

          <Grid item xs={12} md={4}>
            <ResultsFileUploadCard
              title="Duplicados"
              items={duplicatesFileUpload[activeStep]}
              color={yellow[700]}
            />
          </Grid>

          <Grid item xs={12} md={4}>
            <ResultsFileUploadCard
              title="Erros"
              items={errorsFileUpload[activeStep]}
              color="error"
            />
          </Grid>
        </Grid>
      </Grid>
    </form>
  );
}

function ResultsFileUploadCard({ title, items, color, emptyMessage = 'Nenhum item encontrado' }) {
  return (
    <Card sx={{ marginTop: 1 }}>
      <CardContent>
        <Stack direction='row' spacing={1} sx={{ alignItems: 'center', justifyContent: 'space-between' }} >
          <Typography
            variant='h3'
            color={items?.length > 0 ? color : 'textSecondary'}
          >
            {title}
          </Typography>

          <Typography color='textSecondary'>
            <Typography sx={{ fontWeight: items?.length > 0 ? 'bold' : 'normal', display: 'inline' }}>
              {items ? items?.length : 0}
            </Typography> {items?.length === 1 ? 'item' : 'itens'}
          </Typography>
        </Stack>

        <List dense sx={{ listStyleType: 'circle', paddingTop: 1, paddingBottom: 0.5, paddingLeft: 1 }}>
          {items?.length > 0 ? (
            items.map((item, index) => (
              <Typography variant='body2' key={index} sx={{ marginBottom: 0.5 }}>
                {renderValue(item)}
              </Typography>
            ))
          ) : (
            <Typography variant='body2' color='textSecondary'>
              {emptyMessage}
            </Typography>
          )}
        </List>
      </CardContent>
    </Card>
  );
}
