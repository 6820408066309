import { useState } from 'react';
import { Controller } from 'react-hook-form';
import { useQuery } from '@tanstack/react-query';
import {
  Autocomplete,
  CircularProgress,
  Divider,
  FormControl,
  Grid,
  MenuItem,
  TextField,
  Typography
} from '@mui/material';
import { getCamposTabela } from '../../configs/functions';
import { getFormErrorMessage } from '../../../../../../configs/functions';
import {ClearIcon} from "@mui/x-date-pickers";

export function RegisterTypeConfigStep({ control, getValues, errors }) {
  const [selectedTipoRegistro, setSelectedTipoRegistro] = useState('retirada');
  const [selectedFields, setSelectedFields] = useState(getValues('campos') || []);

  const { data: queryCamposTabela, isLoading, isPending } = useQuery({
    queryKey: ['getCamposTabela', selectedTipoRegistro],
    queryFn: async () => {
      const response = await getCamposTabela({ tipo_registro: selectedTipoRegistro })
      const campos = response?.data?.campos || {};
      return Object.values(campos);
    },
    enabled: !!selectedTipoRegistro,
    refetchOnWindowFocus: false,
  });

  const availableFields = queryCamposTabela || [];
  const isLoadingFields = isLoading || isPending;

  return (
    <Grid
      container
      direction="row"
      justifyContent="flex-start"
      alignItems="flex-start"
      spacing={2}
    >
      <Grid item xs={12} md={4}>
        <Controller
          name="tipo_registro"
          control={control}
          render={({ field }) => (
            <TextField
              select
              margin='dense'
              label='Tipo de Registro'
              placeholder='Selecione o Tipo de Registro'
              fullWidth
              {...field}
              error={!!errors.tipo_registro}
              helperText={getFormErrorMessage(errors, 'tipo_registro')}
              variant="outlined"
              onChange={(e) => {
                field.onChange(e)
                setSelectedTipoRegistro(e.target.value)
              }}
            >
              <MenuItem value={'retirada'}>
                Retirada
              </MenuItem>
            </TextField>
          )}
        />
      </Grid>

      <Grid item xs={12}>
        <Typography variant="h6">
          Campos que serão exportados
        </Typography>
        <Divider />
      </Grid>

      <Grid item xs={12}>
        <FormControl fullWidth sx={{ mt: 1 }}>
          <Controller
            name="campos"
            control={control}
            defaultValue={selectedFields}
            rules={{
              validate: value => {
                if (value.length === 0) {
                  return "Selecione ao menos um campo";
                }
                return true;
              }
            }}
            render={({ field }) => (
              <Autocomplete
                {...field}
                multiple
                options={['Todos', ...availableFields]}
                getOptionLabel={(option) => option}
                value={selectedFields}
                onChange={(event, newValue) => {
                  if (newValue.includes('Todos')) {
                    setSelectedFields(availableFields);
                    field.onChange(availableFields);
                  } else {
                    setSelectedFields(newValue);
                    field.onChange(newValue);
                  }
                }}
                disableClearable
                noOptionsText="Nenhum campo encontrado"
                renderOption={(props, option) => (
                    <li {...props} style={{ fontWeight: option === 'Todos' ? 'bold' : 'normal' }}>
                        {option}
                    </li>
                )}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Campos"
                    placeholder={
                      selectedFields.length === availableFields.length
                        ? ''
                        : 'Selecione os campos'
                    }
                    helperText={getFormErrorMessage(errors, 'campos')}
                    error={!!errors.campos}
                    InputProps={{
                      ...params.InputProps,
                      endAdornment: (
                        <>
                            {isLoadingFields ? (
                                <CircularProgress color="inherit" size={20} />
                            ) : selectedFields.length > 0 ? (
                                <ClearIcon
                                    onClick={() => {
                                        setSelectedFields([]);
                                        field.onChange([]);
                                    }}
                                    style={{ cursor: 'pointer', marginRight: 8 }}
                                />
                            ) : null}
                            {params.InputProps.endAdornment}
                        </>
                      ),
                    }}
                  />
                )}
                sx={{
                  // prevent the input break to a new line on hover
                  '& .MuiOutlinedInput-root': {
                    '& .MuiOutlinedInput-input': {
                      boxSizing: selectedFields.length > 0 ? 'border-box' : 'content-box',
                    },
                  },
                }}
              />
            )}
          />
        </FormControl>
      </Grid>
    </Grid>
  )
}