import React, {useState, useEffect, useMemo} from 'react';
import { Helmet } from 'react-helmet-async';
import { Controller, useForm } from 'react-hook-form';
import {
    Grid,
    Breadcrumbs,
    Typography,
    Button,
    TextField, CircularProgress, Box, Tooltip, Badge, LinearProgress, Icon,
} from '@mui/material';
import {Add, ArrowOutward, Cancel, CheckCircle, FilterAlt, Home, Print, RemoveRedEye} from '@mui/icons-material';
import PageTitle from '../../common/PageTitle';
import LinkNavegacao from '../../common/Link';
import { useCommonItems } from '../../../contexts/CommonItensProvider';
import {
    ErrorIcon, EyeIcon, InfoIcon,

} from '../../common/Icons';
import {getRequestErrorsData, listLogs, listRequestErrors} from './configs/functions';
import {TooltipSelectDisabled} from "../../common/TooltipSelectDisabled";
import {listaEmpresas} from "../management/companies/configs/functions";
import {useAppContext} from "../../../contexts/AppContext";
import {SelectEmpresaFilter} from "../../common/SelectEmpresaFilter";
import dayjs from "dayjs";
import {checkPermissionsAndRedirect, getFormErrorMessage } from "../../../configs/functions";
import MenuItem from "@mui/material/MenuItem";
import DateRangePicker from "../../common/DateRangePicker";
import {DataGrid, ptBR} from "@mui/x-data-grid";
import {green, grey} from "@mui/material/colors";
import {carregaEquipamentos} from "../epis/equipments/configs/functions";
import {RequestErrorDetailModal} from "./components/RequestErrorDetailModal";
import {formatDateTime, parseDateTime} from "../../../utils/format-date";

export function RequestErrorsPanel() {
    const { exibirAlerta, exibirDialog, handleCloseDialog } = useCommonItems();
    const { getEmpresaIdSession, setEmpresaIdSession } = useAppContext();

    // estados para controle de paginacao
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(20);
    const [numTotalItems, setNumTotalItems] = useState(0);

    // periodo de busca
    const initialDataInicio = dayjs().subtract(1, 'month');
    const initialDataFim = dayjs();
    const [dateRange, setDateRange] = useState([initialDataInicio, initialDataFim]);

    const [openForm, setOpenForm] = useState(false);

    const [requestErrors, setRequestErrors] = useState({});
    const [empresas, setEmpresas] = useState([]);
    const [equipamentos, setEquipamentos] = useState([]);

    const [selectedEmpresaId, setSelectedEmpresaId] = useState(null);

    const [isLoading, setIsLoading] = useState(false);
    const [isSearched, setIsSearched] = useState(false);

    const [modalOpen, setModalOpen] = useState(false);
    const [requestErrorsDetails, setRequestErrorsDetails] = useState(null);

    const {
        handleSubmit,
        setValue,
        getValues,
        clearErrors,
        reset,
        control,
        formState: { errors },
    } = useForm();

    const columns = useMemo(() => [
        {
            field: 'nomeEquipamento',
            headerName: 'Nome equipamento',
            flex: 1,
            valueGetter: (params) => params.row.equipamento?.nome
        },
        {
            field: 'tagIdentificacaoEquipamento',
            headerName: 'Tag Equipamento',
            flex: 1,
            valueGetter: (params) => params.row.equipamento?.tagIdentificacao
        },
        {
            field: 'tipo_equipamento',
            headerName: 'Tipo Equipamento',
            flex: 1,
        },
        { field: 'erro', headerName: 'Erro', flex: 1 },
        { field: 'status', headerName: 'Código de retorno', flex: 1 },
        { field: 'rota', headerName: 'Rota', flex: 1 },
        {
            field: 'dataRequest',
            headerName: 'Data',
            flex: 1,
            type: 'dateTime',
            valueGetter: (params) => parseDateTime(params.row?.createdAt),
            renderCell: (params) => formatDateTime(params.row?.createdAt, "DD/MM/YYYY HH:mm:ss"),
        },
        {
            field: "request",
            headerName: "Requisição",
            sortable: false,
            renderCell: (params) => {
                const onClick = async (e) => {
                    e.stopPropagation();
                    try {
                        const data = await getRequestErrorsData(params.row.id);
                        setRequestErrorsDetails(data.data.request);
                        setModalOpen(true);
                    } catch (error) {
                        exibirAlerta('Erro', 'Não foi possível obter os detalhes da requisição', 'error');
                    }
                };

                return (
                    <Button onClick={onClick} size='small' color="primary" variant='contained' sx={{minWidth: 30}}>
                        <RemoveRedEye fontSize='small'/>
                    </Button>
                )
            }
        }
    ]);

    //funções
    async function loadRequestErrorsData(id_empresas, id_equipamento, status, data_inicio, data_fim) {
        try {
            setIsLoading(true);

            data_inicio = dayjs(data_inicio).format('YYYY-MM-DD');
            data_fim = dayjs(data_fim).format('YYYY-MM-DD');

            const filter = {
                data_inicio,
                data_fim
            }

            if (id_empresas === 'all') {
                filter.empresa = empresas.map((empresa) => empresa.id);
            } else {
                filter.id_empresa = [id_empresas];
            }

            if (id_equipamento) filter.id_equipamento = id_equipamento;

            const res = await listRequestErrors(filter);
            setRequestErrors(res.data.data);
            setNumTotalItems(res.data.data);
        } catch (error) {
            exibirAlerta('Ops', 'Ocorreu um erro ao carregar os erros', 'error');
        } finally {
            setIsLoading(false);
        }
    }

    const onSubmit = async (data) => {
        try {
            setIsSearched(true);
            setPage(0);
            loadRequestErrorsData(data.id_empresa, data.id_equipamento, data.status, data.data_inicio, data.data_fim);
        } catch (error) {
            exibirAlerta('Ops', 'Erro ao carregar requisições', 'error');
            setIsSearched(false);
        }
    };

    const listaEquipamentos = async (empresaId) => {
        try {
            exibirDialog('Carregando Equipamentos...');

            const filterAllMachines = { id_empresas: Array.isArray(empresaId) ? empresaId.empresa : [] };
            const response = await carregaEquipamentos(filterAllMachines);
            setEquipamentos(response.data.data);
        } catch (error) {
            exibirAlerta("Erro ao carregar equipamentos", '', 'error');
        } finally {
            handleCloseDialog();
        }
    }

    function getDataEmpresa(empresaId) {
        listaEquipamentos(empresaId);
    }

    useEffect(() => {
        const requiredPermissionsView = ["admin", "admin_log", "list_log"];
        checkPermissionsAndRedirect(requiredPermissionsView);

        let defaultEmpresaId = null;
        const selectedEmpresaIdSession = getEmpresaIdSession();

        if (selectedEmpresaIdSession) {
            defaultEmpresaId = selectedEmpresaIdSession;
            setSelectedEmpresaId(selectedEmpresaId);
        }

        listaEmpresas()
            .then((response => {
                exibirDialog('Carregando dados da Empresa...');

                const empresasData = response?.data.data;
                setEmpresas(empresasData);

                if (!selectedEmpresaIdSession) {
                    defaultEmpresaId = empresasData[0].id;
                    setSelectedEmpresaId(empresasData[0].id);
                    setEmpresaIdSession(empresasData[0].id);
                }

                const defaultValues = {
                    id_empresas: defaultEmpresaId,
                    id_equipamento: '',
                    status: '',
                    data_inicio: initialDataInicio,
                    data_fim: initialDataFim
                };
                reset(defaultValues);

                loadRequestErrorsData(defaultEmpresaId, null, null, defaultValues.data_inicio, defaultValues.data_fim);
            }))
            .catch((error) => {
                console.error(error);
            })
            .finally(() => {
                handleCloseDialog();
            });

        return () => {
            reset();
        }
    }, []);

    useEffect(() => {
        if (selectedEmpresaId) {
            getDataEmpresa(selectedEmpresaId);
        }
    }, [selectedEmpresaId]);

    useEffect(() => {
        if (!openForm) {
            const id_empresas = getValues('id_empresas');
            const id_equipamento = getValues('id_equipamento');
            const status = getValues('status');
            const data_inicio = getValues('data_inicio')
            const data_fim = getValues('data_fim')

            if (!id_empresas || !data_inicio || !data_fim) {
                return;
            }

            loadRequestErrorsData(id_empresas, id_equipamento, status, data_inicio, data_fim);
        }
    }, [page, rowsPerPage, openForm]);

    return (
        <>
            <Helmet title="Erros de requisição"/>

            <Grid container="row" justifyContent="flex-start" alignItems="stretch" spacing={2}>
                <Grid item xs={12}>
                    <Breadcrumbs>
                        <LinkNavegacao to='/'><Home fontSize='small'/></LinkNavegacao>
                        <Typography variant='span'>Gerenciamento admin</Typography>
                        <Typography variant='span'>Erros de requisição</Typography>
                    </Breadcrumbs>

                    <PageTitle icon={<ErrorIcon fontSize='large'/>} title='Erros de requisição'/>

                    <Typography p pb={4}>
                        Acompanhe e monitore erros de requisição decorrentes dos equipamentos
                    </Typography>
                </Grid>
            </Grid>

            <Grid container item xs={12} spacing={3} component="form" onSubmit={handleSubmit(onSubmit)}>
                <Grid container item xs={12} md={12} spacing={3}>
                    <Grid item xs={12} md={4}>
                        <SelectEmpresaFilter
                            empresas={empresas}
                            id_empresas={empresas}
                            defaultValue={selectedEmpresaId}
                            enableOptionAll={true}
                            onChangeValue={(value) => {
                                setIsSearched(false);
                                setValue('id_empresas', value, { shouldDirty: true });
                                setValue('id_equipamento', value, { shouldDirty: true });

                                if (value !== 'all') {
                                    setSelectedEmpresaId(value);
                                    setEmpresaIdSession(value);
                                } else {
                                    setSelectedEmpresaId(null);
                                }
                            }}

                            textHelper={false}
                        />
                    </Grid>

                    <Grid item xs={12} md={4}>
                        <TooltipSelectDisabled isDisabled={selectedEmpresaId}>
                            <Controller
                                name='id_equipamento'
                                control={control}
                                rules={{ required: false }}
                                defaultValue={''}
                                render={({ field: { ref, onChange, ...field } }) => (
                                    <TextField
                                        {...field}
                                        select
                                        fullWidth
                                        label="Equipamento"
                                        disabled={!selectedEmpresaId}
                                        onChange={(e) => {
                                            setIsSearched(false);
                                            setValue('id_equipamento', e.target.value, { shouldDirty: true })
                                            if (e.target.value) {
                                                clearErrors("id_equipamento");
                                            }
                                        }}
                                        error={!!errors.id_equipamento}
                                        helperText={getFormErrorMessage(errors, 'id_equipamento')}
                                        size='small'
                                        InputProps={{ style: { backgroundColor: '#fff' } }}
                                    >
                                        <MenuItem value=''>Todos</MenuItem>
                                        {equipamentos.length > 0 ? (
                                            [
                                                ...equipamentos.filter(option => option.status !== 0).map(option => (
                                                    <MenuItem key={option.id} value={option.id}>
                                                        {option.nome} - ({option.tipo === 'maquina' ? 'Máquina' : 'Balcão'})
                                                    </MenuItem>
                                                )),
                                                ...equipamentos.filter(option => option.status === 0).map(option => (
                                                    <MenuItem key={option.id} value={option.id} disabled>
                                                        {option.nome} - ({option.tipo === 'maquina' ? 'Máquina' : 'Balcão'}) - Inativo
                                                    </MenuItem>
                                                ))
                                            ]
                                        ) : (
                                            <MenuItem disabled>Nenhum disponível</MenuItem>
                                        )}
                                    </TextField>
                                )}
                            >

                            </Controller>
                        </TooltipSelectDisabled>
                    </Grid>

                    <Grid item xs={12} md={4}>
                        <Controller
                            name="status"
                            control={control}
                            defaultValue=""
                            render={({ field }) => (
                                <TextField
                                    {...field}
                                    select
                                    fullWidth
                                    label="Status"
                                    size="small"
                                    onChange={(event) => {
                                        setIsSearched(false);
                                        field.onChange(event);
                                    }}
                                    inputProps={{ style: { backgroundColor: '#fff' } }}
                                >
                                    <MenuItem value="">Todos</MenuItem>
                                    <MenuItem value="400">400 - Bad request</MenuItem>
                                    <MenuItem value="500">500 - Erro de servidor</MenuItem>
                                    <MenuItem value="404">404 - Não encontrado</MenuItem>
                                </TextField>
                            )}
                        />
                    </Grid>

                    <Grid item xs={12} md={4}>
                        <DateRangePicker
                            value={dateRange}
                            bgColor='#fff'
                            onChange={(value) => {
                                setDateRange(value);
                                const [start, end] = value;
                                setValue('data_inicio', start ? dayjs(start).format('YYYY-MM-DD') : '');
                                setValue('data_fim', end ? dayjs(end).format('YYYY-MM-DD') : '');
                                setIsSearched(false);
                            }}
                            onReset={() => {
                                setIsSearched(false);
                                setDateRange([null, null]);
                                setValue('data_inicio', '');
                                setValue('data_fim', '');
                            }}
                            hasError={errors.data_inicio || errors.data_fim}
                        />
                    </Grid>

                    {/*  Botão de filtragem  */}
                    <Grid item xs={12} md={2}>
                        <Button
                            type='submit'
                            color='primary'
                            variant='contained'
                            disabled={isLoading || Object.keys(errors).length > 0 || isSearched}
                            startIcon={isLoading ? <CircularProgress size={16} sx={{ color: "textSecondary" }} /> : <FilterAlt />}
                        >
                            Filtrar
                        </Button>
                    </Grid>

                    {/*  Tabela  */}
                    <Grid item xs={12}>
                        <Box sx={{
                            height: requestErrors.length > 0 ? "auto" : 400,
                            minHeight: 400,
                            width: '100%',
                            overflow: "auto",
                            '& .MuiDataGrid-columnHeaders, .MuiDataGrid-toolbarContainer' : {
                                backgroundColor: '#d8d8d8'
                            },
                            '& .MuiDataGrid-columnHeaders' : {
                                borderRadius: 0
                            }
                        }}>
                            <DataGrid
                                getRowId={(row) => row.id}
                                rows={requestErrors}
                                columns={columns}
                                loading={isLoading}
                                pageSizeOptions={[10, 20, 50, 100]}
                                initialState={{
                                    pagination: {
                                        paginationModel: { pageSize: 20, page: 0 }
                                    }
                                }}
                                sx={{
                                    backgroundColor: '#fff',
                                    '.MuiDataGrid-columnHeaderTitle': {
                                        fontWeight: 'bold !important',
                                        overflow: 'visible !important',
                                    },
                                    '& .MuiDataGrid-virtualScroller': {
                                        overflowX: 'auto !important',
                                    },
                                    '& .MuiDataGrid-virtualScroller::-webkit-scrollbar-thumb': {
                                        backgroundColor: grey[600],
                                        borderRadius: '10px',
                                    },
                                    '& .MuiDataGrid-virtualScroller::-webkit-scrollbar-thumb:hover': {
                                        backgroundColor: grey[700],
                                    }
                                }}
                                disableRowSelectionOnClick
                                localeText={ptBR.components.MuiDataGrid.defaultProps.localeText}
                                slots={{
                                    loadingOverlay: LinearProgress,
                                    noRowsOverlay: () => (
                                        <Box style={{ display: "flex", width: '100%', textAlign: 'center', height: "100%", alignItems: 'center', justifyContent: 'center' }}>
                                            {isSearched ?
                                                <Typography variant='h6' color='textSecondary'>Nenhum registro encontrado para o filtro selecionado</Typography>
                                                :
                                                <Typography variant='h6' color='textSecondary'>Selecione uma empresa e o período que deseja filtrar</Typography>
                                            }
                                        </Box>
                                    )
                                }}
                            />
                        </Box>
                    </Grid>
                </Grid>

                <RequestErrorDetailModal open={modalOpen} onClose={() => setModalOpen(false)} requestErrorsDetails={requestErrorsDetails} />
            </Grid>
        </>
    )
}