import { useState } from 'react';
import { Box, MenuItem } from '@mui/material';
import {
  GridToolbarColumnsButton,
  GridToolbarContainer,
  GridToolbarDensitySelector,
  GridToolbarExportContainer,
  GridToolbarFilterButton,
  useGridApiContext
} from '@mui/x-data-grid';
import { CsvIcon, PdfIcon, Xls502Icon } from './Icons';
import { exportAbastecimentoRetiradasToCSV, exportToExcel } from '../screens/epis/reports/configs/utils';

{/** Relatórios com formataçãoes de dados específicas **/}
const exportAdapters = {
  "relatorio_abastecimento_retiradas": exportAbastecimentoRetiradasToCSV
};

export const CustomToolbarReports = ({
  reportName,
  pdfExport,
  customizedCSVExport,
  customItem,
  customData,
  showColumnsButton = true,
  showFilterButton = true,
  showDensitySelector = true,
  ...rest }) => {
  const apiRef = useGridApiContext();

  const [isLoading, setIsLoading] = useState(false);

  const handleExportExcel = async () => exportToExcel(reportName, apiRef);
  const handleExportCsv = async (options) => {
    if (customizedCSVExport) {
      const exportFunction = exportAdapters[reportName] || apiRef.current.exportDataAsCsv;
      await exportFunction(customData || options);
    } else {
      apiRef.current.exportDataAsCsv(options);
    }
  };
  const handleExportPdf = async () => {
    try {
      setIsLoading(true);
      const visibleColumns = apiRef.current.getVisibleColumns();
      await pdfExport(visibleColumns, reportName);
    } catch (error) {
      console.log(error);
    } finally {
      setIsLoading(false);
    }
  };

  // Verificar se há dados para controle de exportação
  const hasData = apiRef.current.getRowModels().size > 0;

  return (
    <GridToolbarContainer {...rest} sx={{ justifyContent: 'flex-end', pt: 1 }}>
      {showColumnsButton && <GridToolbarColumnsButton />}

      {showFilterButton && <GridToolbarFilterButton />}

      {showDensitySelector && <GridToolbarDensitySelector />}

      <Box sx={{ flexGrow: 1 }} />

      <GridToolbarExportContainer {...rest}>
        {/* Exportar como Excel */}
        <MenuItem onClick={handleExportExcel} disabled={isLoading || !hasData}>
          <Xls502Icon sx={{ mr: 1 }} /> Exportar como Excel
        </MenuItem>

        {/* Exportar como CSV */}
        <MenuItem onClick={handleExportCsv} disabled={isLoading || !hasData}>
          <CsvIcon sx={{ mr: 1 }} /> Exportar como CSV
        </MenuItem>

        {/* Exportar como PDF */}
        <MenuItem onClick={handleExportPdf} disabled={isLoading || !hasData}>
          <PdfIcon sx={{ mr: 1 }} /> Exportar como PDF
        </MenuItem>

        {customItem}
      </GridToolbarExportContainer>
    </GridToolbarContainer >
  );
};