import { useEffect, useState } from 'react'
import {
  Box,
  Grid,
  FormControlLabel,
  Checkbox,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Typography,
  Divider
} from '@mui/material';
import { Controller } from 'react-hook-form';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { FormControl, FormHelperText } from "@mui/material";


export function AbilitiesCheckboxes({ roles, abilities, pessoa, control, setValue, allowEdit }) {

  const [orphanAbilities, setOrphanAbilities] = useState([]);

  // estados para controlar checkboxes de roles e abilities
  const [parentChecked, setParentChecked] = useState({});
  const [childChecked, setChildChecked] = useState({});

  // Handler for parent checkbox change
  const handleParentChange = (roleId, isChecked) => {
    setParentChecked((prev) => ({
      ...prev,
      [roleId]: isChecked,
    }));

    // If parent checkbox is checked, set all children checkboxes to checked
    setChildChecked((prev) => ({
      ...prev,
      [roleId]: Object.keys(prev[roleId]).reduce((acc, key) => {
        acc[key] = isChecked;
        return acc;
      }, {}),
    }));
  };

  // Handler for individual child checkbox change
  const handleChildChange = (roleId, abilityId, isChecked) => {
    setChildChecked((prev) => ({
      ...prev,
      [roleId]: {
        ...prev[roleId],
        [abilityId]: isChecked,
      },
    }));
  };

  useEffect(() => {
    if (roles.length > 0) {
      const initialChildChecked = roles.reduce((acc, role) => {
        acc[role.id] = role.abilities.reduce((acc, ability) => {
          acc[ability.id] = pessoa?.abilities.includes(ability.id) || false;
          return acc;
        }, {});
        return acc;
      }, {});
      setChildChecked(initialChildChecked);

      // verifica se todas as habilidades do role estão marcadas p/ inicializar o parentChecked
      const initialParentChecked = roles.reduce((acc, role) => {
        acc[role.id] = role.abilities.every((ability) => pessoa?.abilities.includes(ability.id));
        return acc;
      }, {});
      setParentChecked(initialParentChecked);
    }

    // Filter abilities that are not in any role
    const orphanAbilities = abilities.filter(ability => !roles.some(role => isAbilityInRole(role, ability)));

    setOrphanAbilities(orphanAbilities);
  }, [roles, abilities]);


  // Helper function to check if an ability is in a role
  const isAbilityInRole = (role, ability) => role.abilities.some(roleAbility => roleAbility.id === ability.id);

  useEffect(() => {
    for (const roleId in childChecked) {
      let allChecked = false;
      if (childChecked[roleId]) {
        allChecked = Object.values(childChecked[roleId]).every(Boolean);
      }

      // Update parent checkbox state based on child checkbox state
      setParentChecked((prev) => ({
        ...prev,
        [roleId]: allChecked,
      }));
    }
  }, [childChecked]);

  return (
    <>
      <Grid container spacing={2} sx={{ py: 1 }} item xs={12}>
        <Controller
          name='abilities'
          control={control}
          defaultValue={[]}
          rules={{ validate: value => value.length > 0 || "Selecione no mínimo uma habilidade." }}
          render={({ field: { onChange, value } }) => {
            // Agrupar as abilities por grupo
            const groupedAbilities = roles.reduce((acc, role) => {
              role.abilities.forEach(ability => {
                if (!acc[ability.grupo]) {
                  acc[ability.grupo] = [];
                }
                acc[ability.grupo].push({ ...ability, roleId: role.id });
              });
              return acc;
            }, {});

            return Object.entries(groupedAbilities).map(([grupo, abilities]) => (
              <Grid item xs={12} key={grupo}>
                <Typography variant="h6" sx={{ mt: 2, mb: 1 }}>{grupo}</Typography>
                <Accordion>
                  <AccordionDetails>
                    <Box sx={{ display: 'flex', flexDirection: 'column', ml: 3 }}>
                      {abilities.map((ability) => (
                        <FormControl component="fieldset" key={ability.id} sx={{ p: 1 }}>
                          <FormControlLabel
                            key={ability.id}
                            label={ability.titulo}
                            control={
                              <Checkbox
                                checked={childChecked[ability.roleId]?.[ability.id] || false}
                                disabled={!allowEdit}
                                onChange={(e, isChecked) => {
                                  let updatedAbilities = [...value];
                                  if (isChecked) {
                                    updatedAbilities.push(ability.id);
                                  } else {
                                    updatedAbilities = updatedAbilities.filter((id) => id !== ability.id);
                                  }
                                  setValue('abilities', updatedAbilities, { shouldDirty: true });
                                  onChange(updatedAbilities);
                                  handleChildChange(ability.roleId, ability.id, isChecked);
                                }}
                              />
                            }
                          />
                          <FormHelperText>{ability.descricao}</FormHelperText>
                        </FormControl>
                      ))}
                    </Box>
                  </AccordionDetails>
                </Accordion>
                <Divider />
              </Grid>
            ));
          }}
        />
      </Grid>

      {orphanAbilities && (
        <Controller
          name='abilities'
          control={control}
          defaultValue={[]}
          rules={{ validate: value => value.length > 0 || "Selecione no mínimo uma habilidade." }}
          render={({ field: { onChange, value } }) => {
            // Agrupar orphanAbilities por grupo
            const groupedOrphanAbilities = orphanAbilities.reduce((acc, ability) => {
              if (!acc[ability.grupo]) {
                acc[ability.grupo] = [];
              }
              acc[ability.grupo].push(ability);
              return acc;
            }, {});

            return Object.entries(groupedOrphanAbilities).map(([grupo, abilities]) => (
              <Grid item xs={12} key={grupo}>
                <Typography variant="h6" sx={{ mt: 2, mb: 1 }}>{grupo}</Typography>
                {abilities.map((ability) => (
                  <FormControl component="fieldset" key={ability.id} sx={{ p: 1 }}>
                    <FormControlLabel
                      key={ability.id}
                      label={ability.titulo}
                      control={
                        <Checkbox
                          defaultChecked={pessoa?.abilities.includes(ability.id)}
                          disabled={(ability.id == 1 && pessoa?.abilities.includes(1)) || !allowEdit}
                          onChange={(e, isChecked) => {
                            let updatedAbilities = [...value];
                            if (isChecked) {
                              updatedAbilities.push(ability.id);
                            } else {
                              updatedAbilities = updatedAbilities.filter((id) => id !== ability.id);
                            }
                            setValue('abilities', updatedAbilities, { shouldDirty: true });
                            onChange(updatedAbilities);
                          }}
                        />
                      }
                    />
                    <FormHelperText>{ability.descricao}</FormHelperText>
                  </FormControl>
                ))}
              </Grid>
            ));
          }}
        />
      )}
    </>
  );

}
