import axios from "axios";
import {
    urlUnidadesDeMedidas,
    urlTamanhosEVariacoes,
    urlCategorias,
    urlProdutos,
    urlBuscaProdutoCodigo,
    urlCategoriasEmpresa,
    urlProdutosCategoria,
    urlProdutoFilho,
    urlRetiradas,
    urlDevolucao, urlProdutoEquipamento,
} from "../../../../../constants/endpoints";

// unidades de medida
const listaUnidadesDeMedidas = (filter = null) => axios.get(urlUnidadesDeMedidas, { params: filter })
const atualizaUnidadesDeMedida = (id, data) => axios.patch(`${urlUnidadesDeMedidas}/${id}`, data)
const cadastraUnidadesDeMedida = (data) => axios.post(`${urlUnidadesDeMedidas}`, data)
const manipulaUnidadesDeMedida = (data, isEditAction) => isEditAction ? atualizaUnidadesDeMedida(data.id, data) : cadastraUnidadesDeMedida(data)
const deletaUnidadesDeMedida = (id) => axios.delete(`${urlUnidadesDeMedidas}/${id}`);


// tamanhos/variacoes
const listaTamanhosEVariacoes = (filter = null) => axios.get(urlTamanhosEVariacoes, { params: filter })
const atualizaTamanhoEVariacao = (id, data) => axios.patch(`${urlTamanhosEVariacoes}/${id}`, data)
const cadastraTamanhoEVariacao = (data) => axios.post(`${urlTamanhosEVariacoes}`, data)
const manipulaTamanhoEVariacao = (data, isEditAction) => isEditAction ? atualizaTamanhoEVariacao(data.id, data) : cadastraTamanhoEVariacao(data)
const deletaTamanhoEVariacao = (id) => axios.delete(`${urlTamanhosEVariacoes}/${id}`);

// categorias
const listaCategorias = (empresasId = null, offset = null, limit = null) => {
    // verifica se empresasId é um array ou um valor único, api espera um array
    empresasId = Array.isArray(empresasId) ? empresasId : [empresasId];

    if (empresasId) {
        return axios.get(urlCategorias, { params: { id_empresas: empresasId, offset, limit } })
    }
    return axios.get(urlCategorias)
}
const atualizaCategoria = (id, data) => axios.patch(`${urlCategorias}/${id}`, data)
const cadastraCategoria = (data) => axios.post(`${urlCategorias}`, data)
const manipulaCategoria = (data, isEditAction) => isEditAction ? atualizaCategoria(data.id_categoria, data) : cadastraCategoria(data)
const deletaCategoria = (id) => axios.delete(`${urlCategorias}/${id}`);
const listaCategoriasEmpresa = (data) => axios.get(urlCategoriasEmpresa, {
    params: {
        id_empresa: data
    }
})
const listaGruposByCategoria = (idCategorias) => axios.get(`${urlCategorias}/grupos`, { params: { id_categorias: idCategorias } });

// produtos
const listaProdutos = (filter = null) => axios.get(urlProdutos, { params: filter });
const atualizaProduto = (id, data) => axios.post(`${urlProdutos}/update/${id}`, data)
const consultaCA = (data) => axios.post(`${process.env.REACT_APP_CONSULTA_CA_URL}`, { ca: data })
const buscaProdutoCodigo = (cod) => axios.get(`${urlBuscaProdutoCodigo}`, { params: { cod_externo: cod } })
const cadastrarProduto = (formData) => axios.post(urlProdutos, formData, {
    headers: {
        'Content-Type': 'multipart/form-data'
    }
});
const deletaProduto = (id) => axios.delete(`${urlProdutos}/${id}`);
const listaProdutosEquipamento = (filter = null) => axios.get(`${urlProdutos}/${urlProdutoEquipamento}`, { params: filter });

// produtoFilho
const listaProdutosFilho = (filter = null) => axios.get(urlProdutoFilho, { params: filter });
const listaProdutoFilho = (id) => axios.get(`${urlProdutoFilho}/${id}`);
const listaProdutosCategoria = (id) => axios.get(urlProdutosCategoria.replace(':id', id));

// retiradas
const getRetiradas = (filter) => axios.get(urlRetiradas, { params: filter });

// devolucoes
const listaDevolucoes = (filter = null) => axios.get(urlDevolucao, { params: filter });
const createDevolucao = (data) => axios.post(urlDevolucao, data);

export {
    listaUnidadesDeMedidas,
    atualizaUnidadesDeMedida,
    cadastraUnidadesDeMedida,
    manipulaUnidadesDeMedida,
    deletaUnidadesDeMedida,
    listaCategorias,
    atualizaCategoria,
    cadastraCategoria,
    manipulaCategoria,
    deletaCategoria,
    listaProdutos,
    atualizaProduto,
    deletaProduto,
    listaTamanhosEVariacoes,
    atualizaTamanhoEVariacao,
    cadastraTamanhoEVariacao,
    manipulaTamanhoEVariacao,
    deletaTamanhoEVariacao,
    consultaCA,
    buscaProdutoCodigo,
    cadastrarProduto,
    listaCategoriasEmpresa,
    listaGruposByCategoria,
    listaProdutosFilho,
    listaProdutoFilho,
    listaProdutosCategoria,
    getRetiradas,
    listaDevolucoes,
    createDevolucao,
    listaProdutosEquipamento
}