import axios from 'axios';
import {
    urlAbastecimentoRetiradas,
    urlFichaEpiColaborador,
    urlFichaEpiEmpresa,
    urlFichaEpiEmpresaPDF,
    urlRelatorioAbastecimento,
    urlRelatorioPendenciasRetiradas,
    urlRelatorioRetiradas,
    urlRelatorioRetiradasExtras,
    urlRelatorioRetiradasFunc,
    urlRetiradas,
    urlSaidaProdutos,
    urlSaidaProdutosFunc,
    urlSaidaProdutosFuncAgrupado,
    urlSaldoEpisPessoa,
    urlSaldoMaquina
} from '../../../../../constants/endpoints';

export const getDataFichaEPI = (filter = null) => axios.post(urlFichaEpiColaborador, filter);

export const getSaldoEpisPessoa = (filter = null) => axios.post(urlSaldoEpisPessoa, filter);

export const getDataFichaEPIEmpresa = (filter = null) => axios.post(urlFichaEpiEmpresa, filter);

export const generateFichaEPIEmpresaPDF = (filter = null) => axios.post(urlFichaEpiEmpresaPDF, filter);

export const getAbastecimentoData = (filter = null) => axios.post(urlRelatorioAbastecimento, filter);

export const getSaldoMaquinaData = (filter = null) => axios.post(urlSaldoMaquina, filter);

export const getRetiradaDetails = (retiradaId) => axios.get(`${urlRetiradas}/${retiradaId}`);

export const getRetiradasData = (filter = null) => axios.post(urlRelatorioRetiradas, filter);

export const getPendenciasRetiradasData = (filter = null) => axios.post(urlRelatorioPendenciasRetiradas, filter);

export const getRetiradasExtrasData = (filter = null) => axios.post(urlRelatorioRetiradasExtras, filter);

export const getRetiradasFuncData = (filter = null) => axios.post(urlRelatorioRetiradasFunc, filter);

export const getSaidasProdutosFuncData = (filter = null) => axios.post(urlSaidaProdutosFunc, filter);

export const getSaidasProdutosFuncAgrupadoData = (filter = null) => axios.post(urlSaidaProdutosFuncAgrupado, filter);

export const getSaidasProdutosData = (filter = null) => axios.post(urlSaidaProdutos, filter);

export const getAbastecimentoRetiradasData = (filter = null) => axios.post(urlAbastecimentoRetiradas, filter);