import React, {useState, useEffect, useMemo} from 'react';
import { Helmet } from 'react-helmet-async';
import { Controller, useForm } from 'react-hook-form';
import {
    Grid,
    Breadcrumbs,
    Typography,
    Button,
    TextField, CircularProgress, Box, Tooltip, Badge, LinearProgress,
} from '@mui/material';
import {Add, ArrowOutward, Cancel, CheckCircle, FilterAlt, Home} from '@mui/icons-material';
import PageTitle from '../../common/PageTitle';
import LinkNavegacao from '../../common/Link';
import { useCommonItems } from '../../../contexts/CommonItensProvider';
import {
    EyeIcon,
    GeneralMandatoryActionIcon,
} from '../../common/Icons';
import {listLogs} from './configs/functions';
import {TooltipSelectDisabled} from "../../common/TooltipSelectDisabled";
import {listaEmpresas} from "../management/companies/configs/functions";
import {useAppContext} from "../../../contexts/AppContext";
import {SelectEmpresaFilter} from "../../common/SelectEmpresaFilter";
import dayjs from "dayjs";
import {listaUsuariosFilter} from "../management/people/configs/functions";
import {checkPermissionsAndRedirect, getFormErrorMessage } from "../../../configs/functions";
import {PaginatedUserAutocomplete} from "../../common/PaginatedUserAutocomplete";
import MenuItem from "@mui/material/MenuItem";
import DateRangePicker from "../../common/DateRangePicker";
import {DataGrid, ptBR} from "@mui/x-data-grid";
import {green, grey} from "@mui/material/colors";
import {CustomToolbarReports} from "../../common/CustomToolbarReports";
import {formatDateTime, parseDateTime} from "../../../utils/format-date";

export function LogsPanel() {
    const { exibirAlerta, exibirDialog, handleCloseDialog } = useCommonItems();
    const { getEmpresaIdSession, setEmpresaIdSession } = useAppContext();

    // estados para controle de paginacao
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(20);
    const [numTotalItems, setNumTotalItems] = useState(0);

    // periodo de busca
    const initialDataInicio = dayjs().subtract(1, 'month');
    const initialDataFim = dayjs();
    const [dateRange, setDateRange] = useState([initialDataInicio, initialDataFim]);

    const [openForm, setOpenForm] = useState(false);

    const [logs, setLogs] = useState([]);
    const [empresas, setEmpresas] = useState([]);
    const [pessoas, setPessoas] = useState([]);

    const [pagePessoas, setPagePessoas] = useState(0);
    const [hasMorePessoas, setHasMorePessoas] = useState(true);

    const [selectedEmpresaId, setSelectedEmpresaId] = useState(null);

    const [isLoading, setIsLoading] = useState(false);
    const [isSearched, setIsSearched] = useState(false);
    const [isLoadingPessoas, setIsLoadingPessoas] = useState(false);

    const {
        handleSubmit,
        setValue,
        getValues,
        clearErrors,
        reset,
        control,
        formState: { errors },
    } = useForm();

    const columns = useMemo(() => [
        {
            field: 'nomeUsuario',
            headerName: 'Nome usuário',
            flex: 1,
            valueGetter: (params) => params.row.pessoa?.nome
        },
        {
            field: 'referencia',
            headerName: 'Identificador',
            flex: 1,
            valueGetter: (params) => params.row.referencia
        },
        { field: 'tipoAcesso', headerName: 'Tipo de acesso', minWidth: 150, flex: 1 },
        { field: 'model', headerName: 'Instância', minWidth: 120, flex: 1 },
        {
            field: 'acao',
            headerName: 'Ação',
            flex: 1,
            valueGetter: (params) => {
                switch (params.row.acao) {
                    case 'store': return 'Armazenar';
                    case 'update': return 'Atualizar';
                    case 'set': return 'Designar';
                    case 'import': return 'Importar';
                    case 'reset': return 'Resetar';
                    case 'runRoutine': return 'Rotina iniciada';
                    case 'errorRoutine': return 'Rotina com erro';
                    case 'successRoutine': return 'Rotina concluída'
                }
            },
        },
        {
            field: 'log',
            flex: 1,
            headerName: 'Mensagem',
            renderCell: (params) => {
                return (
                    <Tooltip
                        title={params.row.log ? params.row.log : 'Sem mensagem'}
                        arrow
                    >
                        <span style={{ cursor: 'pointer' }}>
                            <GeneralMandatoryActionIcon style={{ color: '#1e5139' }} />
                        </span>
                    </Tooltip>

                );
            }
        },
        {
            field: 'dataLog',
            headerName: 'Data',
            flex: 1,
            type: 'dateTime',
            valueGetter: (params) => parseDateTime(params.row?.createdAt),
            renderCell: (params) => formatDateTime(params.row?.createdAt, "DD/MM/YYYY HH:mm:ss"),
        },
    ], []);

    // funções
    async function loadLogsData(id_empresas, id_pessoa, tipo_acesso, acao, data_inicio, data_fim, log) {
        try {
            setIsLoading(true);

            data_inicio = dayjs(data_inicio).format('YYYY-MM-DD');
            data_fim = dayjs(data_fim).format('YYYY-MM-DD');

            const filter = {
                data_inicio,
                data_fim
            }

            if (id_empresas === 'all') {
                filter.empresa = empresas.map((empresa) => empresa.id);
            } else {
                filter.id_empresa = [id_empresas];
            }

            if (id_pessoa) filter.id_pessoa = id_pessoa;
            if (acao) filter.acao = acao;
            if (tipo_acesso) filter.tipo_acesso = tipo_acesso;
            if (log) filter.log = log;

            const res = await listLogs(filter);
            setLogs(res.data.data);
            setNumTotalItems(res.data.data);
        } catch (error) {
            exibirAlerta('Ops', 'Ocorreu um erro ao carregar logs', 'error');
        } finally {
            setIsLoading(false);
        }
    }

    const onSubmit = async (data) => {
      try {
          setIsSearched(true);
          setPage(0);
          loadLogsData(data.id_empresa, data.id_pessoa, data.tipo_acesso, data.acao, data.data_inicio, data.data_fim, data.log);
      } catch (error) {
          exibirAlerta('Ops', 'Erro ao carregar logs', 'error');
          setIsSearched(false);
      }
    };

    const listaColaboradores = async (currentPage = 0, initialPageValue = false, filterValue = "") => {
        if (!selectedEmpresaId) return;

        if (initialPageValue) {
            setPessoas([]);
            setPagePessoas(0);
            setHasMorePessoas(true);
        }

        try {
            setIsLoadingPessoas(true);

            const filters = {
                id_empresa: selectedEmpresaId,
                limit: 10,
                offset: (initialPageValue ? 0 : currentPage) * 10
            }

            if (filterValue && !isNaN(filterValue)) {
                filters.matricula = filterValue;
                delete filters.nome;
            } else {
                filters.nome = filterValue;
                delete filters.matricula;
            }

            const response = await listaUsuariosFilter(filters);
            const newOptions = response.data.data;

            setPessoas((prevOptions) => [...prevOptions, ...newOptions]);
            setHasMorePessoas(newOptions.length > 0);
            setPagePessoas((prevPage) => prevPage + 1);
        } catch (error) {
            exibirAlerta('Ops', 'Erro ao carregar colaboradores', 'error');
        } finally {
            setIsLoadingPessoas(false);
        }
    };


    useEffect(() => {
       const requiredPermissionsView = ["admin", "admin_log", "list_log"];
       checkPermissionsAndRedirect(requiredPermissionsView);

       let defaultEmpresaId = null;
       const selectedEmpresaIdSession = getEmpresaIdSession();

       if (selectedEmpresaIdSession) {
           defaultEmpresaId = selectedEmpresaIdSession;
           setSelectedEmpresaId(selectedEmpresaId);
       }

       listaEmpresas()
           .then((response => {
               exibirDialog('Carregando dados da Empresa...');

               const empresasData = response?.data.data;
               setEmpresas(empresasData);

               if (!selectedEmpresaIdSession) {
                   defaultEmpresaId = empresasData[0].id;
                   setSelectedEmpresaId(empresasData[0].id);
                   setEmpresaIdSession(empresasData[0].id);
               }

               const defaultValues = {
                   id_empresas: defaultEmpresaId,
                   id_pessoa: '',
                   acao: '',
                   tipo_acesso: '',
                   data_inicio: initialDataInicio,
                   data_fim: initialDataFim
               };
               reset(defaultValues);

               loadLogsData(defaultEmpresaId, null, null, null, defaultValues.data_inicio, defaultValues.data_fim);
           }))
           .catch((error) => {
               console.error(error);
           })
           .finally(() => {
               handleCloseDialog();
           });

       return () => {
           reset();
       }
    }, []);

    useEffect(() => {
        if (!openForm) {
            const id_empresas = getValues('id_empresas');
            const id_pessoa = getValues('id_pessoa');
            const acao = getValues('acao');
            const tipo_acesso = getValues('tipo_acesso');
            const data_inicio = getValues('data_inicio')
            const data_fim = getValues('data_fim')
            const log = getValues('log');

            if (!id_empresas || !data_inicio || !data_fim) {
                return;
            }

            loadLogsData(id_empresas, id_pessoa, tipo_acesso, acao, data_inicio, data_fim, log);
        }
    }, [page, rowsPerPage, openForm]);

    return (
        <>
            <Helmet title="Registro de Logs" defer={false}/>

            <Grid container="row" justifyContent="flex-start" alignItems="stretch" spacing={2}>
                <Grid item xs={12}>
                    <Breadcrumbs>
                        <LinkNavegacao to='/'><Home fontSize='small'/></LinkNavegacao>
                        <Typography variant='span'>Gerenciamento admin</Typography>
                        <Typography variant='span'>Registro de logs</Typography>
                    </Breadcrumbs>

                    <PageTitle icon={<EyeIcon fontSize='large'/>} title='Registro de Logs'/>

                    <Typography p pb={4}>
                        Acompanhe eventos, identifique erros e monitore atividades em tempo real para uma análise precisa do sistema
                    </Typography>
                </Grid>
            </Grid>

            <Grid container item xs={12} spacing={3} component="form" onSubmit={handleSubmit(onSubmit)}>
                <Grid container item xs={12} md={12} spacing={3}>
                    <Grid item xs={12} md={4}>
                        <SelectEmpresaFilter
                            empresas={empresas}
                            id_empresas={empresas}
                            defaultValue={selectedEmpresaId}
                            enableOptionAll={true}
                            onChangeValue={(value) => {
                                setIsSearched(false);
                                setValue('id_empresas', value, { shouldDirty: true });
                                setValue('id_pessoa', value, { shouldDirty: true });

                                if (value !== 'all') {
                                    setSelectedEmpresaId(value);
                                    setEmpresaIdSession(value);
                                } else {
                                    setSelectedEmpresaId(null);
                                }
                            }}
                            textHelper={false}
                        />
                    </Grid>

                    {pessoas &&
                        <Grid item xs={12} md={4}>
                            <TooltipSelectDisabled isDisabled={selectedEmpresaId}>
                                <PaginatedUserAutocomplete
                                    name="id_pessoa"
                                    control={control}
                                    label="Usuário"
                                    disabled={!selectedEmpresaId}
                                    options={pessoas}
                                    getOptionLabel={(option) => {
                                        const pessoa = pessoas.find(p => p.id === option);
                                        return pessoa ? `${pessoa.matricula} - ${pessoa.nome}` : '';
                                    }}
                                    renderOption={(props, option) => (
                                        <MenuItem {...props} key={option.id} disabled={option.status === 0}>
                                            {option.matricula} - {option.nome} {option.status === 0 ? '- (Inativo)' : ''}
                                        </MenuItem>
                                    )}
                                    onChange={(event, value) => {
                                        setIsSearched(false);
                                        setValue('id_pessoa', value ? value.id : null, { shouldDirty: false });

                                        if (value) {
                                            clearErrors('id_pessoa');
                                        } else {
                                            listaColaboradores(0, true);
                                        }
                                    }}
                                    onInput={(event) => {
                                        setPessoas([]);
                                        setPagePessoas(0);
                                        setHasMorePessoas(true);

                                        const filterValue = event.target.value ?? "";
                                        listaColaboradores(0, true, filterValue);
                                    }}
                                    hasMore={hasMorePessoas}
                                    loadOptionsFn={() => listaColaboradores(pagePessoas, false, "")}
                                    helperText={getFormErrorMessage(errors, 'id_pessoa')}
                                    error={!!errors.id_pessoa}
                                    loading={isLoadingPessoas}
                                    textFieldSize="small"
                                    textFieldStyle={{ backgroundColor: '#fff' }}
                                />
                            </TooltipSelectDisabled>
                        </Grid>
                    }

                    <Grid item xs={12} md={4}>
                        <Controller
                            name="tipo_acesso"
                            control={control}
                            defaultValue=""
                            render={({field}) => (
                                <TextField
                                    {...field}
                                    select
                                    fullWidth
                                    label="Tipo de acesso"
                                    size="small"
                                    onChange={(event) => {
                                        setIsSearched(false);
                                        field.onChange(event);
                                    }}
                                    InputProps={{ style: { backgroundColor: '#fff' } }}
                                >
                                    <MenuItem value="">Todos</MenuItem>
                                    <MenuItem value="usuario">Usuário</MenuItem>
                                    <MenuItem value="integracao">Integração</MenuItem>
                                    <MenuItem value="rotina">Rotina</MenuItem>
                                </TextField>
                            )}
                        />
                    </Grid>

                    <Grid item xs={12} md={4}>
                        <Controller
                            name="acao"
                            control={control}
                            defaultValue=""
                            render={({field}) => (
                                <TextField
                                    {...field}
                                    select
                                    fullWidth
                                    label="Ação"
                                    size="small"
                                    onChange={(event) => {
                                        setIsSearched(false);
                                        field.onChange(event);
                                    }}
                                    InputProps={{ style: { backgroundColor: '#fff' } }}
                                >
                                    <MenuItem value="">Todos</MenuItem>
                                    <MenuItem value="store">Armazenar</MenuItem>
                                    <MenuItem value="update">Atualizar</MenuItem>
                                    <MenuItem value="delete">Excluir</MenuItem>
                                    <MenuItem value="set">Designar</MenuItem>
                                    <MenuItem value="import">Importar</MenuItem>
                                    <MenuItem value="runRoutine">Rodar rotina</MenuItem>
                                    <MenuItem value="errorRoutine">Erro de rotina</MenuItem>
                                    <MenuItem value="successRoutine">Rotinas concluídas</MenuItem>
                                </TextField>
                            )}
                        />
                    </Grid>

                    <Grid item xs={12} md={4}>
                        <Controller
                            name="log"
                            control={control}
                            defaultValue=""
                            render={({ field }) => (
                                <TextField
                                    label="Registro"
                                    fullWidth
                                    size="small"
                                    {...field}
                                    onChange={(e) => {
                                        field.onChange(e);
                                        setIsSearched(false);
                                    }}
                                />
                            )}
                        />
                    </Grid>

                    <Grid item xs={12} md={4}>
                        <DateRangePicker
                            value={dateRange}
                            bgColor='#fff'
                            onChange={(value) => {
                                setDateRange(value);
                                const [start, end] = value;
                                setValue('data_inicio', start ? dayjs(start).format('YYYY-MM-DD') : '');
                                setValue('data_fim', end ? dayjs(end).format('YYYY-MM-DD') : '');
                                setIsSearched(false);
                            }}
                            onReset={() => {
                                setIsSearched(false);
                                setDateRange([null, null]);
                                setValue('data_inicio', '');
                                setValue('data_fim', '');
                            }}
                            hasError={errors.data_inicio || errors.data_fim}
                        />
                    </Grid>

                    {/*  Botão de filtragem  */}
                    <Grid item xs={12} md={2}>
                        <Button
                            type='submit'
                            color='primary'
                            variant='contained'
                            disabled={isLoading || Object.keys(errors).length > 0 || isSearched}
                            startIcon={isLoading ? <CircularProgress size={16} sx={{ color: "textSecondary" }} /> : <FilterAlt />}
                        >
                            Filtrar
                        </Button>
                    </Grid>

                    {/*  Tabela  */}
                    <Grid item xs={12}>
                        <Box sx={{
                            height: logs.length > 0 ? "auto" : 400,
                            minHeight: 400,
                            width: '100%',
                            overflowX: "auto",
                            '& .MuiDataGrid-columnHeaders, .MuiDataGrid-toolbarContainer' : {
                                backgroundColor: '#d8d8d8'
                            },
                            '& .MuiDataGrid-columnHeaders' : {
                                borderRadius: 0
                            }
                        }}>
                            <DataGrid
                                getRowId={(row) => row.id}
                                rows={logs}
                                columns={columns}
                                loading={isLoading}
                                pageSizeOptions={[10, 20, 50, 100]}
                                initialState={{
                                    pagination: {
                                        paginationModel: { pageSize: 20, page: 0 }
                                    }
                                }}
                                sx={{
                                    backgroundColor: '#fff',
                                    '.MuiDataGrid-columnHeaderTitle': {
                                        fontWeight: 'bold !important',
                                        overflow: 'visible !important',
                                    },
                                    '& .MuiDataGrid-virtualScroller': {
                                        overflowX: 'auto !important',
                                    },
                                    '& .MuiDataGrid-virtualScroller::-webkit-scrollbar-thumb': {
                                        backgroundColor: grey[600],
                                        borderRadius: '10px',
                                    },
                                    '& .MuiDataGrid-virtualScroller::-webkit-scrollbar-thumb:hover': {
                                        backgroundColor: grey[700],
                                    }
                                }}
                                disableRowSelectionOnClick
                                localeText={ptBR.components.MuiDataGrid.defaultProps.localeText}
                                slots={{
                                    toolbar: (props) =>
                                        <CustomToolbarReports
                                            props={props}
                                        />
                                    ,
                                    loadingOverlay: LinearProgress,
                                    noRowsOverlay: () => (
                                        <Box style={{ display: "flex", width: '100%', textAlign: 'center', height: "100%", alignItems: 'center', justifyContent: 'center' }}>
                                            {isSearched ?
                                                <Typography variant='h6' color='textSecondary'>Nenhum registro encontrado para o filtro selecionado</Typography>
                                                :
                                                <Typography variant='h6' color='textSecondary'>Selecione uma empresa e o período que deseja filtrar</Typography>
                                            }
                                        </Box>
                                    )
                                }}
                            />
                        </Box>
                    </Grid>
                </Grid>
            </Grid>
        </>
    );
}