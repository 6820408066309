import { useFieldArray } from 'react-hook-form';
import {
  Button,
  Divider,
  Grid,
  IconButton,
  TextField,
  Typography
} from '@mui/material';
import { Add, Delete } from '@mui/icons-material';

export function FiltersAndExtraFieldsStep({ control, register, getValues, setValue, errors }) {
  const { fields: camposExtrasFields, append: appendCampoExtra, remove: removeCampoExtra } = useFieldArray({
    control,
    name: 'campos_extras',
  });

  return (
    <Grid
      container
      direction="row"
      justifyContent="flex-start"
      alignItems="flex-start"
      spacing={2}
    >
      <Grid item xs={12}>
        <Divider />
      </Grid>

      <Grid item xs={12}>
        <Typography variant="h6">
          Campos Adicionais (serão inseridos no arquivo) - <i>opcional</i>
        </Typography>
        <Divider />
      </Grid>

      {camposExtrasFields.map((item, index) => (
        <Grid container item xs={12} spacing={2} key={item.id}>
          <Grid item xs={12} md={5.5}>
            <TextField
              label="Nome do Campo"
              placeholder="Digite o nome do campo"
              {...register(`campos_extras.${index}.nome`)}
              margin="dense"
              fullWidth
              variant="outlined"
              InputLabelProps={{ shrink: true }}
            />
          </Grid>

          <Grid item xs={12} md={5.5}>
            <TextField
              label="Valor"
              placeholder="Digite o valor do campo"
              {...register(`campos_extras.${index}.valor`)}
              margin="dense"
              fullWidth
              variant="outlined"
              InputLabelProps={{ shrink: true }}
            />
          </Grid>

          <Grid item xs={12} md={1} display="flex" alignItems="center">
            <IconButton onClick={() => removeCampoExtra(index)}>
              <Delete />
            </IconButton>
          </Grid>
        </Grid>
      ))}

      <Grid item xs={12}>
        <Button
          variant="contained"
          color="primary"
          startIcon={<Add />}
          onClick={() => appendCampoExtra({ nome: '', valor: '' })}
        >
          Adicionar Campo
        </Button>
      </Grid>
    </Grid>
  )
}