import { useState, useEffect, useCallback, useRef } from 'react';
import dayjs from 'dayjs';
import axios from 'axios'
import { useForm, Controller } from 'react-hook-form';
import { useQuery, useQueryClient } from '@tanstack/react-query';
import {
  Box,
  Grid,
  Typography,
  FormControl,
  MenuItem,
  DialogTitle,
  DialogContentText,
  DialogContent,
  Dialog,
  TextField,
  FormControlLabel,
  Checkbox,
  Tabs,
  Tab,
  Stack,
  Skeleton,
} from '@mui/material';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { useAppContext } from '../../../../../contexts/AppContext';
import { useCommonItems } from '../../../../../contexts/CommonItensProvider';
import { useModuleContext } from '../../../../../contexts/ModuleProvider';
import { estadosBrasileiros } from '../../../../../configs/constants';
import {
  maskCPF,
  renderDay,
  validateCPF,
  unmaskValue,
  maskPhoneNumber,
  validatePhoneNumber,
  formatDateTimeToDate,
  convertToIsoDate,
  getFormErrorMessage,
  fillInputErrors,
  hasPermission
} from '../../../../../configs/functions'
import { AbilitiesCheckboxes } from './AbilitiesCheckboxes';
import { WebcamCapture } from './WebcamCapture';
import { listaEmpresas, verificarLimitesEmpresa } from '../../companies/configs/functions';
import { TutorialInstructions } from '../../../../common/TutorialInstructions';
import { listaAreas, listaCentroCustos, listaRegimesTrabalho, listaSetores, manipulaProntuario } from '../../organization/configs/functions';
import { listaFuncoes, listaUsuariosFilter, markEmpresasWithLimitStatus, verifyCPFIsAlreadyRegistered } from '../configs/functions';
import { TooltipSelectDisabled } from '../../../../common/TooltipSelectDisabled';
import { DocumentsUploader } from './DocumentsUploader';
import { listaProntuarios, listaTiposDocumento } from '../../organization/configs/functions';
import { InsertDriveFile, TextSnippet } from '@mui/icons-material';
import { CommonDialogActionsForm } from '../../../../common/CommonDialogActionsForm';
import { emailRegex } from '../../../../../utils/regex';
import { getDefaultValueSelectField } from '../../../../../utils';
import { PaginatedUserAutocomplete } from '../../../../common/PaginatedUserAutocomplete';
import { extractErrorMessage } from '../../../../../utils/extract-error-message';
import { PasswordConfirmationDialog } from '../../../../common/PasswordConfirmationDialog';
import { ConfirmEditMatriculaDialog } from './ConfirmEditMatriculaDialog';
import ConfirmDialog from '../../../../common/ConfirmDialog';

export function UserForm({ open, setOpen, pessoa, queryKey, actionsAllowed, isMaxLimitTipoPessoa = false, userType = 'colaborador', changeUserToAdmin = false }) {
  const queryClient = useQueryClient();
  const isEditAction = !!pessoa;

  const { getEmpresasFilteredByModule } = useModuleContext();
  const { dadosUsuario, updateDadosUsuarioLocalStorage } = useAppContext();
  const { exibirDialog, handleCloseDialog, exibirAlerta } = useCommonItems();

  const passwordDialogRef = useRef();

  // O usuário pode interagir com o formulário se:
  // 1. Possuir permissões ('admin', 'admin_pessoas', 'update_pessoas').
  // 2. For uma ação de cadastro (empresas com Módulo Permitido).
  // 3. For uma edição e a flag `actionsAllowed` for verdadeira.
  const allowInteraction =
    hasPermission(['admin', 'admin_pessoas', 'update_pessoas']) &&
    (!isEditAction || (isEditAction && actionsAllowed));

  const userCanListDocuments = hasPermission(['admin', 'admin_prontuario', 'list_prontuario']);

  // armazena base64 da imagem capturada
  const [biometriaSrc, setBiometriaSrc] = useState(null);
  const [isAllowedCapture, setIsAllowedCapture] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isAdmin, setIsAdmin] = useState(userType === 'administrador');

  const [selectedEmpresaId, setSelectedEmpresaId] = useState(null);
  const [selectedTab, setSelectedTab] = useState(0);

  const [prontuarios, setProntuarios] = useState([]);

  const genderOptions = [{ value: 'Masculino' }, { value: 'Feminino' }, { value: 'Não-informado' }];

  const [supervisores, setSupervisores] = useState([]);

  const [pagePessoas, setPagePessoas] = useState(0);
  const [loadingSupervisores, setLoadingSupervisores] = useState(false);
  const [hasMore, setHasMore] = useState(true);

  const [openConfirmEditMatriculaDialog, setOpenConfirmEditMatriculaDialog] = useState(false);
  const [openConfirmCreateSameCpf, setOpenConfirmCreateSameCpf] = useState(false);

  const handleChangeTab = (event, newValue) => {
    setSelectedTab(newValue);
  };

  const {
    register,
    handleSubmit,
    getValues,
    setValue,
    setError,
    clearErrors,
    reset,
    control,
    formState: { dirtyFields, errors },
  } = useForm();

  const onSubmit = async (data, passwordConfirmed = false, createDuplicateCpf = false) => {
    setIsLoading(true);
    exibirDialog('Salvando. Aguarde...');

    data.celular = unmaskValue(data.celular);
    data.cpf = unmaskValue(data.cpf);
    data.rg = unmaskValue(data.rg);
    if (data.dt_nascimento) {
      data.dt_nascimento = formatDateTimeToDate(data.dt_nascimento);
    }

    let formDataToSend = {};
    if (isEditAction) {
      const dirtyFieldKeys = Object.keys(dirtyFields);

      const dirtyData = dirtyFieldKeys.reduce((acc, key) => {
        if (data.hasOwnProperty(key)) {
          acc[key] = data[key];
        }
        return acc;
      }, {});

      if (data.isAdmin) {
        dirtyData.tipo_usuario = 'administrador';
        delete dirtyData.isAdmin;
      }

      // Fluxo de confirmação de matrícula
      if (dirtyData.matricula && pessoa.matricula !== dirtyData.matricula && !passwordConfirmed) {
        handleOpenConfirmEditMatricula();
        handleCloseDialog();
        setIsLoading(false);
        return;
      }

      formDataToSend = dirtyData;
    } else {
      // defaultValues to new pessoa
      delete data.id;
      data.is_active_twofactor = 1;
      data.notificacao_email = 1;
      data.notificacao = 1;
      data.notificacao_whatsapp = 1;
      data.status = 1;
      data.tipo_usuario = userType;
      formDataToSend = data;
    }

    if (formDataToSend.cpf) {
      const userExists = await verifyCPFIsAlreadyRegistered(data.cpf, data.id_empresa);
      if (userExists && !createDuplicateCpf) {
        handleOpenConfirmCreateDuplicateCpf();
        handleCloseDialog();
        setIsLoading(false);
        return;
      }
    }

    // nao sera permitido excluir a biometria
    if (biometriaSrc) {
      formDataToSend.bio_face_identifier = biometriaSrc;

      // se for edição e a biometria foi alterada, não enviar a biometria antiga
      if (isEditAction && pessoa.biofaceIdentifier === biometriaSrc) {
        delete formDataToSend.bio_face_identifier;
      }
    } else {
      delete formDataToSend.bio_face_identifier;
    }

    // enviar prontuarios por endPoint especifico
    delete formDataToSend.prontuarios;

    axios({
      method: isEditAction ? 'patch' : 'post',
      url: isEditAction ? `/pessoas/${pessoa.id}` : '/pessoas',
      data: formDataToSend,
    }).then((response) => {
      exibirAlerta('Sucesso', response.data.message, 'success')

      if (isEditAction && dadosUsuario) {
        // se a pessoa editada é a mesma pessoa logada, então deve salvar no sessionStorage as informações atualizadas

        if (pessoa.id === dadosUsuario.pessoa.id_pessoa) {
          const updatedAbilitiesNames = [];

          data.abilities.forEach((abilityId) => {
            const ability = abilities.find((ability) => ability.id === abilityId);
            updatedAbilitiesNames.push(ability.nome);
          });

          // convertendo dados da pessoa para o formato esperado pelo localStorage
          // mesmo formato do retorno de /login
          const formattedDadosPessoa = {
            id_pessoa: pessoa.id,
            nome: data.nome,
            avatar: biometriaSrc,
            matricula: data.matricula,
            tipoUsuario: userType,
            supervisor: null,
            grupo: data.id_grupo ? {
              id: data.id_grupo, nome: grupos.find((grupo) => grupo.id === Number(data.id_grupo)).nome
            } : null,
            funcao: data.id_funcao ? { id: data.id_funcao, nome: funcoes.find((funcao) => funcao.funcoes_id[0] === Number(data.id_funcao)).nome } : null,
            area: data.id_area ? { id: data.id_area, nome: areas.find((area) => area.areas_id[0] === data.id_area).nome } : null,
            setor: data.id_setor ? { id: data.id_setor, titulo: setores.find((setor) => setor.setores_id[0] === Number(data.id_setor)).titulo } : null,
            regimeTrabalho: data.id_regime_trabalho ? { id: data.id_regime_trabalho, titulo: regimesTrabalho.find((regime) => regime.id === Number(data.id_regime_trabalho)).titulo } : null,
            empresa: { id: data.id_empresa, nome: empresas.find((empresa) => empresa.id === Number(data.id_empresa)).nomeFantasia },
          };

          const updatedDadosUsuario = { ...dadosUsuario };
          updatedDadosUsuario.habilidades = updatedAbilitiesNames;
          updatedDadosUsuario.pessoa = formattedDadosPessoa;
          updateDadosUsuarioLocalStorage(updatedDadosUsuario);
        }
      }

      const userId = !isEditAction ? response.data.id : response.data.data.id;
      // cadastrar biometria do usuário por endPoint especifico
      // setBioFace(userId, biometriaSrc);

      // Adiciona os prontuários formatados
      const prontuariosToSend = prontuarios.map(prontuario => ({
        id: prontuario.id,
        id_pessoa: userId,
        isNew: prontuario.isNew, // variavel de controle para saber se é um novo prontuario
        is_dirty: prontuario.is_dirty, // variavel de controle para saber se o prontuario foi alterado
        id_documento: prontuario.id_documento,
        arquivo: prontuario.arquivo,
        ponto_alerta: prontuario.ponto_alerta,
        data_ocorrencia: prontuario.data_ocorrencia ?? dayjs().format('YYYY-MM-DD'),
        data_inicio: prontuario.data_inicio,
        data_fim: prontuario.data_fim,
      }));

      let allRequestsSuccessful = true;

      const prontuarioPromises = prontuariosToSend.map(async (prontuario) => {
        if (!prontuario.is_dirty && !prontuario.isNew) return Promise.resolve();

        const isEditingProntuario = prontuario.is_dirty && !prontuario.isNew;
        if (isEditingProntuario) {
          delete prontuario.arquivo;
        }

        return manipulaProntuario(prontuario, isEditingProntuario)
          .then(response => {
            exibirAlerta('Sucesso', response.data.message, 'success');
          }).catch((error) => {
            const messageError = extractErrorMessage(error, 'Erro ao enviar documento');
            exibirAlerta('Erro', messageError, 'error');
            allRequestsSuccessful = false;
          });
      });

      Promise.all(prontuarioPromises).then(() => {
        if (allRequestsSuccessful) {
          setOpen(false);
        }
      });

      if (queryKey) {
        // invalidate queryKey
        queryClient.invalidateQueries({ queryKey });
      }
    }).catch((error) => {
      console.log(error);
      const userTypeText = isAdmin ? 'Usuário' : 'Colaborador';
      let errorMessage = `Erro ao cadastrar ${userTypeText}!`;
      if (error.response) {
        const responseErrorMessage = error.response.data?.error
        errorMessage = responseErrorMessage || errorMessage;
        fillInputErrors(error, setError);
      }
      exibirAlerta('Ops, ocorreu um erro', `${errorMessage}`, 'error')
    }).finally(() => {
      setIsLoading(false);
      handleCloseDialog();
      setOpenConfirmEditMatriculaDialog(false);
      setOpenConfirmCreateSameCpf(false);
    });
  };

  const handleOpenConfirmEditMatricula = () => {
    setOpenConfirmEditMatriculaDialog(true);
  };

  const handleOpenConfirmCreateDuplicateCpf = () => {
    setOpenConfirmCreateSameCpf(true);
  };

  const handleOpenPasswordConfirmation = async () => {
    try {
      const result = await passwordDialogRef.current.openPasswordConfirmation();
      if (result) {
        const formData = getValues();
        const passwordConfirmed = true;
        onSubmit(formData, passwordConfirmed);
      }
    } catch (error) {
      exibirAlerta('Ops!', 'Ocorreu um erro durante a validação.', 'error');
      console.log('Confirmação de senha cancelada ou erro:', error);
    }
  };

  const { data: listaGruposData, isLoading: listaGruposIsLoading, error: listaGruposError } = useQuery({
    queryKey: ['listaGrupos', selectedEmpresaId],
    queryFn: () => axios.get('grupos', { params: { id_empresa: selectedEmpresaId } }),
    enabled: !!selectedEmpresaId,
  });
  const grupos = listaGruposData?.data?.data ?? [];

  const { data: listaFuncoesData, isLoading: listaFuncoesIsLoading, error: listaFuncoesError, } = useQuery({
    queryKey: ['listaFuncoes', selectedEmpresaId],
    queryFn: async () => {
      const response = await listaFuncoes({ id_empresas: [selectedEmpresaId] });
      return response;
    },
    enabled: !!selectedEmpresaId,
  });
  const funcoes = listaFuncoesData?.data?.data ?? [];

  const filterColaboradores = useCallback((colaboradores) => {
    if (!!pessoa) {
      return colaboradores.filter(colaborador => colaborador.id !== pessoa.id);
    }
    return colaboradores;
  }, [pessoa]);

  const loadSupervisoresOptions = useCallback(async (currentPage = 0, initialPageValue = false, filterValue = "") => {
    if (!selectedEmpresaId) return;

    try {
      setLoadingSupervisores(true);

      const filters = {
        id_empresa: selectedEmpresaId,
        limit: 20,
        offset: (initialPageValue ? 0 : currentPage) * 20,
      };

      // if nome is only number, then it's matricula
      if (filterValue && !isNaN(filterValue)) {
        filters.matricula = filterValue;
        delete filters.nome;
      } else {
        filters.nome = filterValue;
        delete filters.matricula;
      }

      const response = await listaUsuariosFilter(filters);
      const newOptions = filterColaboradores(response.data.data);

      // se for edição e o valor selecionado não estiver na lista, adicionar ele manualmente
      const idEmpresaPessoa = pessoa?.empresa?.id;
      const hasSupervisor = pessoa?.supervisor?.idSupervisor;

      // verificar caso seja edição, se o supervisor atual não está na lista, adicionar ele manualmente
      const supervisorOption = hasSupervisor && idEmpresaPessoa === selectedEmpresaId
        ? {
          id: pessoa.supervisor.idSupervisor,
          nome: pessoa.supervisor.nomeSupervisor,
          matricula: pessoa.supervisor.matricula,
          status: pessoa.supervisor.status,
        }
        : null;

      setSupervisores((prevOptions) => {
        const supervisores = [...prevOptions, ...newOptions];

        const updatedOptions = supervisorOption
          ? [supervisorOption, ...supervisores.filter(option => option.id !== supervisorOption.id)]
          : supervisores;

        return updatedOptions;
      });

      setHasMore(response.data.data.length > 0);
      setPagePessoas((prevPage) => prevPage + 1);
    } catch (error) {
      console.error("Erro ao carregar opções:", error);
    } finally {
      setLoadingSupervisores(false);
    }
  }, [filterColaboradores, pessoa, selectedEmpresaId]);

  useEffect(() => {
    if (!selectedEmpresaId) return;
    setPagePessoas(0);
    setHasMore(true);
    setSupervisores([]);
    loadSupervisoresOptions(0, true, "");
  }, [selectedEmpresaId, loadSupervisoresOptions]);

  const { data: listaAreasData, isLoading: listaAreasIsLoading, error: listaAreasError } = useQuery({
    queryKey: ['listaAreas', selectedEmpresaId],
    queryFn: () => listaAreas({ id_empresas: [selectedEmpresaId] }),
    enabled: !!selectedEmpresaId,
  });

  const areas = listaAreasData?.data?.data ?? [];

  const { data: listaSetoresData, isLoading: listaSetoresIsLoading, error: listaSetoresError } = useQuery({
    queryKey: ['listaSetores', selectedEmpresaId],
    queryFn: () => listaSetores({ id_empresas: [selectedEmpresaId] }),
    enabled: !!selectedEmpresaId,
  });
  const setores = listaSetoresData?.data?.data ?? [];

  const { data: listaCentroCustoData, isLoading: listaCentroCustoIsLoading, error: listaCentroCustoError } = useQuery({
    queryKey: ['listaCentroCusto', selectedEmpresaId],
    queryFn: () => listaCentroCustos({ id_empresas: [selectedEmpresaId] }),
    enabled: !!selectedEmpresaId,
  });
  const centrosDeCusto = listaCentroCustoData?.data?.data ?? [];

  const { data: listaRegimesTrabalhoData, isLoading: listaRegimesTrabalhoIsLoading, error: listaRegimesTrabalhoError } = useQuery({
    queryKey: ['listaRegimesTrabalho', selectedEmpresaId],
    queryFn: () => listaRegimesTrabalho({ id_empresas: [selectedEmpresaId] }),
    enabled: !!selectedEmpresaId,
  });
  const regimesTrabalho = listaRegimesTrabalhoData?.data?.regimes ?? [];

  const { data: listaTiposDocumentoData, isLoading: listaTiposDocumentoIsLoading, error: listaTiposDocumentoError } = useQuery({
    queryKey: ['listaTiposDocumento', selectedEmpresaId],
    queryFn: () => listaTiposDocumento({ id_empresa: selectedEmpresaId }),
    enabled: !!selectedEmpresaId,
  });

  async function carregaProntuarios() {
    try {
      const filterByPessoa = { id_pessoa: pessoa.id };
      const res = await listaProntuarios(filterByPessoa);
      setProntuarios(res.data.data);
    } catch (error) {
      const errorMessage = extractErrorMessage(error, 'Erro ao carregar prontuários');
      exibirAlerta('Erro', errorMessage, 'error');
    }
  }

  const { data: listaAbilitiesData, isLoading: listaAbilitiesIsLoading, error: listaAbilitiesError } = useQuery({
    queryKey: ['abilities'],
    queryFn: () => axios.get('ability'),
    enabled: isAdmin,
    staleTime: Infinity,
  });
  const abilities = listaAbilitiesData?.data?.data ?? [];

  const { data: listaRolesData, isLoading: listaRolesIsLoading, error: listaRolesError } = useQuery({
    queryKey: ['roles'],
    queryFn: () => axios.get('roles'),
    enabled: isAdmin,
    staleTime: Infinity,
  });
  const roles = listaRolesData?.data?.data ?? [];

  const { data: verificarLimitesEmpresaData, error: verificarLimitesEmpresaError } = useQuery({
    queryKey: ['verificarLimitesEmpresa', open],
    queryFn: () => verificarLimitesEmpresa(),
    enabled: open,
  });

  const limitesEmpresa = verificarLimitesEmpresaData?.data ?? [];

  const { data: empresas, isPending: isPendingEmpresas, isLoading: isLoadingEmpresas } = useQuery({
    queryKey: ['listaEmpresas', open],
    queryFn: async () => {
      const response = await listaEmpresas();
      let empresas = response?.data?.data ?? [];

      // no cadastro filtrar empresas que possuem modulo apenas como "recebe"
      empresas = getEmpresasFilteredByModule('Pessoa', empresas, !isEditAction);

      // marcar empresas que atingiram o limite de pessoas
      empresas = markEmpresasWithLimitStatus(limitesEmpresa, empresas, userType);

      if (!isEditAction && isAdmin) {
        // if empresas.length === 1, set adminEmpresas to the only empresa available
        setValue('adminEmpresas', empresas.length === 1 ? [empresas[0].id] : []);
      }

      return empresas;
    },
    enabled: open && limitesEmpresa.length > 0,
  });

  function resetFieldsRelatedToEmpresa() {
    if (isEditAction && selectedEmpresaId === pessoa.empresa.id) {
      return;
    }

    setValue('id_funcao', '', { shouldDirty: true });
    setValue('id_supervisor', '', { shouldDirty: true });
    setValue('id_area', '', { shouldDirty: true });
    setValue('id_grupo', '', { shouldDirty: true });
    setValue('id_setor', '', { shouldDirty: true });
    setValue('id_centro_custo', '', { shouldDirty: true });
    setValue('id_regime_trabalho', '', { shouldDirty: true });
  }

  useEffect(() => {
    const empresasArray = empresas || [];
    if (open && empresasArray.length > 0) {
      setIsAdmin(userType === 'administrador' || changeUserToAdmin);

      if (isEditAction && userCanListDocuments) {
        carregaProntuarios();
      }

      const defaultValueIdEmpresa = getDefaultValueSelectField(pessoa, pessoa?.empresa?.id, empresasArray);
      setSelectedEmpresaId(defaultValueIdEmpresa);

      // set default values to form
      // campos select, o input nao consegue puxar o valor definido como default aqui diretamente, deve ser feito também no defaultValue do input
      const defaultValues = {
        id: pessoa?.id ?? null,
        nome: pessoa?.nome ?? '',
        genero: pessoa?.genero ?? "Masculino",
        email: pessoa?.email ?? '',
        celular: pessoa ? maskPhoneNumber(pessoa.celular) : '',
        matricula: pessoa?.matricula ?? '',
        rg: pessoa?.rg ?? '',
        cpf: pessoa ? maskCPF(pessoa.cpf) : '',
        dt_nascimento: pessoa && pessoa.dtNascimento ? convertToIsoDate(pessoa.dtNascimento) : null,
        estado_naturalidade: pessoa?.estadoNaturalidade ?? "MG",
        cidade_naturalidade: pessoa?.cidade_naturalidade ?? "",
        nacionalidade: pessoa?.nacionalidade ?? "Brasileiro",
        tipo_usuario: pessoa?.tipo_usuario ?? 'colaborador',
        id_empresa: defaultValueIdEmpresa,
        id_supervisor: pessoa?.supervisor?.idSupervisor ?? '',
        id_funcao: pessoa?.funcao?.id ?? "",
        id_grupo: pessoa?.grupo?.id ?? "",
        id_area: pessoa?.area?.id ?? "",
        id_setor: pessoa?.setor?.id ?? "",
        id_centro_custo: pessoa?.centroCusto?.id ?? "",
        id_regime_trabalho: pessoa?.regimeTrabalho?.id ?? "",
        bio_face_identifier: pessoa?.biofaceIdentifier ?? '',
      };

      if (isAdmin) {
        defaultValues.abilities = pessoa ? pessoa.abilities : [];
        defaultValues.adminEmpresas = pessoa ? pessoa.adminEmpresas : [];
      }

      reset(defaultValues);

      if (pessoa) {
        if (pessoa.biofaceIdentifier) {
          setBiometriaSrc(pessoa.biofaceIdentifier);
          setIsAllowedCapture(true);
        }
      }

      if (changeUserToAdmin) {
        setValue('isAdmin', !isAdmin, { shouldDirty: true });

        if (!isAdmin) {
          setValue('abilities', [], { shouldDirty: true });
          setValue('adminEmpresas', [], { shouldDirty: true });
        }
      }
    } else {
      // limpa o formulário ao sair
      reset();
      setBiometriaSrc(null);
      setIsAllowedCapture(false);
      setSelectedEmpresaId(null);
      setOpenConfirmEditMatriculaDialog(false);
      setOpenConfirmCreateSameCpf(false);
    }
  }, [empresas, open, userType, changeUserToAdmin, reset]);

  const isLoadingEmpresaInfo = listaFuncoesIsLoading || listaAreasIsLoading || listaGruposIsLoading ||
    listaSetoresIsLoading || listaCentroCustoIsLoading || listaRegimesTrabalhoIsLoading || listaTiposDocumentoIsLoading;

  useEffect(() => {
    if (open && !isLoadingEmpresaInfo) {
      const valueIdFuncao = getDefaultValueSelectField(pessoa, pessoa?.funcao?.id, funcoes, 'funcoes_id');
      const valueIdArea = getDefaultValueSelectField(pessoa, pessoa?.area?.id, areas, 'areas_id');
      const valueIdGrupo = getDefaultValueSelectField(pessoa, pessoa?.grupo?.id, grupos);
      const valueIdSetor = getDefaultValueSelectField(pessoa, pessoa?.setor?.id, setores, 'setores_id');
      const valueIdCentroCusto = getDefaultValueSelectField(pessoa, pessoa?.centroCusto?.id, centrosDeCusto);
      const valueIdRegimeTrabalho = getDefaultValueSelectField(pessoa, pessoa?.regimeTrabalho?.id, regimesTrabalho);

      setValue('id_funcao', valueIdFuncao, { shouldDirty: true });
      setValue('id_area', valueIdArea, { shouldDirty: true });
      setValue('id_grupo', valueIdGrupo, { shouldDirty: true });
      setValue('id_setor', valueIdSetor, { shouldDirty: true });
      setValue('id_centro_custo', valueIdCentroCusto, { shouldDirty: true });
      setValue('id_regime_trabalho', valueIdRegimeTrabalho, { shouldDirty: true });

      if (valueIdFuncao) {
        clearErrors('id_funcao');
      }
      if (valueIdArea) {
        clearErrors('id_area');
      }
      if (valueIdGrupo) {
        clearErrors('id_grupo');
      }
      if (valueIdSetor) {
        clearErrors('id_setor');
      }
      if (valueIdCentroCusto) {
        clearErrors('id_centro_custo');
      }
      if (valueIdRegimeTrabalho) {
        clearErrors('id_regime_trabalho');
      }
    }
  }, [areas, centrosDeCusto, clearErrors, funcoes, grupos, isLoadingEmpresaInfo, open, pessoa, regimesTrabalho, setValue, setores,]);

  useEffect(() => {
    let errorMessage = "";

    if (listaFuncoesError) {
      errorMessage = extractErrorMessage(listaFuncoesError, 'Erro ao carregar as Funções');
    } else if (verificarLimitesEmpresaError) {
      errorMessage = extractErrorMessage(verificarLimitesEmpresaError, 'Erro ao verificar limites de empresas');
    } else if (listaAreasError) {
      errorMessage = extractErrorMessage(listaAreasError, 'Erro ao carregar as Áreas');
    } else if (listaGruposError) {
      errorMessage = extractErrorMessage(listaGruposError, 'Erro ao carregar os Grupos');
    } else if (listaSetoresError) {
      errorMessage = extractErrorMessage(listaSetoresError, 'Erro ao carregar os Setores');
    } else if (listaCentroCustoError) {
      errorMessage = extractErrorMessage(listaCentroCustoError, 'Erro ao carregar os Centros de Custo');
    } else if (listaRegimesTrabalhoError) {
      errorMessage = extractErrorMessage(listaRegimesTrabalhoError, 'Erro ao carregar os Regimes de Trabalho');
    } else if (listaTiposDocumentoError) {
      errorMessage = extractErrorMessage(listaTiposDocumentoError, 'Erro ao carregar os Tipos de Documento');
    } else if (listaAbilitiesError) {
      errorMessage = extractErrorMessage(listaAbilitiesError, 'Erro ao carregar as Habilidades');
    } else if (listaRolesError) {
      errorMessage = extractErrorMessage(listaRolesError, 'Erro ao carregar os Papéis');
    }

    errorMessage && exibirAlerta('Ops! Ocorreu um erro', errorMessage, 'error');
  }, [exibirAlerta, listaAbilitiesError, listaAreasError, listaCentroCustoError, listaFuncoesError, listaGruposError, listaRegimesTrabalhoError, listaRolesError, listaSetoresError, listaTiposDocumentoError, verificarLimitesEmpresaError]);

  return (
    <Dialog
      open={open}
      fullWidth
      maxWidth={"md"}
    >
      <form onSubmit={handleSubmit((data) => onSubmit(data))}>
        {isEditAction
          ? <DialogTitle>Editando {isAdmin ? ' Usuário' : ' Colaborador'} <strong>{pessoa.nome}</strong></DialogTitle>
          : <DialogTitle>Cadastrar novo {isAdmin ? ' Usuário' : ' Colaborador'}</DialogTitle>
        }

        <DialogContent>
          <DialogContentText sx={{ mb: 2 }}>
            {isEditAction
              ? 'Preencha corretamente os dados.'
              : `Preencha corretamente os dados para cadastrar um novo ${isAdmin ? 'usuário' : 'colaborador'}.`
            }
          </DialogContentText>

          {(isEditAction && userCanListDocuments) &&
            <Tabs
              value={selectedTab}
              onChange={handleChangeTab}
              textColor="primary"
              indicatorColor="primary"
              variant="fullWidth"
              sx={{ height: { md: '48px' }, minHeight: { md: '48px' } }}
              aria-label="tabs formulário do colaborador"
            >
              <Tab
                label="Dados Pessoais"
                icon={<TextSnippet fontSize='small' />}
                iconPosition="start"
                sx={{ minHeight: { md: '48px' } }}
              />

              <Tab
                label="Documentos do Colaborador"
                icon={<InsertDriveFile fontSize='small' />}
                iconPosition="start"
                sx={{ minHeight: { md: '48px' } }}
              />
            </Tabs>
          }

          <FormControl variant="standard" fullWidth>
            <Box hidden={(isEditAction && selectedTab !== 0)} sx={{ p: 2, backgroundColor: "#fafafa", borderRadius: 2 }}>
              <Grid
                container
                direction="row"
                justifyContent="flex-start"
                alignItems="flex-start"
                spacing={2}
              >
                <Grid item xs={12} md={9}>
                  <Typography>
                    Dados Pessoais
                  </Typography>
                </Grid>

                <Grid item xs={12} md={8}>
                  <TextField
                    InputLabelProps={{ shrink: true }}
                    margin='dense'
                    label='Nome'
                    placeholder='Digite o Nome'
                    name='nome'
                    error={!!errors.nome}
                    helperText={getFormErrorMessage(errors, 'nome')}
                    fullWidth
                    {...register("nome", { required: true })}
                    autoComplete='off'
                    inputProps={{ form: { autocomplete: 'off' } }}
                    disabled={!allowInteraction}
                  />
                </Grid>

                <Grid item xs={12} md={4}>
                  <TextField
                    select
                    label="Gênero"
                    fullWidth
                    defaultValue={pessoa ? pessoa.genero : 'Masculino'}
                    error={!!errors.genero}
                    helperText={getFormErrorMessage(errors, 'genero')}
                    {...register("genero")}
                    sx={{ mt: 1 }}
                    disabled={!allowInteraction}
                  >
                    {genderOptions.map((option) => (
                      <MenuItem key={option.value} value={option.value}>
                        {option.value}
                      </MenuItem>
                    ))}
                  </TextField>
                </Grid>

                <Grid item xs={12} md={6}>
                  <TextField
                    InputLabelProps={{ shrink: true }}
                    margin='dense'
                    label='Matricula'
                    placeholder='Digite o número de matrícula'
                    name='matricula'
                    error={!!errors.matricula}
                    helperText={getFormErrorMessage(errors, 'matricula')}
                    fullWidth
                    onKeyDown={(evt) => ["e", "E", "+", "-"].includes(evt.key) && evt.preventDefault()}
                    {...register("matricula", { required: true })}
                    autoComplete='off'
                    inputProps={{ form: { autocomplete: 'off' } }}
                    disabled={!allowInteraction}
                  />
                </Grid>

                <Grid item xs={12} md={6}>
                  <Controller
                    name='celular'
                    control={control}
                    render={({ field: { ref, onChange, ...field } }) => (
                      <TextField
                        {...field}
                        InputLabelProps={{ shrink: true }}
                        margin='dense'
                        name='celular'
                        label='Celular'
                        placeholder='Digite o celular'
                        onChange={(e) => setValue('celular', maskPhoneNumber(e.target.value), { shouldDirty: true })}
                        onBlur={(e) => {
                          if (!validatePhoneNumber(e.target.value)) {
                            setError("celular", { type: "invalid", message: "Número de celular inválido" })
                          } else {
                            clearErrors("celular")
                          }
                        }}
                        error={!!errors.celular}
                        helperText={getFormErrorMessage(errors, 'celular')}
                        fullWidth
                        autoComplete='off'
                        inputProps={{ form: { autocomplete: 'off' } }}
                        disabled={!allowInteraction}
                      />
                    )}
                  />
                </Grid>

                <Grid item xs={12} md={6}>
                  <TextField
                    InputLabelProps={{ shrink: true }}
                    type='email'
                    margin='dense'
                    label='Email'
                    placeholder='Digite o Email'
                    name='email'
                    error={!!errors.email}
                    helperText={getFormErrorMessage(errors, 'email')}
                    fullWidth
                    {...register("email", {
                      required: isAdmin ? true : false,
                      pattern: {
                        value: emailRegex,
                        message: 'Por favor, insira um email válido'
                      }
                    })}
                    autoComplete='off'
                    inputProps={{ form: { autocomplete: 'off' } }}
                    disabled={!allowInteraction}
                  />
                </Grid>

                <Grid item xs={12} md={6}>
                  <TextField
                    fullWidth
                    margin='dense'
                    InputLabelProps={{ shrink: true }}
                    label='RG'
                    placeholder='Digite o RG'
                    name='rg'
                    error={!!errors.rg}
                    helperText={getFormErrorMessage(errors, 'rg')}
                    {...register("rg")}
                    autoComplete='off'
                    inputProps={{ form: { autocomplete: 'off' } }}
                    disabled={!allowInteraction}
                  />
                </Grid>

                <Grid item xs={12} md={6}>
                  <Controller
                    name='cpf'
                    control={control}
                    render={({ field: { ref, onChange, ...field } }) => (
                      <TextField
                        {...field}
                        label='CPF'
                        placeholder='Digite o CPF'
                        error={!!errors.cpf}
                        onChange={(e) => {
                          setValue('cpf', maskCPF(e.target.value), { shouldDirty: true })
                          clearErrors('cpf');
                        }}
                        onBlur={(e) => {
                          if (!e.target.value || validateCPF(e.target.value)) {
                            clearErrors("cpf")
                          } else {
                            setError("cpf", { type: "invalid", message: "CPF inválido" })
                          }
                        }}
                        helperText={getFormErrorMessage(errors, 'cpf')}
                        fullWidth
                        margin='dense'
                        InputLabelProps={{ shrink: true }}
                        autoComplete='off'
                        inputProps={{ form: { autocomplete: 'off' } }}
                        disabled={!allowInteraction}
                      />
                    )}
                  />
                </Grid>

                <Grid item xs={12} md={6}>
                  <Controller
                    name='dt_nascimento'
                    control={control}
                    rules={{ required: true }}
                    render={({ field: { ref, onChange, ...field } }) => (
                      <DatePicker
                        sx={{ mt: 1, width: '100%' }}
                        label="Data de Nascimento"
                        format="DD/MM/YYYY"
                        openTo="year"
                        maxDate={dayjs()}
                        views={['year', 'month', 'day']}
                        dayOfWeekFormatter={renderDay}
                        onChange={(data) => {
                          setValue('dt_nascimento', data, { shouldDirty: true });
                          clearErrors('dt_nascimento');
                        }}
                        defaultValue={pessoa && pessoa.dtNascimento && dayjs(convertToIsoDate(pessoa.dtNascimento))}
                        onError={(error) => {
                          if (error) {
                            setError("dt_nascimento", { type: "invalid", message: "Data inválida" })
                          }
                        }}
                        slotProps={{
                          textField: {
                            error: !!errors.dt_nascimento,
                            helperText: getFormErrorMessage(errors, 'dt_nascimento'),
                            InputLabelProps: { shrink: true },
                          },
                        }}
                        disabled={!allowInteraction}
                        TextField={(params) => (
                          <TextField
                            {...field}
                            {...params}
                            autoComplete='off'
                            fullWidth
                            type="date"
                          />
                        )}
                      />
                    )}
                  />
                </Grid>

                <Grid item xs={12} md={4}>
                  <TextField
                    fullWidth
                    InputLabelProps={{ shrink: true }}
                    margin='dense'
                    label='Cidade de Naturalidade'
                    placeholder='Digite a cidade'
                    name='cidade_naturalidade'
                    error={!!errors.cidade_naturalidade}
                    helperText={getFormErrorMessage(errors, 'cidade_naturalidade')}
                    {...register("cidade_naturalidade")}
                    disabled={!allowInteraction}
                  />
                </Grid>

                {estadosBrasileiros &&
                  <Grid item xs={12} md={4}>
                    <Controller
                      name='estado_naturalidade'
                      control={control}
                      defaultValue={pessoa ? pessoa.estadoNaturalidade : "MG"}
                      render={({ field: { ref, onChange, ...field } }) => (
                        <TextField
                          {...field}
                          select
                          fullWidth
                          sx={{ mt: 1 }}
                          label="Estado"
                          onChange={(e) => setValue('estado_naturalidade', e.target.value, { shouldDirty: true })}
                          error={!!errors.estado_naturalidade}
                          helperText={getFormErrorMessage(errors, 'estado_naturalidade')}
                          disabled={!allowInteraction}
                        >
                          {estadosBrasileiros.map((option) => (
                            <MenuItem key={option.sigla} value={option.sigla}>
                              {option.nome}
                            </MenuItem>
                          ))}
                        </TextField>
                      )}
                    />
                  </Grid>
                }

                <Grid item xs={12} md={4}>
                  <TextField
                    fullWidth
                    InputLabelProps={{ shrink: true }}
                    margin='dense'
                    label='Nacionalidade'
                    placeholder='Digite a nacionalidade'
                    name='nacionalidade'
                    error={!!errors.nacionalidade}
                    helperText={getFormErrorMessage(errors, 'nacionalidade')}
                    {...register("nacionalidade")}
                    disabled={!allowInteraction}
                  />
                </Grid>

                <Grid item xs={12} md={9}>
                  <Typography>
                    Empresa
                  </Typography>
                </Grid>

                <Grid container item xs={12} spacing={2}>
                  <Grid item xs={12} md={6}>
                    <Controller
                      name='id_empresa'
                      control={control}
                      rules={{ required: true }}
                      defaultValue={pessoa?.empresa?.id || ""}
                      render={({ field: { ref, onChange, ...field } }) => (
                        <TextField
                          {...field}
                          select
                          fullWidth
                          sx={{ mt: 1 }}
                          label="Empresa"
                          onChange={(e) => {
                            if (e.target.value) {
                              clearErrors('id_empresa');
                            }
                            setSelectedEmpresaId(e.target.value);
                            setValue('id_empresa', e.target.value, { shouldDirty: true })
                            resetFieldsRelatedToEmpresa();
                          }}
                          error={!!errors.id_empresa}
                          helperText={getFormErrorMessage(errors, 'id_empresa')}
                          disabled={!allowInteraction}
                        >
                          {allowInteraction ? (
                            empresas && empresas.length > 0 ? (
                              [
                                ...empresas.filter(option => option.limiteAtingido === false).map(option => (
                                  <MenuItem key={option.id} value={option.id}>
                                    {option.nomeFantasia}
                                  </MenuItem>
                                )),
                                ...empresas.filter(option => option.limiteAtingido === true).map(option => (
                                  <MenuItem key={option.id} value={option.id} disabled>
                                    {option.nomeFantasia} - Limite Atingido
                                  </MenuItem>
                                ))
                              ]
                            ) : (
                              <MenuItem disabled>
                                {(isPendingEmpresas || isLoadingEmpresas) ? 'Carregando...' : 'Nenhuma empresa disponível'}
                              </MenuItem>
                            )
                          ) : (
                            <MenuItem key={pessoa?.empresa.id} value={pessoa?.empresa.id}>
                              {pessoa?.empresa.fantasyName}
                            </MenuItem>
                          )}
                        </TextField>
                      )}
                    />
                  </Grid>

                  <Grid item xs={12} md={6}>
                    <TooltipSelectDisabled isDisabled={selectedEmpresaId}>
                      <PaginatedUserAutocomplete
                        marginTop={1}
                        name="id_supervisor"
                        label="Supervisor"
                        defaultValue={pessoa?.supervisor?.idSupervisor || ""}
                        disabled={!selectedEmpresaId || !allowInteraction}
                        control={control}
                        options={supervisores}
                        loading={loadingSupervisores}
                        error={!!errors.id_supervisor}
                        helperText={getFormErrorMessage(errors, 'id_supervisor')}
                        hasMore={hasMore}
                        loadOptionsFn={() => loadSupervisoresOptions(pagePessoas, false, "")}
                        getOptionLabel={(option) => {
                          const pessoa = supervisores.find(p => p.id === option);
                          return pessoa ? `${pessoa.matricula} - ${pessoa.nome}` : '';
                        }}
                        renderOption={(props, option) => (
                          <MenuItem {...props} key={option.id} disabled={option.status === 0}>
                            {option.matricula} - {option.nome} {option.status === 0 ? '- (Inativo)' : ''}
                          </MenuItem>
                        )}
                        onChange={(e, value) => {
                          setValue('id_supervisor', value ? value.id : null, { shouldDirty: true });
                          if (value) {
                            clearErrors('id_supervisor');
                          } else {
                            loadSupervisoresOptions(0, true);
                          }
                        }}
                        onInput={(event) => {
                          setSupervisores([]);
                          setPagePessoas(0);
                          setHasMore(true);
                          const filterValue = event.target.value ?? "";
                          loadSupervisoresOptions(0, true, filterValue);
                        }}
                      />
                    </TooltipSelectDisabled>
                  </Grid>
                </Grid>

                <Grid item xs={12} md={9}>
                  <Typography>
                    Atribuições do Colaborador
                  </Typography>
                </Grid>

                <Grid item xs={12} md={4}>
                  <TooltipSelectDisabled isDisabled={selectedEmpresaId}>
                    <Controller
                      name='id_funcao'
                      control={control}
                      rules={{ required: true }}
                      defaultValue={pessoa?.funcao?.id || ""}
                      render={({ field: { ref, onChange, ...field } }) => (
                        <TextField
                          {...field}
                          select
                          fullWidth
                          sx={{ mt: 1 }}
                          label="Função"
                          disabled={!selectedEmpresaId || !allowInteraction}
                          onChange={(e) => {
                            setValue('id_funcao', e.target.value, { shouldDirty: true })
                            if (e.target.value) {
                              clearErrors('id_funcao');
                            }
                          }}
                          error={!!errors.id_funcao}
                          helperText={getFormErrorMessage(errors, 'id_funcao')}
                        >
                          {allowInteraction ? (
                            funcoes.length > 0 ? (
                              [
                                ...funcoes.filter(option => option.status[0] !== 0).map(option => (
                                  <MenuItem key={option.funcoes_id[0]} value={option.funcoes_id[0]}>
                                    {option.nome}
                                  </MenuItem>
                                )),
                                ...funcoes.filter(option => option.status[0] === 0).map(option => (
                                  <MenuItem key={option.funcoes_id[0]} value={option.funcoes_id[0]} disabled>
                                    {option.nome} - (Inativo)
                                  </MenuItem>
                                ))
                              ]
                            ) : (
                              <MenuItem disabled>Nenhum disponível</MenuItem>
                            )) :
                            pessoa?.funcao &&
                            <MenuItem key={pessoa?.funcao.id} value={pessoa?.funcao.id}>
                              {pessoa?.funcao.nome}
                            </MenuItem>
                          }
                        </TextField>
                      )}
                    />
                  </TooltipSelectDisabled>
                </Grid>

                <Grid item xs={12} md={4}>
                  <TooltipSelectDisabled isDisabled={selectedEmpresaId}>
                    <Controller
                      name='id_regime_trabalho'
                      control={control}
                      defaultValue={pessoa?.regimeTrabalho?.id || ""}
                      render={({ field: { ref, onChange, ...field } }) => (
                        <TextField
                          {...field}
                          select
                          fullWidth
                          sx={{ mt: 1 }}
                          label="Regime de trabalho"
                          disabled={!selectedEmpresaId || !allowInteraction}
                          onChange={(e) => {
                            setValue('id_regime_trabalho', e.target.value, { shouldDirty: true })
                            if (e.target.value) {
                              clearErrors('id_regime_trabalho');
                            }
                          }}
                          error={!!errors.id_regime_trabalho}
                          helperText={getFormErrorMessage(errors, 'id_regime_trabalho')}
                        >
                          <MenuItem value="">
                            Selecione...
                          </MenuItem>

                          {allowInteraction ? (
                            regimesTrabalho.length > 0 ? (
                              [
                                ...regimesTrabalho.filter(option => option.status !== 0).map(option => (
                                  <MenuItem key={option.id} value={option.id}>
                                    {option.titulo}
                                  </MenuItem>
                                )),
                                ...regimesTrabalho.filter(option => option.status === 0).map(option => (
                                  <MenuItem key={option.id} value={option.id} disabled>
                                    {option.titulo} - (Inativo)
                                  </MenuItem>
                                ))
                              ]
                            ) : (
                              <MenuItem disabled>Nenhum disponível</MenuItem>
                            )) :
                            pessoa?.regimeTrabalho &&
                            <MenuItem key={pessoa?.regimeTrabalho.id} value={pessoa?.regimeTrabalho.id}>
                              {pessoa?.regimeTrabalho.titulo}
                            </MenuItem>
                          }
                        </TextField>
                      )}
                    />
                  </TooltipSelectDisabled>
                </Grid>

                <Grid item xs={12} md={4}>
                  <TooltipSelectDisabled isDisabled={selectedEmpresaId}>
                    <Controller
                      name='id_grupo'
                      control={control}
                      rules={{ required: true }}
                      defaultValue={pessoa?.grupo?.id || ""}
                      render={({ field: { ref, onChange, ...field } }) => (
                        <TextField
                          {...field}
                          select
                          fullWidth
                          sx={{ mt: 1 }}
                          label="Grupo"
                          disabled={!selectedEmpresaId || !allowInteraction}
                          onChange={(e) => {
                            setValue('id_grupo', e.target.value, { shouldDirty: true })
                            if (e.target.value) {
                              clearErrors('id_grupo');
                            }
                          }}
                          error={!!errors.id_grupo}
                          helperText={getFormErrorMessage(errors, 'id_grupo')}
                        >
                          {allowInteraction ? (
                            grupos.length > 0 ? (
                              [
                                ...grupos.filter(option => option.status !== 0).map(option => (
                                  <MenuItem key={option.id} value={option.id}>
                                    {option.nome}
                                  </MenuItem>
                                )),
                                ...grupos.filter(option => option.status === 0).map(option => (
                                  <MenuItem key={option.id} value={option.id} disabled>
                                    {option.nome} - (Inativo)
                                  </MenuItem>
                                ))
                              ]
                            ) : (
                              <MenuItem disabled>Nenhum disponível</MenuItem>
                            )) :
                            pessoa?.grupo &&
                            <MenuItem key={pessoa?.grupo?.id} value={pessoa?.grupo?.id}>
                              {pessoa?.grupo?.nome}
                            </MenuItem>
                          }
                        </TextField>
                      )}
                    />
                  </TooltipSelectDisabled>
                </Grid>

                <Grid item xs={12} md={4}>
                  <TooltipSelectDisabled isDisabled={selectedEmpresaId}>
                    <Controller
                      name='id_area'
                      control={control}
                      rules={{ required: false }}
                      defaultValue={pessoa?.area?.id || ""}
                      render={({ field: { ref, onChange, ...field } }) => (
                        <TextField
                          {...field}
                          select
                          fullWidth
                          sx={{ mt: 1 }}
                          label="Área de Atuação"
                          disabled={!selectedEmpresaId || !allowInteraction}
                          onChange={(e) => {
                            setValue('id_area', e.target.value, { shouldDirty: true })
                            if (e.target.value) {
                              clearErrors('id_area');
                            }
                          }}
                          error={!!errors.id_area}
                          helperText={getFormErrorMessage(errors, 'id_area')}
                        >
                          <MenuItem value="">Selecione...</MenuItem>

                          {allowInteraction ? (
                            areas.length > 0 ? (
                              [
                                ...areas.filter(option => option.status[0] !== 0).map(option => (
                                  <MenuItem key={option.areas_id[0]} value={option.areas_id[0]}>
                                    {option.nome}
                                  </MenuItem>
                                )),
                                ...areas.filter(option => option.status[0] === 0).map(option => (
                                  <MenuItem key={option.areas_id[0]} value={option.areas_id[0]} disabled>
                                    {option.nome} - (Inativo)
                                  </MenuItem>
                                ))
                              ]
                            ) : (
                              <MenuItem disabled>Nenhum disponível</MenuItem>
                            )) :
                            pessoa?.area &&
                            <MenuItem key={pessoa?.area.id} value={pessoa?.area.id}>
                              {pessoa?.area.nome}
                            </MenuItem>
                          }
                        </TextField>
                      )}
                    />
                  </TooltipSelectDisabled>
                </Grid>

                <Grid item xs={12} md={4}>
                  <TooltipSelectDisabled isDisabled={selectedEmpresaId}>
                    <Controller
                      name='id_setor'
                      control={control}
                      rules={{ required: true }}
                      defaultValue={pessoa?.setor?.id || ""}
                      render={({ field: { ref, onChange, ...field } }) => (
                        <TextField
                          {...field}
                          select
                          fullWidth
                          sx={{ mt: 1 }}
                          label="Setor"
                          disabled={!selectedEmpresaId || !allowInteraction}
                          onChange={(e) => {
                            setValue('id_setor', e.target.value, { shouldDirty: true })
                            if (e.target.value) {
                              clearErrors('id_setor');
                            }
                          }}
                          error={!!errors.id_setor}
                          helperText={getFormErrorMessage(errors, 'id_setor')}
                        >
                          {allowInteraction ? (
                            setores.length > 0 ? (
                              [
                                ...setores.filter(option => option.status[0] !== 0).map(option => (
                                  <MenuItem key={option.setores_id[0]} value={option.setores_id[0]}>
                                    {option.titulo}
                                  </MenuItem>
                                )),
                                ...setores.filter(option => option.status[0] === 0).map(option => (
                                  <MenuItem key={option.setores_id[0]} value={option.setores_id[0]} disabled>
                                    {option.titulo} - (Inativo)
                                  </MenuItem>
                                ))
                              ]
                            ) : (
                              <MenuItem disabled>Nenhum disponível</MenuItem>
                            )) :
                            pessoa?.setor &&
                            <MenuItem key={pessoa?.setor?.id} value={pessoa?.setor?.id}>
                              {pessoa?.setor.nome}
                            </MenuItem>
                          }
                        </TextField>
                      )}
                    />
                  </TooltipSelectDisabled>
                </Grid>

                <Grid item xs={12} md={4}>
                  <TooltipSelectDisabled isDisabled={selectedEmpresaId}>
                    <Controller
                      name='id_centro_custo'
                      control={control}
                      // rules={{ required: true }}
                      defaultValue={pessoa?.centroCusto?.id || ""}
                      render={({ field: { ref, onChange, ...field } }) => (
                        <TextField
                          {...field}
                          select
                          fullWidth
                          sx={{ mt: 1 }}
                          label="Centro de Custo"
                          disabled={!selectedEmpresaId || !allowInteraction}
                          onChange={(e) => {
                            setValue('id_centro_custo', e.target.value, { shouldDirty: true })
                            if (e.target.value) {
                              clearErrors('id_centro_custo');
                            }
                          }}
                          error={!!errors.id_centro_custo}
                          helperText={getFormErrorMessage(errors, 'id_centro_custo')}
                        >
                          <MenuItem value="">
                            Selecione...
                          </MenuItem>

                          {allowInteraction ? (
                            centrosDeCusto.length > 0 ? (
                              [
                                ...centrosDeCusto.filter(option => option.status !== 0).map(option => (
                                  <MenuItem key={option.id} value={option.id}>
                                    {option.nome}
                                  </MenuItem>
                                )),
                                ...centrosDeCusto.filter(option => option.status === 0).map(option => (
                                  <MenuItem key={option.id} value={option.id} disabled>
                                    {option.nome} - (Inativo)
                                  </MenuItem>
                                ))
                              ]
                            ) : (
                              <MenuItem disabled>Nenhum disponível</MenuItem>
                            )) :
                            pessoa?.setor &&
                            <MenuItem key={pessoa?.setor?.id} value={pessoa?.setor?.id}>
                              {pessoa?.setor.nome}
                            </MenuItem>
                          }
                        </TextField>
                      )}
                    />
                  </TooltipSelectDisabled>
                </Grid>

                <Grid item xs={12} md={9}>
                  <Typography>
                    Cadastrar Biometria Facial
                  </Typography>
                </Grid>

                <Grid item xs={12} md={5}>
                  <WebcamCapture
                    biometriaSrc={biometriaSrc}
                    setBiometriaSrc={setBiometriaSrc}
                    isAllowedCapture={isAllowedCapture}
                    setIsAllowedCapture={setIsAllowedCapture}
                    isEditAction={isEditAction}
                    disabled={!allowInteraction}
                  />
                </Grid>

                <Grid item xs={12} md={7}>
                  <TutorialInstructions
                    title='Instruções de Captura:'
                    steps={[
                      'Clique em "Abrir Câmera" para iniciar a captura.',
                      'Certifique-se de que a câmera esteja funcionando e que o ambiente esteja bem iluminado.',
                      [
                        'Para tirar a foto:',
                        'Clique em "Capturar".',
                        'Posicione seu rosto no centro da marcação.',
                        'Aguarde a contagem regressiva de 3 segundos.'
                      ],
                      'Se desejar capturar novamente, clique em "Capturar Novamente".'
                    ]}
                  />
                </Grid>

                {(userType === 'colaborador' && isEditAction) && (
                  <>
                    <Grid item xs={12} md={9} mt={2}>
                      <Typography>
                        Alterar Tipo de Usuário
                      </Typography>
                    </Grid>

                    <Grid item xs={12} md={9}>
                      <FormControlLabel
                        label='Tornar Usuário Administrador?'
                        control={
                          <Checkbox
                            {...register(`isAdmin`)}
                            value={isAdmin}
                            checked={isAdmin}
                            disabled={!allowInteraction || isMaxLimitTipoPessoa}
                            onChange={() => {
                              setIsAdmin(!isAdmin);
                              setValue('isAdmin', !isAdmin, { shouldDirty: true });
                              if (!isAdmin) {
                                setValue('abilities', [], { shouldDirty: true });
                                setValue('adminEmpresas', [], { shouldDirty: true });
                              }
                            }}
                          />
                        }
                        sx={{ width: '100%' }}
                      />
                    </Grid>
                  </>
                )}

                {isAdmin && (
                  <>
                    <Grid container item>
                      <Grid item xs={12} sx={{ mt: 1, display: 'flex', gap: '4px', alignItems: 'center' }}>
                        <Typography color={errors.adminEmpresas && 'error'}>
                          Empresas que o Usuário Administra
                        </Typography>

                        {errors.adminEmpresas && (
                          <Typography variant="subtitle2" component="caption" color='error'>
                            - {getFormErrorMessage(errors, 'adminEmpresas')}
                          </Typography>
                        )}

                        {/* {pessoa && pessoa?.abilities.includes(1) && (
                          <Typography variant="subtitle2" component="caption" color={grey[800]}>
                            - Não é possível alterar empresas de administradores
                          </Typography>
                        )} */}
                      </Grid>

                      <Grid container py={1} item xs={12}>
                        {empresas &&
                          <Controller
                            name='adminEmpresas'
                            control={control}
                            defaultValue={[]}
                            rules={{ validate: value => value.length > 0 || "Selecione no mínimo uma empresa." }}
                            render={({ field: { onChange, value } }) => (
                              empresas.map((empresa) => (
                                <Grid item xs={12} md={6} key={empresa.id}>
                                  <FormControlLabel
                                    label={
                                      <>
                                        <Typography>
                                          {empresa.nomeFantasia}

                                          <Typography component="span" style={{ color: '#7a7a7a', marginLeft: '0.2rem' }}>
                                            ({empresa.tipo.tituloTipo})
                                          </Typography>
                                        </Typography>
                                      </>
                                    }
                                    control={
                                      <Checkbox
                                        disabled={!allowInteraction}
                                        defaultChecked={pessoa ? pessoa?.adminEmpresas.includes(empresa.id) : empresas.length === 1}
                                        // nao permitir alterar empresas caso usuario seja admin (1)
                                        // disabled={pessoa && pessoa?.abilities.includes(1)}
                                        onChange={(e, isChecked) => {
                                          let updatedEmpresas = [...value];
                                          if (isChecked) {
                                            updatedEmpresas.push(empresa.id);
                                          } else {
                                            updatedEmpresas = updatedEmpresas.filter((id) => id !== empresa.id);
                                          }
                                          setValue('adminEmpresas', updatedEmpresas, { shouldDirty: true });
                                          onChange(updatedEmpresas);
                                        }}
                                      />
                                    }
                                  />
                                </Grid>
                              ))
                            )}
                          />
                        }
                      </Grid>
                    </Grid>

                    <Grid container item>
                      <Grid item xs={12} sx={{ display: 'flex', gap: '4px', alignItems: 'center' }}>
                        <Typography color={errors.abilities && 'error'}>
                          Habilidades do Usuário
                        </Typography>

                        {errors.abilities && (
                          <Typography variant="subtitle2" component="caption" color='error'>
                            - {getFormErrorMessage(errors, 'abilities')}
                          </Typography>
                        )}
                      </Grid>

                      {listaAbilitiesIsLoading || listaRolesIsLoading ? (
                        <Grid item xs={12}>
                          {Array.from({ length: 3 }).map((_, index) => (
                            <Stack key={index} spacing={2} mb={2} direction="row" width={"100%"}>
                              <Skeleton variant="rounded" width={"100%"} height={60} />
                              <Skeleton variant="rounded" width={"100%"} height={60} />
                              <Skeleton variant="rounded" width={"100%"} height={60} />
                            </Stack>
                          ))}
                        </Grid>
                      ) :
                        <AbilitiesCheckboxes
                          roles={roles}
                          abilities={abilities}
                          pessoa={pessoa}
                          control={control}
                          setValue={setValue}
                          allowEdit={allowInteraction}
                        />
                      }

                      {errors.abilities && (
                        <Grid item xs={12} sx={{ display: 'flex', gap: '4px', alignItems: 'center' }}>
                          {errors.abilities && (
                            <Typography variant="subtitle2" component="caption" color='error'>
                              {getFormErrorMessage(errors, 'abilities')}
                            </Typography>
                          )}
                        </Grid>
                      )}
                    </Grid>
                  </>
                )}
              </Grid>
            </Box>

            <Box hidden={selectedTab !== 1} sx={{ p: 2, backgroundColor: '#fafafa', borderRadius: 2 }}>
              <Grid
                container
                direction="row"
                justifyContent="flex-start"
                alignItems="flex-start"
                spacing={2}
              >
                <Grid container item xs={12} md={12}>
                  <DocumentsUploader
                    prontuarios={prontuarios}
                    setProntuarios={setProntuarios}
                    tiposDocumento={listaTiposDocumentoData?.data?.data ?? []}
                    register={register}
                    setValue={setValue}
                    getValues={getValues}
                    clearErrors={clearErrors}
                    errors={errors}
                    setError={setError}
                    handleExibirAlerta={exibirAlerta}
                    selectedEmpresaId={selectedEmpresaId}
                    disableActions={!allowInteraction}
                  />
                </Grid>
              </Grid>
            </Box>
          </FormControl>
        </DialogContent>

        <CommonDialogActionsForm errors={errors} isLoading={isLoading} setOpen={setOpen} disableSubmit={!allowInteraction} />
      </form>

      <Dialog open={isLoadingEmpresaInfo}>
        <DialogTitle>Carregando informações da empresa. Aguarde...</DialogTitle>
      </Dialog>

      <ConfirmEditMatriculaDialog
        open={openConfirmEditMatriculaDialog}
        selectedCollaborator={pessoa}
        newMatriculaValue={getValues('matricula')}
        goAction={() => {
          setOpenConfirmEditMatriculaDialog(false);
          handleOpenPasswordConfirmation();
        }}
        handleClose={() => setOpenConfirmEditMatriculaDialog(false)}
      />

      <ConfirmDialog
        title='Atenção'
        description={
          <>
            <Typography fontWeight={600} gutterBottom>
              Já existe um cadastro com o CPF informado.
            </Typography>

            <Typography gutterBottom>
              {isEditAction
                ? 'Deseja atualizar o cadastro existente?'
                : 'Deseja criar um novo cadastro com o mesmo CPF?'
              }
            </Typography>

            <Typography fontWeight="bold" color="error" gutterBottom>
              Essa ação irá desativar o cadastro anterior.
            </Typography>
          </>
        }
        confirmText={
          isEditAction
            ? 'Sim, atualizar cadastro existente'
            : 'Sim, criar novo cadastro'
        }
        buttonColor='primary'
        goAction={() => {
          setOpenConfirmCreateSameCpf(false);

          const formData = getValues();
          const createDuplicateCpf = true;

          onSubmit(formData, false, createDuplicateCpf);
        }}
        handleClose={() => setOpenConfirmCreateSameCpf(false)}
        state={openConfirmCreateSameCpf}
      />

      <PasswordConfirmationDialog dialogRef={passwordDialogRef} />

    </Dialog>
  );
}
