import { useState } from 'react';
import ListSubheader from '@mui/material/ListSubheader';
import List from '@mui/material/List';
import Collapse from '@mui/material/Collapse';
import { Divider, Drawer, IconButton, Stack, Toolbar } from '@mui/material';
import MenuTitle from './MenuTitle';
import { styled, useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import CloseIcon from '@mui/icons-material/Close';
import { useAppContext } from '../../contexts/AppContext';
import { LinkMenu } from './LinkMenu';
import MenuEpis from '../../components/screens/epis/menu';
import MenuTraining from '../../components/screens/training/menu';
import MenuRH from '../../components/screens/humanResources/menu';
import {
    ClientManagementIcon,
    DatabaseRestoreIcon,
    InfoIcon,
    OfficeIcon,
    ProductDocumentsIcon,
    RadarIcon,
    SettingsIcon,
    SupportIcon,
    ThumbnailsIcon,
    EyeIcon, ErrorIcon
} from './Icons';

const openedMixin = (theme, isXlScreen) => ({
    width: isXlScreen ? '16%' : '25%',
    transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
    }),
    overflowX: 'hidden',
});

const closedMixin = (theme) => ({
    transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
    }),
    overflowX: 'hidden',
    width: `calc(${theme.spacing(7)} + 1px)`,
    [theme.breakpoints.up('sm')]: {
        width: `calc(${theme.spacing(8)} + 1px)`,
    },
});

const PermanentDrawer = styled(Drawer, {
    shouldForwardProp: (prop) => prop !== 'open' && prop !== 'isXlScreen',
})(
    ({ theme, open, isXlScreen }) => ({
        flexShrink: 0,
        whiteSpace: 'nowrap',
        position: 'fixed',
        boxSizing: 'border-box',
        ...(open && {
            ...openedMixin(theme, isXlScreen),
            '& .MuiDrawer-root': { zIndex: 1 },
            '& .MuiDrawer-paper': openedMixin(theme, isXlScreen),
        }),
        ...(!open && {
            ...closedMixin(theme),
            '& .MuiDrawer-paper': closedMixin(theme),
        }),
    }),
);

export default function GeneralMenu() {
    const { dadosUsuario, drawerOpen, handleCloseDrawer, saveMenuStateToLocalStorage, getMenuStateFromLocalStorage, activeModule } = useAppContext();

    const [openMenuEpi, setOpenMenuEpi] = useState(getMenuStateFromLocalStorage('openMenuEpi', true));
    const [openMenuGerenciamento, setOpenMenuGerenciamento] = useState(getMenuStateFromLocalStorage('openMenuGerenciamento', false));
    const [openMenuSempher, setOpenMenuSempher] = useState(getMenuStateFromLocalStorage('openMenuSempher', true));

    /** Checa se a media Query é menor que SM  */
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('lg'));
    const isXlScreen = useMediaQuery(theme => theme.breakpoints.up("xl"));
    const isXXlScreen = useMediaQuery('(min-width:1921px)');

    const handleMenuEpiClick = () => {
        const newState = !openMenuEpi;
        setOpenMenuEpi(newState);
        saveMenuStateToLocalStorage('openMenuEpi', newState);

        // não permitir fechar ambos fechados simultaneamente
        if (!newState && !openMenuGerenciamento) {
            setOpenMenuGerenciamento(true);
            saveMenuStateToLocalStorage('openMenuGerenciamento', true);
        }
    };

    const handleMenuGerenciamentoClick = () => {
        const newState = !openMenuGerenciamento;
        setOpenMenuGerenciamento(newState);
        saveMenuStateToLocalStorage('openMenuGerenciamento', newState);

        // não permitir fechar ambos fechados simultaneamente
        if (!newState && !openMenuEpi) {
            setOpenMenuEpi(true);
            saveMenuStateToLocalStorage('openMenuEpi', true);
        }
    };

    const handleMenuSempherClick = () => {
        const newState = !openMenuSempher;
        setOpenMenuSempher(newState);
        saveMenuStateToLocalStorage('openMenuSempher', newState);
    };

    const menu = () => {
        return (<>
            <List
                sx={{
                    overflowX: 'hidden',
                    paddingTop: 1,
                    width: '100%',
                    minHeight: isXXlScreen ? '100vh' : 'auto',
                    height: {
                        md: "100%"
                    },
                    bgcolor: 'background.paper',
                    boxShadow: {
                        xs: 0,
                        lg: 2
                    }
                }}
                component="nav"
                aria-labelledby="nested-list-subheader"
                disablePadding
                subheader={
                    <Stack
                        direction="row"
                        justifyContent="space-between"
                        alignItems="flex-start"
                        spacing={2}
                    >
                        <ListSubheader style={{ whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}>
                            {dadosUsuario ? dadosUsuario?.pessoa?.empresa?.nome : ''}
                        </ListSubheader>
                        <IconButton
                            onClick={handleCloseDrawer}
                            sx={{
                                display: {
                                    xs: 'block',
                                    lg: 'none'
                                },
                                color: 'inherit'
                            }}><CloseIcon />
                        </IconButton>
                    </Stack>
                }
            >

                {(activeModule === 'epis') && <MenuEpis openMenuEpi={openMenuEpi} handleClick={handleMenuEpiClick} isMobile={isMobile} />}
                {(activeModule === 'treinamentos') && <MenuTraining openMenuEpi={openMenuEpi} handleClick={() => { }} />}
                {(activeModule === 'rh') && <MenuRH openMenuEpi={openMenuEpi} handleClick={() => { }} />}

                <Divider variant='middle' sx={{ mt: 2 }} />

                <MenuTitle
                    title={'Gerenciamento'}
                    icon={<ThumbnailsIcon />}
                    stateVar={openMenuGerenciamento}
                    fChangeState={handleMenuGerenciamentoClick}
                />

                <Collapse in={openMenuGerenciamento} timeout="auto" unmountOnExit>
                    <List disablePadding>
                        <LinkMenu
                            title='Empresas'
                            url='/gerenciamento/empresas'
                            icon={<OfficeIcon />}
                            drawerOpen={drawerOpen}
                            handleCloseDrawer={handleCloseDrawer}
                            isMobileScreen={isMobile}
                        />

                        <LinkMenu
                            title='Organização'
                            url='/gerenciamento/organizacao'
                            icon={<ProductDocumentsIcon />}
                            drawerOpen={drawerOpen}
                            handleCloseDrawer={handleCloseDrawer}
                            isMobileScreen={isMobile}
                        />

                        <LinkMenu
                            title='Pessoas'
                            url='/gerenciamento/pessoas'
                            icon={<ClientManagementIcon />}
                            drawerOpen={drawerOpen}
                            handleCloseDrawer={handleCloseDrawer}
                            isMobileScreen={isMobile}
                        />

                        <LinkMenu
                            title='Configurações'
                            url='/gerenciamento/configuracoes'
                            icon={<SettingsIcon />}
                            drawerOpen={drawerOpen}
                            handleCloseDrawer={handleCloseDrawer}
                            isMobileScreen={isMobile}
                        />

                        <Divider variant='middle' />

                        <LinkMenu
                            title='Detalhes'
                            url='/gerenciamento/detalhes'
                            icon={<InfoIcon />}
                            drawerOpen={drawerOpen}
                            handleCloseDrawer={handleCloseDrawer}
                            isMobileScreen={isMobile}
                        />
                    </List>
                </Collapse>

                {dadosUsuario?.isSempher && (
                    <>
                        <Divider variant='middle' sx={{ mt: 2 }} />

                        <MenuTitle
                            title="Admin Sempher"
                            icon={<SupportIcon />}
                            stateVar={openMenuSempher}
                            fChangeState={handleMenuSempherClick}
                        />

                        <Collapse in={openMenuSempher} timeout="auto" unmountOnExit>
                            <List disablePadding>
                                <LinkMenu
                                    title='Painel de Monitoramento'
                                    url='/admin/painel'
                                    icon={<RadarIcon />}
                                    drawerOpen={drawerOpen}
                                    handleCloseDrawer={handleCloseDrawer}
                                    isMobileScreen={isMobile}
                                />

                                <LinkMenu
                                    title='Organização'
                                    url='/admin/organizacao'
                                    icon={<SettingsIcon />}
                                    drawerOpen={drawerOpen}
                                    handleCloseDrawer={handleCloseDrawer}
                                    isMobileScreen={isMobile}
                                />

                                <LinkMenu
                                    title='Importação de Planilhas'
                                    url='/admin/importacao'
                                    icon={<DatabaseRestoreIcon />}
                                    drawerOpen={drawerOpen}
                                    handleCloseDrawer={handleCloseDrawer}
                                    isMobileScreen={isMobile}
                                />

                                <LinkMenu
                                    title='Registro de logs'
                                    url='/admin/logs'
                                    icon={<EyeIcon />}
                                    drawerOpen={drawerOpen}
                                    handleCloseDrawer={handleCloseDrawer}
                                    isMobileScreen={isMobile}
                                />

                                <LinkMenu
                                    title='Erros de requisição'
                                    url='/admin/erros'
                                    icon={<ErrorIcon />}
                                    drawerOpen={drawerOpen}
                                    handleCloseDrawer={handleCloseDrawer}
                                    isMobileScreen={isMobile}
                                />
                            </List>
                        </Collapse>
                    </>
                )}
            </List>
        </>)

        /** Fim da função Menu */
    }

    return (
        <>
            {isXXlScreen ? (
                <>
                    {menu()}
                </>
            ) :
                isMobile ?
                    (
                        <Drawer
                            variant='temporary'
                            anchor='left'
                            open={drawerOpen}
                            onClose={handleCloseDrawer}
                            sx={{ '& .MuiDrawer-paper': { maxWidth: '100%' } }}
                        >
                            {menu()}
                        </Drawer>
                    ) : (
                        <PermanentDrawer
                            variant='permanent'
                            anchor='left'
                            open={drawerOpen}
                            onClose={handleCloseDrawer}
                            isXlScreen={isXlScreen}
                        >
                            <Toolbar />
                            {menu()}
                        </PermanentDrawer>
                    )
            }
        </>
    );
}